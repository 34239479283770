import React from 'react';
import ReactModal from 'react-modal';

import './modal.scss';

const getMaxWidthFromSize = size => {
  switch (size) {
    case 'sm': return 320;
    case 'md': return 520;
    default: return null;
  }
};

const ModalDialog = ({ isOpen, onClose, children, size }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="modal modal_quote modal-flex"
      overlayClassName="backdrop fade in"
    >
      <div
        className="modal-dialog"
        style={{
          maxWidth: getMaxWidthFromSize(size),
        }}
      >
        {children}
      </div>
    </ReactModal>
  );
};

export default ModalDialog;
