import React from 'react';
import styled from 'styled-components';

import { translator } from 'js/translator';

import Icon from '../Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
`;

const LinkText = styled.a`
  display: contents;
  cursor: pointer;
`;

const iconStyle = {
  marginRight: 10,
  width: '0.875em',
  fontSize: 'inherit',
};

const GoBackView = ({ onClick }) => {
  return (
    <Wrapper>
      <LinkText onClick={onClick}>
        <Icon name="arrow_left" style={iconStyle}/>
        {translator.getTitle('go_back_label')}
      </LinkText>
    </Wrapper>
  );
};

export default GoBackView;
