import BaseEntity from 'js/base_v3/models/entity';

/**
 * Base Pricing Source.
 *
 * @class
 * @abstract
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function BasePricingSource(data) {
  BaseEntity.call(this, data);
}

export default BasePricingSource;
