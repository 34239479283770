import BaseDataTable from 'js/base_v2/data-table';
import EntityHelper from 'js/helpers/entity-helper';
import TableColumnRenderHelper from 'js/helpers/table/column-render-helper';
import QuoteRenderer from 'js/quote/helpers/renderer';
import Quote from 'js/quote/models/quote';

/**
 * Quote Table.
 *
 * @class
 * @abstract
 * @extends BaseDataTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 */
function QuoteTable(tableEl, options) {
  BaseDataTable.call(this, tableEl, options);
  const parent = this.clone();

  /**
   * @prop EntityHelper
   */
  this.entityHelper = null;

  /**
   * @inheritDoc
   */
  this.initEarlyProps = function() {
    parent.initEarlyProps.call(this);

    this.entityHelper = new EntityHelper();
    this.quoteRenderer = new QuoteRenderer();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      loadMore: true,
      rowClickAction: this.ROW_CLICK_ACTION_CLICK,
      dataTable: {
        pageLength: 15,
        order: [[5, 'desc']],
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.onRowClick = function(tr) {
    const quote = this.getRowData(tr);

    window.location.href = `/quote/part/list?quote=${quote.id}`;
  };

  /**
   * @inheritDoc
   */
  this.createdRow = function(tr, record, index) {
    parent.createdRow.call(this, tr, record, index);

    this.highlightRow(record, tr);
  };

  /**
   * @inheritDoc
   */
  this.getColumns = function() {
    let columns = [{
      // 1. Checkbox
      className: 'check_item first-col highlight-bar',
      type: TableColumnRenderHelper.TYPE_CHECKBOX,
    }, {
      // 2. Company
      name: 'company_name',
      className: 'title_item text_left',
      render: (data) => this.quoteRenderer.renderContactCompany(data),
    }, {
      // 3. Job
      name: 'job_name',
      className: 'text_left',
      render: (data) => this.quoteRenderer.renderJob(data),
    }, {
      // 4. Quote #
      name: 'custom_id',
      className: 'text_right',
      render: (data) => this.quoteRenderer.renderCustomId(data),
    }, {
      // 5. Value
      name: 'total_price',
      className: 'text_right moq_col',
      render: (data) => this.quoteRenderer.renderQuoteValues(data),
    }, {
      // 6. Updated
      name: 'updated_at',
      className: 'text_right',
      render: (data) => this.renderDate(data.updated_at),
    }, {
      // 7. Status
      name: 'status',
      className: 'text_left',
      render: (data) => this.quoteRenderer.renderStatus(data),
    }, {
      // 8. Contact
      name: 'vendor_corporate_user_name',
      className: 'text_left',
      render: (data) => this.quoteRenderer.renderContact(data),
    }];

    columns = columns.concat(this.getQuoteTypeSpecificColumns());

    columns = columns.concat([{
      // Responsive Btn
      type: TableColumnRenderHelper.TYPE_RESPONSIVE_BTN,
    }]);

    return columns;
  };

  /**
   * Highlight row.
   *
   * @param  {string}     record
   * @param  {DOMElement} tr
   * @return {QuoteTable}
   */
  this.highlightRow = function(record, tr) {
    let className = '';

    switch (record.type) {
      case Quote.TYPE_CUSTOMER:
        className = 'blue';
        break;
      case Quote.TYPE_VENDOR:
        className = 'green';
        break;
      default:
        // Do nothing
    }

    $('td.first-col.highlight-bar', tr).addClass(className);

    return this;
  };

  /**
   * Get quote type specific columns.
   *
   * @return {object[]}
   */
  this.getQuoteTypeSpecificColumns = function() {
    return [];
  };
}

export default QuoteTable;
