import BaseField from 'js/base_v2/field';

/**
 * Base Typeahead Field.
 *
 * @class
 * @abstract
 * @extends BaseField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function BaseTypeaheadField(fieldCt, fieldEl, options) {
  BaseField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {Bloodhound}
   */
  this.suggestionEngine = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entitiesName: 'records',
      url: undefined,
      urlParams: undefined,
      urlWildcard: undefined,
      filterResponse: undefined,
      limit: Infinity,
      onRecordSelected: undefined,
      onFieldChange: undefined,
      onSuggestionsRendered: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    return this
      .initSuggestionEngine()
      .initTypeahead();
  };

  /**
   * Initialize suggestion engine.
   *
   * @return {BaseTypeaheadField}
   */
  this.initSuggestionEngine = function() {
    /* eslint-disable no-undef */
    this.suggestionEngine = new Bloodhound({
      datumTokenizer(records) {
        return Bloodhound.tokenizers.whitespace(records);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: this.options.url,
        wildcard: this.options.urlWildcard,
        filter(response) {
          return self.filterResponse(response);
        },
        replace(url, query) {
          return self.processUrl(url, query);
        },
      },
    });
    /* eslint-enable no-undef */

    this.suggestionEngine.initialize();

    return this;
  };

  /**
   * Initialize Typeahead.
   *
   * @return {BaseTypeaheadField}
   */
  this.initTypeahead = function() {
    this.fieldEl.typeahead({
      hint: true,
      highlight: true,
      minLength: 1,
    }, {
      name: this.options.entitiesName,
      display: this.getDisplay(),
      source: this.suggestionEngine.ttAdapter(),
      limit: this.options.limit,
      templates: {
        suggestion(record) {
          return `<div>${self.getRecordLabel(record)}</div>`;
        },
      },
    }).on('typeahead:selected', (event, record) => {
      self.onRecordSelected(event, record);
    }).on('typeahead:change', (event, record) => {
      self.onFieldChange(event, record);
    }).on('typeahead:rendered', () => {
      self.onSuggestionsRendered();
    });

    return this;
  };

  /**
   * On record selected.
   *
   * @param  {Event}             event
   * @param  {object}            record
   * @return {boolean|undefined}
   */
  this.onRecordSelected = function(event, record) {
    if (_.isFunction(this.options.onRecordSelected) &&
      false === this.options.onRecordSelected(event, record)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * On field change.
   *
   * @param  {Event}             event
   * @param  {object}            record
   * @return {boolean|undefined}
   */
  this.onFieldChange = function(event, record) {
    if (_.isFunction(this.options.onFieldChange) &&
      false === this.options.onFieldChange(event, record)
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * On suggestions rendered.
   *
   * @return {boolean|undefined}
   */
  this.onSuggestionsRendered = function() {
    if (_.isFunction(this.options.onSuggestionsRendered) &&
      false === this.options.onSuggestionsRendered()
    ) {
      return false;
    }

    return undefined;
  };

  /**
   * Filter response.
   *
   * @param  {object}   response
   * @return {object[]}
   */
  this.filterResponse = function(response) {
    if (_.isFunction(this.options.filterResponse)) {
      return this.options.filterResponse(response);
    }

    return response[this.options.entitiesName];
  };

  /**
   * Process url.
   *
   * @param  {string} url
   * @param  {string} query
   * @return {string}
   */
  this.processUrl = function(url, query) {
    const processedUrl = url.replace('%QUERY', encodeURIComponent(query));
    const urlParams = Object.serialize(this.options.urlParams);

    return processedUrl + ('' !== urlParams ? '&' : '') + urlParams;
  };

  /**
   * Get record label.
   *
   * @param  {object} record
   * @return {string}
   */
  this.getRecordLabel = function(record) {
    return record.name;
  };

  /**
   * Get display (function or key).
   *
   * @return {function|string}
   */
  this.getDisplay = function() {
    return 'name';
  };
}

export default BaseTypeaheadField;
