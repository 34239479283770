import QuotationFormHelper from 'js/base_v3/helpers/quotation-form-helper';
import Quote from 'js/quote/models/quote';

/**
 * Quote Form Helper.
 *
 * @class
 * @extends QuotationFormHelper
 *
 * @param {object} [options]
 */
function QuoteFormHelper(options) {
  QuotationFormHelper.call(this, options);

  /**
   * @inheritDoc
   */
  this.getDropdownStatuses = function() {
    return [
      Quote.STATUS_DRAFT,
      Quote.STATUS_OPEN,
      Quote.STATUS_WON,
      Quote.STATUS_PUNCHED_OUT,
      Quote.STATUS_LOST,
    ];
  };

  // Initialize
  this._init();
}

export default QuoteFormHelper;
