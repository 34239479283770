import React from 'react';

import Button from 'partfiniti/core/components/Button';
import Modal from 'partfiniti/core/components/Modal';
import Wrapper from 'partfiniti/core/components/Wrapper';

const ConfirmationView = ({
  title,
  size,
  message,
  confirmBtnLabel,
  confirmBtnType,
  cancelBtnLabel,
  onConfirm,
  onCancel,
  isOpen,
}) => {
  return (
    <Modal
      header={{ title }}
      isOpen={isOpen}
      onClose={onCancel}
      size={size}
    >
      <Wrapper>
        {message}
      </Wrapper>

      <Wrapper>
        <Button onClick={onConfirm} type={confirmBtnType}>
          {confirmBtnLabel}
        </Button>
        <Button onClick={onCancel}>
          {cancelBtnLabel}
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmationView;
