import React from 'react';

import { translator } from 'js/translator';

import { ADD_LOG_STEPS } from 'partfiniti/bom/types';
import { 
  Button,
  DateField,
  FieldWrapper,
  GoBack,
  Modal,
  SelectField,
  TextField,
  Wrapper,
} from 'partfiniti/core/components';
import { getNumber } from 'partfiniti/core/utils';

import { LeftWrapper, RightWrapper } from './addLogStyle';

const renderField = (config, fields, initialValues) => {
  switch (config.type) {
    case 'text':
    case 'textarea':
      return (
        <TextField
          key={config.name}
          {...{
            ...config,
            ...fields[config.name],
          }}
          value={config.value}
        />
      );
    case 'select':
      return (
        <SelectField
          key={config.name}
          {...{
            options: initialValues?.[config.name] ?
              [initialValues?.[config.name]] :
              [],
            ...config,
            ...fields[config.name],
          }}
        />
      );
    case 'date':
      return (
        <DateField
          key={config.name}
          {...{
            ...config,
            ...fields[config.name],
          }}
        />
      );
    default:
      throw new Error(`Unknown field type "${config.type}"`);
  }
};

const sumDuration = array => {
  let result = 0;
  for(let i = 0; i < array.length; i++){
    result += getNumber(array[i].value);
  }

  return result;
};

const AddLogFormView = ({
  isOpen,
  onClose,
  createTitle,
  size,
  fieldsConfig,
  createBtnLabel,

  initialValues,
  fields,
  form,
  onSubmit,

  step,
  goBack,
}) => {
  const error = fieldsConfig.reduce((firstError, field) => (
    firstError || (fields[field.name].touched && fields[field.name].error) 
  ), null);

  const fieldContent = (config, index) => 
    step === ADD_LOG_STEPS.TIME ? 
      (index % 2 === 0 ? (
        <FieldWrapper key={index}>
          <LeftWrapper>
            {renderField(config, fields, initialValues)}
          </LeftWrapper>
          <RightWrapper>
            {renderField(fieldsConfig[index + 1], fields, initialValues)}
          </RightWrapper>
        </FieldWrapper>
      ) : <></>) :
      renderField(config, fields, initialValues);

  const total = step === ADD_LOG_STEPS.TIME ?
    sumDuration(Object.values(fields)) : 
    0;

  const btnLabel = step === ADD_LOG_STEPS.DETAILS ? 
    translator.getTitle('label_next') : 
    createBtnLabel;

  return (
    <Modal
      header={{ title: createTitle }}
      error={error || form.error}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <Wrapper>
        {fieldsConfig.map((config, index) => 
          fieldContent(config, index, fields, initialValues)
        )}

        {step === ADD_LOG_STEPS.TIME && (
          <FieldWrapper hasPaddingTop>
            <LeftWrapper>{translator.getTitle('label_total')}</LeftWrapper>
            <RightWrapper alignRight>
              {total} <small>{translator.get('label_hours')}</small>
            </RightWrapper>
          </FieldWrapper>
        )}
      </Wrapper>

      {step === ADD_LOG_STEPS.TIME && (
        <Wrapper>
          <GoBack onClick={goBack} />
        </Wrapper>
      )}

      <Wrapper>
        <Button onClick={onSubmit}>
          {btnLabel}
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default AddLogFormView;
