import BasePricingSource from 'js/pricing/models/base-source';

const staticSelf = PricingSource;

/**
 * @const
 */
staticSelf.STATUS_ACTIVE = 'active';

/**
 * @const
 */
staticSelf.STATUS_PRICING_PROFILE_REMOVED = 'pricing_profile_removed';

/**
 * @const
 */
staticSelf.TYPE_CORPORATE = 'corporate';

/**
 * @const
 */
staticSelf.TYPE_CUSTOMER_CORPORATE = 'customer_corporate';

/**
 * @const
 */
staticSelf.TYPE_FAMILY = 'family';

/**
 * @const
 */
staticSelf.TYPE_LIST_PRICE = 'list_price';

/**
 * @const
 */
staticSelf.TYPE_FLOOR_PRICE = 'floor_price';

/**
 * @const
 */
staticSelf.TYPE_CUSTOM = 'custom';

/**
 * @const
 */
staticSelf.TYPE_DEFAULT = 'default';

/**
 * @const
 */
staticSelf.TYPE_EXTERNAL = 'external';

/**
 * @const
 */
staticSelf.TYPE_PROJECT = 'project';

/**
 * @const
 */
staticSelf.TYPE_VENDOR = 'vendor';

/**
 * @const
 */
staticSelf.TYPE_CUSTOMER_QUOTE = 'customer_quote';

/**
 * @const
 */
staticSelf.TYPE_VENDOR_QUOTE = 'vendor_quote';

/**
 * @const
 */
staticSelf.DISCOUNT_TYPE_DISCOUNT = 'discount';

/**
 * @const
 */
staticSelf.DISCOUNT_TYPE_MARGIN = 'margin';

/**
 * @const
 */
staticSelf.DISCOUNT_TYPE_FIXED_PRICE = 'fixed_price';

/**
 * @const
 */
staticSelf.DISCOUNT_TYPE_FIXED_COST = 'fixed_cost';

/**
 * @const
 */
staticSelf.DISCOUNT_TYPE_BASE_CODE = 'base_code';

/**
 * @const
 */
staticSelf.DISCOUNT_TYPE_VENDOR_QUOTE_PART = 'vendor_quote_part';

/**
 * @const
 */
// eslint-disable-next-line max-len
staticSelf.DISCOUNT_TYPE_BASE_CODE_VENDOR_QUOTE_PART = 'base_code_vendor_quote_part';

/**
 * @const
 */
// eslint-disable-next-line max-len
staticSelf.DISCOUNT_TYPE_VENDOR_QUOTE_PRICING_GROUP_DISCOUNT = 'vendor_quote_pricing_group_discount';

/**
 * @const
 */
// eslint-disable-next-line max-len
staticSelf.DISCOUNT_TYPE_CURRENT_QUOTE_PRICING_GROUP_DISCOUNT = 'current_quote_pricing_group_discount';

/**
 * @const
 */
// eslint-disable-next-line max-len
staticSelf.DISCOUNT_TYPE_VENDOR_QUOTE_PART_DISCOUNT = 'vendor_quote_part_discount';

/**
 * @const
 */
// eslint-disable-next-line max-len
staticSelf.DISCOUNT_TYPE_CURRENT_QUOTE_PART_DISCOUNT = 'current_quote_part_discount';

/**
 * Pricing Source.
 *
 * @class
 * @extends BasePricingSource
 *
 * @param {object} [data]
 */
function PricingSource(data) {
  BasePricingSource.call(this, data);

  // Initialize
  this._init();
}

export default PricingSource;
