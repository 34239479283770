import BaseEntityConfig from 'js/base_v3/configs/entity-config';
import Order            from 'js/order/models/order';
import OrderTable       from 'js/order/table';
import OrderRenderer    from 'js/order_v2/helpers/renderer';

/**
 * Order Config.
 *
 * @class
 * @extends BaseEntityConfig
 *
 * @param {object} [options]
 */
function OrderConfig(options) {
    BaseEntityConfig.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this._initDefaults = function() {
        parent._initDefaults.call(this);

        return this._extendDefaultOptions({
            entityName              : 'order',
            entityClass             : Order,
            tableClass              : OrderTable,
            rendererClass           : OrderRenderer
        });
    };

    // Initialize
    this._init();
}

export default OrderConfig;
