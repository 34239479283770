import React from 'react';

import { translator } from 'js/translator';

import { Button, DateField, Icon, Visible } from 'partfiniti/core/components';
import { ArrowDown } from 'partfiniti/core/elements';
import { DATE_OPTIONS } from 'partfiniti/core/types';
import { formatDate } from 'partfiniti/core/utils';

import {
  Box,
  ButtonsWrapper,
  Control,
  DateIntervalWrapper,
  DateWrapper,
  Dropdown,
  iconStyle,
  IconWrapper,
  ListItem,
  Menu,
  Placeholder,
  Text,
  Wrapper,
} from './dateIntervalStyle.js';

const DateIntervalView = (
  {
    handleChange,
    options,
    selectedOption,
    setOpened,
    opened,
    onChange,
    startDate,
    endDate,
    onApply,
    placeholder = translator.getTitle('label_created'),
    size,
    rightPosition = false,
  }) => {

  return (
    <DateIntervalWrapper>
      <Control onClick={() => setOpened((currValue) => !currValue)} size={size}>
        <Box>
          <Placeholder>
            <Icon name="calendar" style={iconStyle}/>

            <Visible when={!selectedOption || !startDate || !endDate}>
              {placeholder}
            </Visible>

            <Visible when={Boolean(selectedOption && startDate && endDate)}>
              <Text>
                {formatDate(startDate)} - {formatDate(endDate)}
              </Text>
            </Visible>
          </Placeholder>
        </Box>

        <IconWrapper>
          <ArrowDown />
        </IconWrapper>
      </Control>

      <Visible when={opened}>
        <Dropdown
          isNotFittingToTheRight={
            rightPosition && selectedOption?.label === DATE_OPTIONS.CUSTOM_RANGE
          }
        >
          <Wrapper>
            <Menu>
              {options.map((item) => (
                <ListItem
                  key={item.id}
                  selected={selectedOption?.id === item.id}
                  onClick={() => handleChange(item)}
                >
                  {item.label}
                </ListItem>
              ))}
            </Menu>

            <Visible when={selectedOption?.label === DATE_OPTIONS.CUSTOM_RANGE}>
              <DateField
                selected={endDate}
                endDate={endDate}
                startDate={startDate}
                onChange={onChange}
                dateFormat="MM/dd/YY"
                monthsShown={2}
                selectsRange
                inline
              />
            </Visible>
          </Wrapper>

          <Visible when={selectedOption?.label === DATE_OPTIONS.CUSTOM_RANGE}>
            <ButtonsWrapper>
              <DateWrapper>
                {formatDate(startDate)} - {endDate ? formatDate(endDate) : ''}
              </DateWrapper>

              <Button 
                size="xs" 
                onClick={() => setOpened(false)}
              >
                {translator.getTitle('label_cancel')}
              </Button>

              <Button
                size="xs"
                onClick={() => onApply(startDate, endDate)}
                disabled={!endDate}
              >
                {translator.getTitle('label_apply')}
              </Button>
            </ButtonsWrapper>
          </Visible>
        </Dropdown>
      </Visible>
    </DateIntervalWrapper>
  );
};

export default DateIntervalView;
