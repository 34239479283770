import BaseList from 'js/base_v2/list';
import CliJobChecker from 'js/cli_v2/helpers/job-checker';
import CliJob from 'js/cli_v2/models/job';
import Renderer from 'js/components/renderer';
import EntityHelper from 'js/helpers/entity-helper';
import OrderFilterGroup from 'js/order/filters/order-filter-group';
import OrderCreateFormGroup from 'js/order/forms/create-form-group';
import OrderTable from 'js/order/table';

/**
 * Order List.
 *
 * @class
 * @extends BaseList
 *
 * @param {DOMElement} listCt
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 */
function OrderList(listCt, tableEl, options) {
  BaseList.call(
    this,
    listCt,
    tableEl,
    options.detailsEl ? options.detailsEl : null,
    options,
  );
  const parent = this.clone();

  /**
   * @prop {?object}
   */
  this.project = null;

  /**
   * @prop {?object}
   */
  this.bom = null;

  /**
   * @prop {object}
   */
  this.ordersUpdatedMap = {};

  /**
   * @prop {boolean}
   */
  this.coreOrderIntegrationEnabled = null;

  /**
   * @prop {CliJobChecker}
   */
  this.cliJobChecker = null;

  /**
   * @prop EntityHelper
   */
  this.entityHelper = null;

  /**
   * @prop {OrderCreateFormGroup}
   */
  this.orderCreateFormGroup = null;

  /**
   * @inheritDoc
   */
  this.initEarlyProps = function() {
    parent.initEarlyProps.call(this);

    this.cliJobChecker = new CliJobChecker();
    this.entityHelper = new EntityHelper();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'order',
      entityLabel: 'order',
      tableClass: OrderTable,
      filterGroupClass: OrderFilterGroup,
      filterGroupCt: '.orderFilterGroupContainer',
      loadUrl: '/order/list/orders',
      orderCreateFormGroup: {
        data: {
          coreOrderIntegrationEnabled: this.coreOrderIntegrationEnabled,
        },
        customerOrderCreateForm: {
          afterSubmit: (response) => {
            this.afterOrderCreateFormSubmit(response);
          },
        },
        vendorOrderCreateForm: {
          afterSubmit: (response) => {
            this.afterOrderCreateFormSubmit(response);
          },
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    $(document).on('click', '.createOrder', (event) => {
      this.onCreateOrderBtnClick(event);
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onTableAjax = function(response) {
    parent.onTableAjax.call(this, response);

    if (!_.isObject(response)) {
      return;
    }

    this
      .updateTotals(response.totals)
      .updateExternalOrderNumbers(this.entityHelper.get(
        response.get_orders_info_from_core_cli_job,
        CliJob,
      ));
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    this.processOrderCreateFormOptions();

    return this;
  };

  /**
   * Process order create form options.
   *
   * @return {OrderList}
   */
  this.processOrderCreateFormOptions = function() {
    if (!_.isObject(this.project)) {
      return this;
    }

    this.extendOptions({
      orderCreateFormGroup: {
        customerOrderCreateForm: {
          projectField: {
            selectedRecords: [this.project],
          },
        },
        vendorOrderCreateForm: {
          projectField: {
            selectedRecords: [this.project],
          },
        },
      },
    });

    if (_.isObject(this.bom)) {
      this.extendOptions({
        orderCreateFormGroup: {
          customerOrderCreateForm: {
            data: {
              bom: this.bom,
            },
          },
          vendorOrderCreateForm: {
            data: {
              bom: this.bom,
            },
          },
        },
      });
    }

    return this;
  };

  /**
   * Update totals.
   *
   * @param  {object}    totals
   * @return {QuoteList}
   */
  this.updateTotals = function(totals) {
    if (!_.isObject(totals)) {
      return this;
    }

    // Update total price
    const priceHtml = Renderer.Price(totals.price);
    $('.totalPrice', this.listCt).html(priceHtml);

    // Update total count
    const countHtml = Renderer.Count(totals.count);
    $('.totalCount', this.listCt).html(countHtml);

    return this;
  };

  /**
   * Update external order numbers.
   *
   * @param  {?CliJob}   getOrdersInfoJob
   * @return {QuoteList}
   */
  this.updateExternalOrderNumbers = function(getOrdersInfoJob) {
    if (!_.isObject(getOrdersInfoJob)) {
      return this;
    }

    $(
      '.orderCustomIdCol .externalOrderNumber .message',
      this.table.getHeaderTableEl(),
    ).html(Renderer.Loading(''));

    this.ordersUpdatedMap = {};

    this.cliJobChecker.check(
      getOrdersInfoJob, // CLI job
      2000, // Interval milliseconds
      () => {
        this.onGetOrdersInfoFromCoreJobFinished();
      }, // Finished callback
      () => {
        this.onGetOrdersInfoFromCoreJobStopped();
      }, // Stopped callback
      (updatedGetOrdersInfoJob) => {
        this.onCheckGetOrdersInfoFromCoreJob(updatedGetOrdersInfoJob);
      }, // Check callback
      { instantCheck: true }, // Check options
    );

    return this;
  };

  /**
   * "Get orders info from CORE" CLI job finished event handler.
   */
  this.onGetOrdersInfoFromCoreJobFinished = function() {
    this.ordersUpdatedMap = {};

    $(
      '.orderCustomIdCol .externalOrderNumber .message',
      this.table.getHeaderTableEl(),
    ).html('');
  };

  /**
   * "Get orders info from CORE" CLI job stopped event handler.
   */
  this.onGetOrdersInfoFromCoreJobStopped = function() {
    this.ordersUpdatedMap = {};

    this.notifier.notifyError(this.translator.get(
      'get_orders_info_from_core_error',
    ));

    $(
      '.orderCustomIdCol .externalOrderNumber .message',
      this.table.getHeaderTableEl(),
    ).html('');
  };

  /**
   * "Get orders info from CORE" CLI job check event.
   *
   * @param {CliJob} cliJob
   */
  this.onCheckGetOrdersInfoFromCoreJob = function(cliJob) {
    const ordersData = Object.get(cliJob.getResult(), 'orders');

    _.each(ordersData, (orderData) => {
      if (this.ordersUpdatedMap[orderData.id]) {
        return;
      }

      this.updateRowDataByIdentifier(orderData.id, {
        external_order_number: orderData.external_order_number,
        external_order_response_code: orderData.external_order_response_code,
        external_order_data: orderData.external_order_data,
        external_order_url: orderData.external_order_url,
      });

      this.ordersUpdatedMap[orderData.id] = true;
    });
  };

  /**
   * Create order button click event handler.
   *
   * @param {Event} event
   */
  this.onCreateOrderBtnClick = function(event) {
    event.preventDefault();
    this.createOrderCreateFormGroup();
  };

  /**
   * Order create form after submit event handler.
   *
   * @param {object} response
   */
  this.afterOrderCreateFormSubmit = function(response) {
    window.location.href = `/order/part/list?order=${response.order.id}`;
  };

  /**
   * Create order create form group.
   *
   * @return {OrderList}
   */
  this.createOrderCreateFormGroup = function() {
    this.orderCreateFormGroup = new OrderCreateFormGroup(
      [], // Records
      this.options.orderCreateFormGroup, // Options
    ).create();

    return this;
  };

  // Initialize
  this.init();
}
export default OrderList;
