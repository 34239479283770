import React from 'react';

import Visible from '../Visible';

const ModalHeader = ({
  title,
  subtitle,
  error,
  closeModal,
}) => {
  return (
    <div className="wrapper_block">
      <div className="wrap_top">
        <div></div>
        <button type="button" className="close_bt" onClick={closeModal}>
          <i className="fa fa-times"></i>
        </button>
      </div>
      <h2 className="title title_modal">
        {title}
      </h2>

      <span className="subtitle">
        {subtitle}
      </span>

      <Visible when={error}>
        <div className="notificationCt notification-container error">
          <i className="icon icon-left fa fa-exclamation-circle"></i>
          {error}
        </div>
      </Visible>
    </div>
  );
};

export default ModalHeader;
