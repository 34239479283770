import ModalForm from 'js/base_v2/modal-form';

/**
 * Login - Email Form.
 *
 * @class
 * @abstract
 * @extends ModalForm
 *
 * @param {object} [options]
 */
function BaseLoginForm(options) {
  ModalForm.call(this, options, true);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      focusFirstNonEmptyField: true,
      tmplParams: {
        actionUrl: '/auth/standard/sign-in',
        actionMethod: 'POST',
        title: this.translator.getTitle('label_sign_in'),
        submitBtnLabel: this.translator.getTitle('label_continue'),
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('click', '.createAccount', (event) => {
      event.preventDefault();

      this.destroy();
    });

    this.formEl.on('click', '.recoverPassword', (event) => {
      event.preventDefault();

      this.destroy();
    });

    this.formEl.on('click', '.resendEmailVerificationLink', (event) => {
      event.preventDefault();

      this.destroy();
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      email: {
        required: {
          message: this.translator.get('error_empty_email'),
        },
      },
    };
  };

  /**
   * @inheritDoc
   */
  this.getAfterSubmitMessage = function() {
    return null;
  };
}

export default BaseLoginForm;
