/* eslint-disable max-len */
import BaseQuotationRenderHelper from 'js/base_v3/helpers/quotation-render-helper';
/* eslint-enable max-len */

/**
 * Order Render Helper.
 *
 * @class
 * @extends BaseQuotationRenderHelper
 *
 * @param {object} [options]
 */
function OrderRenderHelper(options) {
  BaseQuotationRenderHelper.call(this, options);

  // Initialize
  this._init();
}

export const orderRenderHelper = new OrderRenderHelper();

export default OrderRenderHelper;
