import BaseHelper from 'js/base_v3/helpers/helper';

/**
 * Quotation Form Helper.
 *
 * @class
 * @abstract
 * @extends BaseHelper
 *
 * @param {object} [options]
 */
function QuotationFormHelper(options) {
  BaseHelper.call(this, options);

  /**
   * Get quotation status dropdown values.
   *
   * @abstract
   *
   * @return {string[]}
   */
  this.getDropdownStatuses = null;
}

export default QuotationFormHelper;
