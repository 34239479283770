import ModalForm from 'js/base_v2/modal-form';
import ApplicationConfig from 'js/common_v3/configs/application-config';

/**
 * Create Account Form.
 *
 * @class
 * @extends ModalForm
 *
 * @param {object} [options]
 */
function CreateAccountForm(options) {
  ModalForm.call(this, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {string}
   */
  this.grecaptchaSiteKey = null;

  /**
   * @prop {number}
   */
  this.recaptchaWidgetId = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      tmplEl: '#create-account-form',
      tmplParams: {
        title: 'Create Your Free Account',
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.grecaptchaSiteKey = new ApplicationConfig().get('grecaptchaSiteKey');

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('click', '.signIn', (e) => {
      e.preventDefault();
      self.destroy();
    });

    this.formEl.on('click', '.recoverPassword', (e) => {
      e.preventDefault();
      self.destroy();
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onShown = function() {
    if (false === parent.onShown.call(this)) {
      return false;
    }

    this.renderRecaptcha();

    return undefined;
  };

  /**
   * Render reCAPTCHA.
   *
   * @return {CreateAccountForm}
   */
  this.renderRecaptcha = function() {
    // eslint-disable-next-line no-undef
    this.recaptchaWidgetId = grecaptcha.render(
      'createAccountRecaptchaContainer',
      {
        sitekey: this.grecaptchaSiteKey,
        callback() {
          // Enable register button when reCAPTCHA is solved
          self.enableSubmitBtn();
        },
        'expired-callback': function() {
          // Disable register button when reCAPTCHA expires
          self.disableSubmitBtn();
        },
        'error-callback': function() {
          self.notifier.notifyError(self.translator.get(
            'error_submit_recaptcha_response',
          ));
        },
      },
    );

    return this;
  };

  /**
   * @inheritDoc
   */
  this.validate = function() {
    if (!parent.validate.call(this)) {
      return false;
    }

    if (!this.validateEmail()) {
      return false;
    }

    if (!this.validateRecaptcha()) {
      return false;
    }

    return true;
  };

  /**
   * @inheritDoc
   */
  this.afterSubmit = function(response) {
    if (1 === +response.sso_enabled) {
      window.location.href = response.sso_redirect_url;

      return false;
    }

    if (1 === +response.email_confirmed) {
      window.location.href =
        `/?trigger=setup-account&email=${encodeURIComponent(response.email)}`;

      return false;
    }

    this.clearNotifications();

    this.formEl.find('h2').html('We sent you an email');

    const email = $.trim(this.formEl.find('input[name="email"]').val());

    this.formEl.find('.formConfirmation').html(
      `${'<p>' +
        'We have sent an email to ' +
        '<strong>'}${email}</strong>. ` +
        'Please follow the link in the email to complete your account set up.' +
      '</p>',
    );

    this.formEl.find('.formContent').addClass('hide');
    this.formEl.find('.formConfirmation').removeClass('hide');

    return false;
  };

  /**
   * @inheritDoc
   */
  this.onSubmitError = function(response, errorMessage) {
    if (false === parent.onSubmitError.call(this, response, errorMessage)) {
      return false;
    }

    // Reset register reCAPTCHA
    // eslint-disable-next-line no-undef
    grecaptcha.reset(this.recaptchaWidgetId);

    this.disableSubmitBtn();

    return undefined;
  };

  /**
   * Validate email.
   *
   * @return {boolean}
   */
  this.validateEmail = function() {
    const email = $('input[name="email"]').val();

    if (email.length > 255) {
      this.notifier.notifyError(this.translator.get(
        'error_email_too_long',
      ));

      return false;
    }

    return true;
  };

  /**
   * Validate reCAPTCHA.
   *
   * @return {boolean}
   */
  this.validateRecaptcha = function() {
    // eslint-disable-next-line no-undef
    if (!grecaptcha.getResponse(this.recaptchaWidgetId)) {
      this.notifier.notifyError(this.translator.get(
        'error_recaptcha_not_solved',
      ));

      return false;
    }

    return true;
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      email: {
        required: {
          message: 'Please enter the email address.',
        },
      },
    };
  };

  // Initialize
  this.init();
}

export default CreateAccountForm;
