import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

const getError = (fieldsOrdered, fields, form) => {
  if (form.valid) {
    return null;
  }

  if (form.error) {
    return form.error;
  }

  const fieldNames = Array.isArray(fieldsOrdered) ?
    fieldsOrdered :
    Object.keys(fields);

  return fieldNames.reduce((error, fieldName) => {
    if (error) {
      return error;
    }

    const field = fields[fieldName];

    if (!field) {
      return error;
    }

    if (!field.valid && field.touched) {
      return field.error;
    }
  }, null);
};

const withError = (fieldsOrdered) => WrappedComponent => {
  const WithError = ({ fields, form, ...ownProps }) => {
    const error = getError(fieldsOrdered, fields, form);

    return (
      <WrappedComponent
        {...{
          ...ownProps,
          fields,
          form,
          error,
        }}
      />
    );
  };

  return hoistStatics(WithError, WrappedComponent);
}

export default withError;
