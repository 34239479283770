import BaseList from 'js/base_v2/list';
import CliJobChecker from 'js/cli_v2/helpers/job-checker';
import CliJob from 'js/cli_v2/models/job';
import Ajax from 'js/components/ajax';
import Renderer from 'js/components/renderer';
import EntityHelper from 'js/helpers/entity-helper';
import { storageHelper } from 'js/helpers/storage-helper';
import QuoteCreateFormGroup from 'js/quote/create-form-group';
import QuoteExportHelper from 'js/quote/helpers/export-helper';
import QuoteSelectionPanel from 'js/quote/selection-panel';
import Quote from 'js/quote/models/quote';

/**
 * Quote List.
 *
 * @class
 * @extends BaseList
 *
 * @param {DOMElement} listCt
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 * @param {boolean}    [inherited]
 */
function QuoteList(listCt, tableEl, options, inherited) {
  BaseList.call(
    this,
    listCt,
    tableEl,
    options.detailsEl ? options.detailsEl : null,
    options,
  );
  const parent = this.clone();
  const self = this;

  /**
   * @prop {?object}
   */
  this.project = null;

  /**
   * @prop {?object}
   */
  this.bom = null;

  /**
   * @prop {User}
   */
  this.user = null;

  /**
   * @prop {?CorporateLocation}
   */
  this.userLocation = null;

  /**
   * @prop {boolean}
   */
  this.punchoutSessionActive = null;

  /**
   * @prop {?boolean}
   */
  this.verticalMarketRequired = null;

  /**
   * @prop {CliJobChecker}
   */
  this.cliJobChecker = null;

  /**
   * @prop {EntityHelper}
   */
  this.entityHelper = null;

  /**
   * @prop {QuoteCloneFormGroup}
   */
  this.quoteCloneFormGroup = null;

  /**
   * @prop {QuoteCreateFormGroup}
   */
  this.quoteCreateFormGroup = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'quote',
      entityLabel: 'quote',
      saveFilterSelection: true,
      filterSelectionNamespace: undefined,
      tableClass: undefined,
      filterGroupClass: undefined,
      filterGroupCt: undefined,
      loadUrl: undefined,
      selectionPanelClass: QuoteSelectionPanel,
      selectionPanel: {
        quoteBulkUpdateForm: {
          afterSubmit() {
            self.onQuotesSave();
          },
        },
        quoteArchiveConfirmation: {
          onAction() {
            self.onQuotesSave();
          },
        },
        reassignUserForm: {
          afterSubmit() {
            self.onQuotesSave();
          },
        },
        exportQuotesList(contentType, recordIds) {
          self.generateQuoteListExportFile(contentType, recordIds);
        },
      },
      quoteCreateFormGroup: {
        data: {
          verticalMarketRequired: this.verticalMarketRequired,
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.getDefaultFilterGroupOptions = function() {
    return parent.getDefaultFilterGroupOptions.call(this).extend({
      onResetAllBtnClick: () => this.onResetAllFiltersBtnClick(),
    });
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.entityHelper = new EntityHelper();
    this.cliJobChecker = new CliJobChecker();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onTableAjax = function(response) {
    parent.onTableAjax.call(this, response);

    if (!_.isObject(response)) {
      return;
    }

    this.updateTotals(response.totals);
  };

  /**
   * Quotes save event handler.
   */
  this.onQuotesSave = function() {
    this.selectionPanel.hide();
    this.reloadAndKeepScrolling();
  };

  /**
   * Create quote button click event handler.
   *
   * @param {Event}  event
   */
  this.onCreateQuoteBtnClick = function(event) {
    event.preventDefault();

    this.createQuoteCreateFormGroup();
  };

  /**
   * Reset all filters button click event handler.
   *
   * @return {boolean|undefined}
   */
  this.onResetAllFiltersBtnClick = function() {
    storageHelper.remove(this.options.filterSelectionNamespace);

    window.location.reload();

    return false;
  };

  /**
   * Quote create/clone form after submit event handler.
   *
   * @param {object} response
   */
  this.afterQuoteCreateFormSubmit = function(response) {
    window.location.href = `/quote/part/list?quote=${response.quote.id}`;
  };

  /**
   * Create quote create form group.
   *
   * @param  {string}    quoteType
   * @return {QuoteList}
   */
  this.createQuoteCreateFormGroup = function() {
    let quoteCreateFormGroupOptions = this.options.quoteCreateFormGroup;

    if (_.isObject(this.project)) {
      quoteCreateFormGroupOptions = quoteCreateFormGroupOptions.extend({
        customerQuoteCreateForm: {
          projectField: {
            selectedRecords: [this.project.getData()],
          },
        },
        vendorQuoteCreateForm: {
          projectField: {
            selectedRecords: [this.project.getData()],
          },
        },
      });
    }

    this.quoteCreateFormGroup = new QuoteCreateFormGroup(
      [], // Records
      quoteCreateFormGroupOptions, // Options
    ).create();

    return this;
  };

  /**
   * Update totals.
   *
   * @param  {object}    totals
   * @return {QuoteList}
   */
  this.updateTotals = function(totals) {
    if (!_.isObject(totals)) {
      return this;
    }

    // Update total price
    const priceHtml = Renderer.Price(totals.price);
    $('.totalPrice', this.listCt).html(priceHtml);

    // Update total count
    const countHtml = Renderer.Count(totals.count);
    $('.totalCount', this.listCt).html(countHtml);

    return this;
  };

  /**
   * Start generating quote list export file.
   *
   * @param {string} contentType
   * @param {array}  recordIds
   */
  this.generateQuoteListExportFile = function(contentType, recordIds) {
    this.notifier.notifyLoading(this.translator.get('export_file_in_progress'));

    const filters = QuoteExportHelper.CONTENT_SELECTED === contentType ?
      { id: recordIds } :
      this.getFilters();

    Ajax.post(
      '/quote/customer/generate-export-file',
      {
        export_type: QuoteExportHelper.TYPE_LIST,
        options: {
          sort_field: this.table.ajaxData.sortField,
          sort_dir: this.table.ajaxData.sortDir,
          filters,
        },
      },
      (response) => {
        const cliJob = this.entityHelper.get(response.cli_job, CliJob);

        this.cliJobChecker.check(
          cliJob, // CLI job
          null, // Interval milliseconds
          (job) => {
            this.onGenerateQuoteListExportFileFinished(job);
          }, // Finished callback
          (job) => {
            this.onGenerateQuoteListExportFileStopped(job);
          }, // Stopped callback
        );
      },
    );
  };

  /**
   * Generate quote list export file - job finished event handler.
   *
   * @param {CliJob} cliJob
   */
  this.onGenerateQuoteListExportFileFinished = function(cliJob) {
    this.notifier.clear();

    const cliJobResult = cliJob.getResult();

    window.location.href = `/files/index/download/file/${cliJobResult.file_id}`;
  };

  /**
   * Generate quote list export file - job stopped event handler.
   *
   * @param {CliJob} cliJob
   */
  this.onGenerateQuoteListExportFileStopped = function(cliJob) {
    const errorMessage = _.isString(cliJob.status_description) ?
      cliJob.status_description : 'Server error.';

    const errorPrefix = this.translator.get(
      'error_generating_export_file_prefix',
    );

    this.notifier.notifyError(`${errorPrefix}: ${errorMessage}`);
  };

  /**
   * @inheritDoc
   */
  this.getDefaultFilterValues = function() {
    let filters = parent.getDefaultFilterValues.call(this).extend({
      type: 'all_customer',
      status: `${Quote.STATUS_OPEN}|${Quote.STATUS_DRAFT}`,
      archived: '"0"',
    });

    if (!this.punchoutSessionActive &&
      !_.isObject(this.project) &&
      _.isUndefined(filters.corporate_users) &&
      _.isObject(this.user)
    ) {
      filters = filters.extend({
        corporate_users: [this.user.getCorporateUser()],
      });
    }

    return filters;
  };

  /**
   * @inheritDoc
   */
  this.getInitialFilterValues = function() {
    let filters = parent.getInitialFilterValues.call(this);

    if (!this.punchoutSessionActive &&
      !_.isObject(this.project) &&
      _.isUndefined(filters.corporate_users) &&
      _.isObject(this.user)
    ) {
      filters = filters.extend({
        corporate_users: [this.user.getCorporateUser()],
      });
    }

    return filters;
  };

  /**
   * Get project - vendor quote association save data.
   *
   * @return {object}
   */
  this.getProjectVendorQuoteAssociationSaveData = function() {
    if (!_.isObject(this.project)) {
      return {};
    }

    return {
      project_id: this.project.id,
    };
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}

export default QuoteList;
