import { translator } from 'js/translator';

import { SERVER_FORMAT_DATE } from 'partfiniti/core/constants';
import { findValuesByPrefix, getNumber } from 'partfiniti/core/utils';

export const validateFormData = (data, props, validate, setServerErrors) => {
  if (_.isFunction(validate)) {
    const errors = validate(data, props);

    if (_.isObject(errors)) {
      setServerErrors(errors);

      return false;
    }
  }

  const durations = findValuesByPrefix(data, 'duration_');

  for (let i = 0; i < durations.length; i++) {
    if (!durations[i] && durations[i] !== 0) {
      setServerErrors({
        [`duration_${i}`]:
        translator.get('error_empty_duration_for_activity_date', {
          activityDate: data[`activity_date_${i}`],
        })
      });

      return false;
    }
  }

  const total = durations.reduce((partialSum, duration) => {
    return partialSum + getNumber(duration);
  }, 0);

  if (durations.length > 0 && total <= 0) {
    setServerErrors({
      duration_0: translator.get('error_empty_duration_total'),
    });

    return false;
  }

  if (data?.start_date && data?.end_date) {
    const startValue = (new Date(data.start_date)).getTime();
    const endValue = (new Date(data.end_date)).getTime();

    if (endValue < startValue) {
      setServerErrors({
        end_date: 
          translator.get('end_date_greater_than_start_date_error_message'),
      });

      return false;
    }
  }

  return true;
};

export const processFormData = (data, values) => {
  const durations = findValuesByPrefix(data, 'duration_');

  const bom_logs = [];
  for (let i = 0; i < durations.length; i++) {
    if (!getNumber(durations[i])) {
      continue;
    }

    bom_logs.push({
      bom_id: values.bom_id,
      user_id: values.user.user_id,
      bom_task_id: values.task.id,
      activity_date: 
        moment(data[`activity_date_${i}`]).format(SERVER_FORMAT_DATE),
      duration: Number(durations[i]),
      note: values.note,
    })
  }

  return {
    bom_logs,
  };
};
