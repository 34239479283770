import { DISPLAY_FORMAT } from 'partfiniti/core/constants';

export const calculateDifferenceInDays = (
  startDate, 
  endDate, 
  bothIncluded = true
) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const diffTime = Math.abs(end - start);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays + (bothIncluded ? 1 : 0);
};

export const addDays = (date, days) => {
  const result = new Date(date);

  result.setDate(result.getDate() + days);

  return moment(result).format(DISPLAY_FORMAT);
};

export const formatDate = (
  date,
  dateFormat = DISPLAY_FORMAT
) => {
  if (!date || !moment(date).isValid()) {
    return '';
  }

  return moment(date).format(dateFormat);
};
