import Event from './Event';

export default class CloseModalEvent extends Event {
    static NAME = 'close-modal-event';

    /**
     * Constructor.
     *
     * @param {object} options
     */
    constructor(options = {}) {
      super();

      this._options = options;
    }

    /**
     * Get options.
     *
     * @return {object}
     */
    get options() {
      return this._options;
    }
}
