import { createSlice } from '@reduxjs/toolkit';

const partSlice = createSlice({
  name: 'part',
  initialState: null,
  reducers: {
    setPart(state, action) {
      return action.payload.part;
    },
  },
});

export const { setPart } = partSlice.actions;

export default partSlice.reducer;
