import BaseView from 'js/base_v3/views/view';
import Tabs from 'js/components/tabs';
import QuoteList from 'js/quote/list';
import QuoteTable from 'js/messages_v2/tables/quotes';
import OrderList from 'js/order/list';
import OrderTable from 'js/messages_v2/tables/orders';
import MessagesDetails from 'js/messages_v2/components/details';

/**
 * Messages Panel View.
 *
 * @class
 * @extends BaseView
 *
 * @param {DOMElement} container
 * @param {object}     [options]
 */
function MessagesPanelView(container, options) {
  BaseView.call(this, container, options);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {object}
   */
  this.bom = null;

  /**
   * @prop {object}
   */
  this.project = null;

  /**
   * @prop {object}
   */
  this.quote = null;

  /**
   * @prop {object}
   */
  this.order = null;

  /**
   * @prop {Tabs}
   */
  this.tabs = null;

  /**
   * @prop {QuoteList}
   */
  this.quoteList = null;

  /**
   * @prop {OrderList}
   */
  this.orderList = null;

  /**
   * @inheritDoc
   */
  this._initDefaults = function() {
    parent._initDefaults.call(this);

    this._extendDefaultOptions({
      tmplEl: '#messages-panel-view',
      tabs: {
        onTabShown(tabEl) {
          self._onTabShown(tabEl);
        },
      },
      quoteList: {
        saveFilterSelection: false,
        tableClass: QuoteTable,
        loadUrl: '/quote/list/all-quotes',
        filterGroup: {
          showResetButton: false,
        },
        details: {
          entityName: 'quote',
        },
      },
      orderList: {
        tableClass: OrderTable,
        loadUrl: '/order/list/orders',
        details: {
          entityName: 'order',
        },
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this._processOptions = function() {
    parent._processOptions.call(this);

    if (this.order) {
      this._options.orderList.activeRecord = this.order;
    }

    if (this.quote) {
      this._options.quoteList.activeRecord = this.quote;
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this._render = function() {
    this._container.append(this._getContent());
    return this;
  };

  /**
   * Get HTML content.
   *
   * @protected
   *
   * @return {string}
   */
  this._getContent = function() {
    return $(this._options.tmplEl).tmpl(this._options.tmplParams);
  };

  /**
   * @return {boolean}
   */
  this.isShown = function() {
    return this._container.find('.messagesPanelCt').hasClass('active');
  };

  /**
   * @return {MessagesPanelView}
   */
  this.toggle = function() {
    this._container.find('.messagesPanelCt').toggleClass('active');

    // First load - orders
    if (this._container.find('a[href="#messagesOrders"]').hasClass('active')) {
      this.showOrders();
    }

    // First load - quotes
    if (this._container.find('a[href="#messagesQuotes"]').hasClass('active')) {
      this.showQuotes();
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this._createSubcomponents = function() {
    parent._createSubcomponents.call(this);

    return this
      ._createTabs();
  };

  /**
   * Create tabs.
   *
   * @return {ProjectPricingView}
   */
  this._createTabs = function() {
    this.tabs = new Tabs(
      $('.messagingTabsCt', this._container),
      this._options.tabs,
    ).create();

    return this;
  };

  /**
   * Tab shown event handler.
   *
   * @param {DOMElement} tabEl
   */
  this._onTabShown = function(tabEl) {
    $('.messagingTab', this._container).addClass('hide');
    tabEl.removeClass('hide');

    switch (tabEl.attr('id')) {
      case 'messagesQuotes':
        this.showQuotes();
        break;
      case 'messagesOrders':
        this.showOrders();
        break;
      default:
        // Do nothing
    }
  };

  /**
   * @return {object}
   */
  this._getListOptions = function() {
    return {
      filterGroupCt: $('.messagingFilterGroupCt', this._container),
      detailsClass: MessagesDetails,
      detailsEl: $('.messagesDetailsCt', this._container),
      loadParams: {
        filters: {
          project_id: this.project ? this.project.id : null,
          bom_id: this.bom ? this.bom.id : null,
        },
      },
    };
  };

  /**
   * @return {MessagesPanelView}
   */
  this.showQuotes = function() {
    if (!this.quoteList) {
      this.quoteList = new QuoteList(
        $('div#messagesQuotes', this._container),
        $('div#messagesQuotes .messagesQuotesTable', this._container),
        this._getListOptions().extend(this._options.quoteList),
      ).create();

      return this;
    }

    this.quoteList.details.options.entityName = 'quote';

    this.quoteList.details.load(
      this.quoteList.getActiveRecord(),
    );

    return this;
  };

  /**
   * @return {MessagesPanelView}
   */
  this.showOrders = function() {
    if (!this.orderList) {
      this.orderList = new OrderList(
        $('div#messagesOrders', this._container),
        $('div#messagesOrders .messagesOrdersTable', this._container),
        this._getListOptions().extend(this._options.orderList),
      ).create();

      return this;
    }

    this.orderList.details.options.entityName = 'order';

    this.orderList.details.load(
      this.orderList.getActiveRecord(),
    );

    return this;
  };

  // Initialize
  this._init();
}

export default MessagesPanelView;
