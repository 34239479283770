/* eslint-disable max-len */
import BaseVendorQuotationCreateForm from 'js/base_v3/forms/quotation_forms/vendor-quotation-create-form';
import OrderHelper from 'js/order/helpers/helper';
/* eslint-enable max-len */

/**
 * Vendor Order Create Form.
 *
 * @class
 * @extends BaseVendorQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function VendorOrderCreateForm(record, options) {
  BaseVendorQuotationCreateForm.call(this, record, options);
  const parent = this.clone();
  const self = this;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'order',
      entityLabel: this.translator.get('vendor_order_label'),
      actionUrl: '/order/vendor/save',
      tmplParams: {
        showAgreementCheckbox: true,
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.create = function() {
    parent.create.call(this);

    this.getSubmitButton().addClass('disable');

    return this;
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('click', '.acceptTermsAndConditions', function() {
      self.onAcceptTermsAndConditionsClick($(this));
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (this.coreOrderIntegrationEnabled) {
      this.options.requiredFields = this.options.requiredFields || [];

      if (!this.options.requiredFields.includes('purchase_order_number')) {
        this.options.requiredFields.push('purchase_order_number');
      }
    }

    return this.extendOptions({
      hiddenFields: [
        `${this.options.entityName}[estimated_close_date]`,
        `${this.options.entityName}[location_id]`,
        `${this.options.entityName}[external_customer_number]`,
        `${this.options.entityName}[external_number]`,
        `${this.options.entityName}[external_project_name]`,
        `${this.options.entityName}[revision_number]`,
        `${this.options.entityName}[attach_to]`,
        'validity_field_group',
      ],
    });
  };

  /**
   * @inheritDoc
   */
  this.prepareServerParams = function() {
    parent.prepareServerParams.call(this);

    if (_.isObject(this.projectField)) {
      const project = this.projectField.getRecord();

      this.options.serverParams = this.options.serverParams.extend({
        order: {
          estimated_close_date: moment().format('MM/YYYY'),
          close_confidence: Object.get(project, 'close_confidence'),
          valid_until: this.helper
            .getValidUntilFromProject(project)
            .format('MM/DD/YYYY'),
        },
      });
    }

    return this;
  };

  /**
   * Accept terms and conditions event hendler.
   *
   * @param {DOMElement} checkboxEl
   */
  this.onAcceptTermsAndConditionsClick = function(checkboxEl) {
    this.getSubmitButton().toggleClass('disable', !checkboxEl.prop('checked'));
  };

  /**
   * @inheritDoc
   */
  this.getHelper = function() {
    return new OrderHelper();
  };

  // Initialize
  this.init();
}

export default VendorOrderCreateForm;
