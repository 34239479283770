/**
 * Map array of objects with name and value, to object with name 
 * as key and url as value.
 */
export const mapArrayToNameValueObject = (fields) => {
  return fields?.reduce(
    (map, field) => ({
      ...map,
      [field.name]: field.value,
    }),
    {}
  );
};
