import BaseDataTable from 'js/base_v2/data-table';
import AttachmentList from 'js/base_v3/components/attachment-list';
/**
 * Message Table.
 *
 * @class
 * @extends BaseDataTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     options
 */
function MessagesTable(tableEl, options) {
    BaseDataTable.call(this, tableEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            loadMore: true,
            dataTable: {
                pageLength: 1000
            },
            loadParams: {
                sortField: ['message_creation_date'],
                sortDir: ['asc']
            },
            rowClickAction: this.ROW_CLICK_ACTION_NONE
        });
    };

    /**
     * @inheritDoc
     */
    this.getColumns = function() {
        return [{
            data: null,
            render: function(data) {
                return self.renderMessageBlock(data);
            }
        }];
    };

    /**
     * @param {object} data
     * @return {string}
     */
    this.renderMessageBlock = function(data)
    {
        var el = $('#messages-message').tmpl({
            text: data.message_text,
            user: data.sender_name,
            date: moment(data.message_creation_date, 'YYYY-MM-DD hh:mm:ss').calendar()
        });

        if (!data.sender_is_current_user) {
            el.addClass('me_message');
        }

        if (data.message_type === 'quote_released') {
            el.addClass('autocreated_message')
                .addClass('quote_released');

            var hasMessageText = data.message_text && $(data.message_text).text();
            el.find('.textCt').html(
                "Quote released by " + data.sender_name + "." + (hasMessageText ?
                    "<div class=\"released-quote-message\">" + data.message_text + "</div>" :
                    "")
            );
            el.find('.name_user').html("");
        }

        if (data.attachments.length > 0) {
            var attachmentCt = $('<ul class="attachment-list attachmentsCt"></ul>');

            new AttachmentList(attachmentCt, {
                files: data.attachments
            }).create();

            el.find('.textCt').append(attachmentCt);
        }

        if (data.cc_recipients.length > 0) {
            const label = $('<span>')
                .text(this.translator.getUppercased('label_cc'));
            el.find('.ccRecipients').append(label);

            data.cc_recipients.forEach(({ email, name }) => {
                const ccHtml = $('<div>')
                    .addClass('cc-recipient')
                    .text(`${name} ${email}`);

                el.find('.ccRecipients').append(ccHtml);
            });
        }

        return el[0].outerHTML;
    };

    // Initialize
    this.init();
}

export default MessagesTable;
