import styled, { css } from 'styled-components';

export const DateInput = styled.div`
  position: relative;
  border-left: ${({ theme, selectsRange = true }) =>
    selectsRange && `1px solid ${theme.palette.neutral.lightest}`};
  margin-left: -1px;
  font-size: inherit;

  .react-datepicker {
    ${({ selectsRange }) => selectsRange && css`
      border: none;
      border-radius: 0px;
    `};
  }

  .react-datepicker-wrapper {
      width: 100%;
  }

  .react-datepicker__input-container input {
      width: 100%;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: #dee5e9;;
      border-radius: 0;
      border-style: solid;
      border-width: 1px;
      box-shadow: none;
      cursor: default;
      display: flex;
      justify-content: space-between;
      min-height: 45px;
      outline: 0;
      position: relative;
      transition: all 100ms;
      box-sizing: border-box;
      padding: 3px 10px;
      cursor: pointer;
      color: inherit;
      font-size: 12px;

      &:focus,&:hover {
          box-shadow: none;
          border-color: hsl(0, 0%, 80%);
      }
  }

  &:focus,&:hover {
      box-shadow: none;
      border-color: hsl(0, 0%, 80%);
  }
`;
