import AjaxForm from 'js/base_v2/ajax-form';
import Ajax from 'js/components/ajax';
import { eventDispatcher } from 'js/react/core/services';
import { MessageCcChanged } from 'js/react/messages/events';
import mountCcForm from 'js/react/messages/elements/messageCcElement';

/**
 * Modal Form.
 *
 * @class
 * @extends AjaxForm
 *
 * @param {DOMElement} formEl
 * @param {object}     options
 */
function MessagesComposeForm(formEl, options) {
    AjaxForm.call(this, formEl, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {object[]}
     */
    this.ccRecipients = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.extendDefaultOptions({
            entityType: undefined,
            entity: undefined
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.processOptions = function() {
        parent.processOptions.call(this);

        this.setEntity(
            this.options.entityType,
            this.options.entity
        );

        return this;
    };

    /**
     * @param {string} type
     * @param {object} entity
     * @return {MessagesComposeForm}
     */
    this.setEntity = function(type, entity) {
        this._getInput('text').removeAttr('disabled');
        this._getInput(type).val(entity.id);
        this._getInput('type').val('v2_' + type + '_message');

        return this;
    };

    /**
     * @inheritDoc
     */
    this.registerEventListeners = function() {
        parent.registerEventListeners.call(this);

        this.formEl.on('click', '.addAttachment', function(e) {
            e.preventDefault();

            // pressing enter triggers this click event
            if (e.clientX === 0 && e.clientY === 0 && e.pageX === 0 && e.pageY === 0) {
                return self.formEl.trigger('submit');
            }

            if (self.uploadInProgress) {
                return;
            }

            self.options.fileUploadFieldEl.click();
        });

        this.formEl.on('click', '.addCc', function(e) {
            self.onAddCc(e);
        });

        eventDispatcher.subscribe(
            MessageCcChanged.NAME,
            event => this.onCcChanged(event.ccRecipients)
        );

        return this;
    };

    /**
     * On CC changed.
     *
     * @param {object[]} ccRecipients
     */
    this.onCcChanged = function(ccRecipients) {
        this.ccRecipients = ccRecipients;
    };

    /**
     * On add CC.
     *
     * @param {object} event
     */
    this.onAddCc = function(event) {
        mountCcForm('addMessageCc');
    };

    /**
     * @inheritDoc
     */
    this.reset = function(resetAfterSubmit) {
        if (resetAfterSubmit) {
            this._getInput('text').val('');
            this.options.attachmentsCt.html('');
        } else {
            this._getInput('quote').val('');
            this._getInput('order').val('');
        }

        return this;
    };

    /**
     * @param {string} name
     * @return {DOMElement}
     */
    this._getInput = function(name) {
        return this.formEl.find('input[name="message[' + name + ']"]');
    };

    /**
     * @inheritDoc
     */
    this.getConstraints = function() {
        return {
            'message[text]': {
                'required': {
                    'message': 'Message text cannot be empty.'
                }
            }
        };
    };

    /**
     * @inheritdoc
     */
    this.getFormData = function(full, processed) {
        let formData = parent.getFormData.call(this, full, processed);

        if (this.ccRecipients) {
            formData = formData.extend({
                cc: this.ccRecipients,
            });
        }

        return formData;
    };

    this.init();
}

export default MessagesComposeForm;
