import React from 'react';
import styled from 'styled-components';

import Button from 'partfiniti/core/components/Button';
import DateInterval from 'partfiniti/core/components/DateInterval';
import Select from 'partfiniti/core/components/Select';

import Search from './Search';
import './filters.scss';

const FiltersBar = styled.div`
  height: ${({ theme }) => theme.filterBarHeight}px;
  width: 100vw;
  border-bottom: 1px solid #ccc;
`;

const Section = styled.div`
  height: 100%;
  border-right: 1px solid #ccc;
  display: inline-block;
  padding: 7px 30px;
`;

const renderFilterField = (filter, values, onChangeFilter, onAdd) => {
  switch (filter.type) {
    case 'search':
      return (
        <Search
          {...{
            value: values[filter.name],
            onChange: value => onChangeFilter(filter.name, value),
          }}
        />
      );
    case 'select':
      return (
        <Select
          {...{
            ...filter.props,
            value: values[filter.name],
            onChange: value => onChangeFilter(filter.name, value),
          }}
        />
      );
    case 'date':
      return (
        <DateInterval
          onChange={(value) => onChangeFilter(filter.name, value)}
          name={filter.name}
          value={values[filter.name]}
          {...filter.props}
        />
      );
    case 'add':
      return (
        <Button onClick={onAdd} size={filter.size}>
          {filter.label}
        </Button>
      );
    default:
      throw new Error(`Unknown filter type "${filter.type}"`);
  }
};

const FiltersView = ({
  onChangeFilter,
  onAdd,
  values,
  config,
}) => {
  return (
    <FiltersBar>
      {config.map(filter => (
        <Section key={filter.name} style={{ width: filter.width }}>
          {renderFilterField(filter, values, onChangeFilter, onAdd)}
        </Section>
      ))}
    </FiltersBar>
  );
};

export default FiltersView;
