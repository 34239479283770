import BaseHelper from 'js/base_v3/helpers/helper';

const staticSelf = BaseExportHelper;

/**
 * @const
 */
staticSelf.FILE_TYPE_XLS = 'xls';

/**
 * @const
 */
staticSelf.FILE_TYPE_PDF = 'pdf';

/**
 * @const
 */
staticSelf.CONTENT_SELECTED = 'selected';

/**
 * @const
 */
staticSelf.CONTENT_FILTERED = 'filtered';

/**
 * Base Export Helper.
 *
 * @class
 * @abstract
 * @extends BaseHelper
 *
 * @param {?object} [options]
 */
function BaseExportHelper(options) {
  BaseHelper.call(this, options);
}

export default BaseExportHelper;
