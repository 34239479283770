import React from 'react';
import { ReactSVG } from 'react-svg';

const getPath = name => {
  switch (name) {
    case 'loader': return '/images/icons/loader.svg';
    case 'search': return '/images/icons/search.svg';
    case 'arrow_left': return '/images/icons/arrow_left.svg';
    case 'calendar': return '/images/icons/calendar.svg';
    default: throw new Error(`Unknwon SVG icon "${name}"`);
  }
};

const SvgIcon = ({ name, classNames, style }) => {
  return (
    <ReactSVG
      src={getPath(name)}
      className={classNames}
      style={style}
    />
  );
}

export default SvgIcon;
