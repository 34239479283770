import React from 'react';

const RateTitleView = ({ label, interval = 'hr' }) => (
  <div>
    {label}
    /
    <span style={{ textTransform: 'none' }}>{interval}</span>
  </div>
);

export default RateTitleView;
