import BaseQuotation from 'js/base_v3/models/quotation';

const staticSelf = Order;

/**
 * @const
 */
staticSelf.TYPE_CUSTOMER = BaseQuotation.TYPE_CUSTOMER;

/**
 * @const
 */
staticSelf.TYPE_VENDOR = BaseQuotation.TYPE_VENDOR;

/**
 * @const
 */
staticSelf.STATUS_DRAFT_CUSTOMER = 'draft-customer';

/**
 * @const
 */
staticSelf.STATUS_DRAFT_VENDOR = 'draft-vendor';

/**
 * @const
 */
staticSelf.STATUS_REQUESTED = 'requested-by-customer';

/**
 * @const
 */
staticSelf.STATUS_ORDERED = 'ordered';

/**
 * @const
 */
staticSelf.STATUS_ACCEPTED = 'accepted';

/**
 * @const
 */
staticSelf.STATUS_DECLINED = 'declined';

/**
 * @const
 */
staticSelf.STATUS_CANCELED = 'canceled';

/**
 * @const
 */
staticSelf.STATUS_SHIPPED_PARTIAL = 'shipped-partial';

/**
 * @const
 */
staticSelf.STATUS_SHIPPED_COMPLETE = 'shipped-complete';

/**
 * Order Entity.
 *
 * @class
 * @extends BaseQuotation
 *
 * @param {object} [data]
 */
function Order(data) {
  BaseQuotation.call(this, data);

  // Initialize
  this._init();
}

export default Order;
