import { createSlice } from '@reduxjs/toolkit';

const partConfigurationSlice = createSlice({
  name: 'partConfiguration',
  initialState: null,
  reducers: {
    setPartConfiguration(state, action) {
      return action.payload.partConfiguration;
    },
  },
});

export const { setPartConfiguration } = partConfigurationSlice.actions;

export default partConfigurationSlice.reducer;
