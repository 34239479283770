import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import { translator } from 'js/translator';

import { createCustomStyles } from './selectStyles';

const AsyncSelect = ({
  loadOptions,
  value,
  onChange,
  options,
  getValue = option => option.id,
  getLabel = option => option.name,
  isMulti = false,
  isClearable = false,
  placeholder = translator.get('select_options'),
  size,
}) => {
  const customStyles = createCustomStyles(size);

  return (
    <AsyncPaginate
      {...{
        styles: customStyles,
        value,
        onChange,
        loadOptions,
        options,
        getOptionValue: getValue,
        getOptionLabel: getLabel,
        placeholder,
        isMulti,
        isClearable,
        additional: {
          page: 1,
        },
        debounceTimeout: 200,
      }}
    />
  );
};

export default AsyncSelect;
