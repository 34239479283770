import React from 'react';
import styled from 'styled-components';

const Tr = styled.div`
  background: #f2f2f2;
  padding: 0 15px;

  &.has-border {
    border-bottom: 1px solid #ccc;
  }
`;

const Th = styled.div`
  border-bottom: none;
  font: 300 12px/1.2em "Open Sans",sans-serif;
  letter-spacing: .02em;
  padding: ${({ lines }) => lines == 2 ? 12 : 25}px 15px;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
  text-align: left;
  vertical-align: top;
`;

const Header = ({
  headerGroups,
  borders,
  lines,
}) => headerGroups.map(headerGroup => (
  <Tr // eslint-disable-line react/jsx-key
    {...headerGroup.getHeaderGroupProps()}
    className={borders ? 'has-border' : ''}
  >
    {headerGroup.headers.map(column => (
      <Th // eslint-disable-line react/jsx-key
        {...{
          ...column.getHeaderProps(column.getSortByToggleProps()),
          lines,
          style: {
            width: column.width,
            minWidth: column.minWidth,
          },
        }}
      >
        {column.Header}
      </Th>
    ))}
  </Tr>
));

export default Header;
