import BaseDataTable from 'js/base_v2/data-table';
import AttachmentList from 'js/base_v3/components/attachment-list';
import TableColumnRenderHelper from 'js/helpers/table/column-render-helper';

/**
 * Message Table.
 *
 * @class
 * @extends BaseDataTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     options
 */
function MessageTable(tableEl, options) {
  BaseDataTable.call(this, tableEl, options);
  const parent = this.clone();
  const self = this;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.defaultOptions = this.defaultOptions.extend({
      rowClickAction: 'click',
      columnWidths: [250, 250, 200, 200, 200, 100, 20, 500, 250],
      dataTable: {
        columnDefs: [{
          orderable: false,
          targets: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        }],
      },
    });

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onRowClick = function(tr) {
    parent.onRowClick.call(this, tr);

    tr.find('.intro,.full').toggleClass('hide');
  };

  /**
   * @inheritDoc
   */
  this.getColumns = function() {
    return [{
      name: 'sender_name',
      type: TableColumnRenderHelper.TYPE_TEXT,
    }, {
      name: 'recipients',
      type: TableColumnRenderHelper.TYPE_TEXT,
      render(data) {
        return self.renderRecipients(data);
      },
    }, {
      name: 'cc_recipients',
      type: TableColumnRenderHelper.TYPE_TEXT,
      render(data) {
        return data.cc_recipients
          .map((cc) => cc.name || cc.email)
          .join(', ');
      },
    }, {
      name: 'message_type',
      type: TableColumnRenderHelper.TYPE_TEXT,
      render(data) {
        return self.renderType(data);
      },
    }, {
      name: 'job_name',
      type: TableColumnRenderHelper.TYPE_TEXT,
      render(data) {
        return self.renderJob(data);
      },
    }, {
      name: 'custom_id',
      type: TableColumnRenderHelper.TYPE_TEXT,
      render(data) {
        return self.renderEntity(data);
      },
    }, {
      name: 'has_attachments',
      render(data) {
        return self.renderHasAttachments(data);
      },
    }, {
      name: 'message_text',
      className: 'text_left',
      render(data) {
        return self.renderMessageText(data);
      },
    }, {
      name: 'message_creation_date',
      className: 'text_left',
      render(data) {
        return moment(
          data.message_creation_date,
          'YYYY-MM-DD hh:mm:ss',
        ).calendar();
      },
    }, {
      type: TableColumnRenderHelper.TYPE_RESPONSIVE_BTN,
    }];
  };

  /**
   * @param  {object} data
   * @return {string}
   */
  this.renderEntity = function(data) {
    if (data.quote_id > 0) {
      return `
        <a
          class="defaultLink default-link blue-color-hover"
          href="/quote/part/list?quote=${data.quote_id}"
        >
          ${data.quote_custom_id}
        </a>
      `;
    }

    if (data.order_id > 0) {
      return `
        <a
          class="defaultLink default-link blue-color-hover"
          href="/order/part/list?order=${data.order_id}"
        >
          ${data.order_custom_id}
        </a>
      `;
    }

    return `
      <span class="placeholder">
        ${this.translator.get('not_available_label')}
      </span>
    `;
  };

  /**
   * @param  {object} data
   * @return {string}
   */
  this.renderRecipients = function(data) {
    const recipients = _.filter(data.recipients.map((r) => r.name));

    if (recipients.length === 0 && data.message_data) {
      recipients.push(data.message_data.recipient_full_name);
    }

    return this.renderExpandableText(
      recipients.join(', '),
      recipients.join('<br>'),
    );
  };

  /**
   * @param  {object} data
   * @return {string}
   */
  this.renderType = function(data) {
    const messageTypeLabelsMap = {
      quote_released: 'Quote Release',
      v2_quote_message: 'Quote Message',
      v2_order_message: 'Order Message',
      visitor_invitation: 'Contact Invitation',
      contact_invitation: 'Contact Invitation',
      user_invitation: 'User Invitation',
    };

    return !_.isUndefined(messageTypeLabelsMap[data.message_type]) ?
      messageTypeLabelsMap[data.message_type] :
      data.message_type.replace(/_/g, ' ').capitalizeWords();
  };

  /**
   * @param  {object} data
   * @return {string}
   */
  this.renderJob = function(data) {
    return this.renderText(data.job_name) +
      this.renderText(data.project_name, 'help_text');
  };

  /**
   * @param  {object} data
   * @return {string}
   */
  this.renderHasAttachments = function(data) {
    if (data.attachments.length > 0) {
      return '<svg><use xlink:href="#icon-paperclip"></use></svg>';
    }

    return '';
  };

  /**
   * @param  {object} data
   * @return {string}
   */
  this.renderMessageText = function(data) {
    const messageText = data.message_text;

    if (messageText === null) {
      return '';
    }

    if (data.attachments.length > 0) {
      new AttachmentList($('<ul class="attachment-list attachmentsCt"></ul>'), {
        files: data.attachments,
      }).create();
    }

    const plainText = messageText.replace(/(<([^>]+)>)/ig, '');
    let intro = plainText.substr(0, 50);

    if (plainText.length > 50) {
      intro += '...';
    }

    return `
      <div class="messageTextIntro">
        <span>${intro}</span>
      </div>
    `;
  };

  /**
   * @param  {string} introSource
   * @param  {string} fullText
   * @return {string}
   */
  this.renderExpandableText = function(introSource, fullText) {
    // eslint-disable-next-line no-param-reassign
    introSource = introSource || '';
    // eslint-disable-next-line no-param-reassign
    fullText = fullText || introSource;

    let intro = introSource.substr(0, 255);

    if (intro !== introSource) {
      intro += '...';
    }

    return `
      <span class="intro">${_.escape(intro)}</span>
      <span class="full hide">${fullText}</span>
    `;
  };

  // Initialize
  this.init();
}

export default MessageTable;
