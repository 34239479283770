import React from 'react';

import { notifier } from 'js/components_v2/default-notifier';
import { translator } from 'js/translator';

import { api } from 'partfiniti/core/services';

// Not used directly, but required for the custom forms (line 58)
/* eslint-disable no-unused-vars */
import AddLogForm from 'partfiniti/bom/components/AddLogForm';
/* eslint-enable no-unused-vars */

import CrudForm from 'partfiniti/core/components/CrudForm';
import Filters from 'partfiniti/core/components/Filters';
import List from 'partfiniti/core/components/List';
import Confirmation from 'partfiniti/core/components/Confirmation';
import Visible from 'partfiniti/core/components/Visible';

const INPUT_FILTER_TYPES = [
  'search',
  'select',
  'text',
  'date',
];

const initState = filters => filters.reduce((state, filter) => {
  if (!INPUT_FILTER_TYPES.includes(filter.type)) {
    return state;
  }

  return {
    ...state,
    [filter.name]: filter?.default,
  };
}, {});

const filtersReducer = (state, action) => {
  return {
    ...state,
    [action.name]: action.value,
  };
};

const FilteredCrudList = ({
  filters: filtersConfig,
  createForm,
  form,
  removeConfirmation = {},
  list,
}) => {
  const [filters, setFilter] = React.useReducer(
    filtersReducer,
    filtersConfig,
    initState,
  );
  const [upserted, setUpserted] = React.useState(null);
  const [removing, setRemoving] = React.useState(null);
  const [listReloadRequested, setListReloadRequested] = React.useState(0);

  const CreateForm = createForm && upserted === 'new' ? createForm : CrudForm;

  const handleRemoveRequest = () => {
    setRemoving(upserted);
    setUpserted(null);
  };

  const handleRemove = async () => {
    try {
      notifier.notifyLoading(translator.getTitle('message_removing'));

      await api.post(
        form.removePath,
        form.processRemoveParams(removing)
      );

      notifier.notifySuccess(translator.getTitle('message_success'));
      setListReloadRequested(Date.now());

      setRemoving(null);
    } catch (error) {
      notifier.notifyError(translator.getTitle('message_failure'));
    }
  };

  const handleCancelRemove = () => {
    setUpserted(removing);
    setRemoving(null);
  };

  const handleSaveSuccess = () => {
    setUpserted(null);
    setListReloadRequested(Date.now());
  };

  return (
    <>
      <Filters
        {...{
          onChangeFilter: (name, value) => setFilter({ name, value }),
          onAdd: () => setUpserted('new'),
          values: filters,
          config: filtersConfig,
        }}
      />

      <Visible when={upserted !== null}>
        <CreateForm
          {...{
            ...form,
            initialValues: {
              ...(form?.initialValues || {}),
              ...(form.editInitialValues(upserted) || {}),
            },
            isOpen: upserted !== null,
            onClose: () => setUpserted(null),
            onSuccess: handleSaveSuccess,
            onRemove: handleRemoveRequest,
          }}
        />
      </Visible>

      <Visible when={removing !== null}>
        <Confirmation
          {...{
            ...removeConfirmation,
            isOpen: removing !== null,
            onConfirm: handleRemove,
            onCancel: handleCancelRemove,
          }}
        />
      </Visible>

      <List
        {...{
          filters,
          listReloadRequested,
          onRowClick: setUpserted,
          ...list,
        }}
      />
    </>
  );
}

export default FilteredCrudList;
