import React from 'react';

import TextField from 'partfiniti/core/components/TextField';

const Search = ({ value, onChange }) => (
  <TextField
    value={value || ''}
    onChange={onChange}
    leftIcon="search"
    inputClass="search-input"
  />
);

export default Search;
