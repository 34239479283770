const partfinitiTheme = {
  palette: {
    primary: {
      main: '#33BEFF',
      dark: '#265e9d',
    },
    secondary: {
      main: '#008000',
    },
    tertiary: {
      main: '#a81f1f',
    },
    neutral: {
      main: '#f2f2f2',
      light: '#dee5e9',
      lightest: '#ccc',
      dark: '#2e3037',
      darkest: '#090909',
    },
    text: {
      primary: '#000000',
      secondary: '#fff',
      tertiary: '#1a456d',
      active: '#1c1c1c',
      placeholder: '#999',
      button: '#333333',
      dropdown: '#2e3037',
      link: '#0275d8',
    },
    backgrounds: {
      main: '#fff',
      primary: '#f2f2f2',
      contrast: '#000',
      range: '#ebf4f8',
      columnHeader: '#f2f2f2',
      evenRow: '#fff',
      oddRow: '#f9f9f9',
      evenRowColor: '#fff',
      oddRowColor: '#eff2f4',
      hoverRow: '#f6f6f6',
      tab: '#e4e4e4',
      loading: 'rgba(0, 0, 0, 0.5)',
      chip: '#ffa500',
      dragAndDrop: '#e7e7e7',
      pill: '#dee5e8',
      button: '#199ED8',
      option: '#FAFAFA',
      border: '#C9CDCF',
      arrow: '#be5f4b',
    },
    hover: {
      main: 'hsl(0, 0%, 80%)',
      borderColor: '#424345',
      chip: '#ef9c04',
    },
    filterIcon: '#7f7f7f',
    success: '#d1eed1',
    error: '#fddfde',
    loading: '#edf8fc',
    checkMark: 'rgb(192, 192, 192)',
    borderColor: 'rgb(163 163 163)',
    tableBorderColor: '#acacac',
    tableBorderColorLight: '#c5c5c5',
  },
  topBigFixedHeaderHeight: 219, // px
  filterBarHeight: 64, // px
  headerFooterTableHeight: 63, // px
};

export default partfinitiTheme;
