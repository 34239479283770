import { createSlice } from '@reduxjs/toolkit';

const quoteSlice = createSlice({
  name: 'quote',
  initialState: null,
  reducers: {
    setQuote(state, action) {
      return action.payload.quote;
    },
  },
});

export const { setQuote } = quoteSlice.actions;

export default quoteSlice.reducer;
