import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
/**
 * User Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} selectEl
 * @param {object}     options
 */
function UserField(fieldCt, selectEl, options) {
    BaseSelect2AutocompleteField.call(this, fieldCt, selectEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.extendDefaultOptions({
            entitiesName: 'users',
            select2: {
                placeholder: '',
                allowClear: false,
                ajax: {
                    url: '/admin/user/autocomplete'
                }
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.getRecordLabel = function(record) {
        if (!record.id) {
            return parent.getRecordLabel.call(this, record);
        }

        var pieces = [];

        pieces.push('#' + record.id);
        pieces.push(this.getRecordName(record));
        pieces.push('(' + record.email + ')');

        return pieces.join(' ');
    };

    /**
     * Get record name.
     *
     * @param  {object} record
     * @return {string}
     */
    this.getRecordName = function(record) {
        if (record.name) {
            return record.name;
        }

        return $.trim((record.first_name || '') + ' ' + (record.last_name || ''));
    };

    // Initialize
    this.init();
}
export default UserField;
