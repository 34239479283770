const RecordsUtils = {};

/**
 * Transform array of objects into array of records (instances of given entity
 * class) if not already instances of given entity class.
 *
 * @param  {object[]}     objects
 * @param  {BaseEntity}   entityClass
 * @return {BaseEntity[]}
 */
RecordsUtils.objectsToRecords = function(objects, entityClass) {
  return objects.map((obj) => (obj instanceof entityClass ?
    obj :
    new entityClass(obj)
  ));
};

/**
 * Get IDs from records.
 *
 * @param  {BaseEntity[]} records
 * @return {array}
 */
RecordsUtils.getIds = function(records) {
  return RecordsUtils.getFieldValues(records, 'id');
};

/**
 * Get field values from records.
 *
 * @param  {BaseEntity[]} records
 * @return {array}
 */
RecordsUtils.getFieldValues = function(records, field) {
  return records.map((record) => record[field]);
};

export default RecordsUtils;
