import BaseTypeChooseForm from 'js/base_v3/forms/type-choose-form';
/**
 * Order Type Choose Form.
 *
 * @class
 * @extends BaseTypeChooseForm
 *
 * @param {object} options
 */
function OrderTypeChooseForm(options) {
    BaseTypeChooseForm.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entityName: 'order',
            tmplParams: {
                title: 'Create an Order',
                switchToCustomerFormName: 'customerOrderCreateForm',
                switchToVendorFormName: 'vendorOrderCreateForm'
            }
        });
    };

    // Initialize
    this.init();
}

export default OrderTypeChooseForm;
