export const findValuesByPrefix = (object, prefix) => {
  const values = [];

  Object.keys(object).forEach(key => {
    if (key.startsWith(prefix)) {
      values.push(object[key]);
    }
  });

  return values;
};
