import React from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import FieldLabel from '../FieldLabel';
import Icon from '../Icon';
import Visible from '../Visible';

import './textField.scss';

const LeftIconContainer = styled.div`
  position: absolute;
  left: 38px;
  top: 18px;
  font-size: 1.3em;
`;

const TextFieldView = ({
  label,
  type = 'text',
  rows = 3, // for textarea
  mask,

  value,
  onChange,
  onBlur,
  required,
  disabled,

  leftIcon,
  inputClass,
}) => {
  let inputClasses = `form-control ${inputClass || ''}`;
  if (leftIcon) {
    inputClasses += ' with-left-icon';
  }

  return (
    <div className="form-group">
      <Visible when={Boolean(label)}>
        <FieldLabel {...{ label, required }} />
      </Visible>

      <Visible when={Boolean(leftIcon)}>
        <LeftIconContainer>
          <Icon name={leftIcon}/>
        </LeftIconContainer>
      </Visible>

      <Visible when={!!mask}>
        <InputMask
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          className={inputClasses}
          mask={mask}
          alwaysShowMask
        />
      </Visible>

      <Visible when={type !== 'textarea' && !mask}>
        <input
          type={type}
          value={value === null ? '' : value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          className={inputClasses}
        />
      </Visible>

      <Visible when={type === 'textarea'}>
        <textarea
          value={value === null ? '' : value}
          rows={rows}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          className={inputClasses}
        />
      </Visible>
    </div>
  );
};

export default TextFieldView;
