import React from 'react';

import { eventDispatcher } from 'js/react/core/services';
import { CloseModalEvent } from 'js/react/core/events';

import ModalDialog from './ModalDialog';
import ModalView from './ModalView';

const Modal = ({
  header,
  error,

  isOpen,
  onClose,
  size,

  children,
}) => {
  const closeModal = () => {
    if (typeof isOpen === 'boolean') {
      return onClose();
    }

    eventDispatcher.dispatch(new CloseModalEvent());
  };

  const content = (
    <ModalView {...{
      header,
      error,
      closeModal,
    }}>
      {children}
    </ModalView>
  );

  if (typeof isOpen !== 'boolean') {
    return content;
  }

  return (
    <ModalDialog
      {...{
        isOpen,
        onClose: closeModal,
        size,
      }}
    >
      {content}
    </ModalDialog>
  );
}

export default Modal;
