import BaseEntityTable from 'js/base_v2/tables/entity-table';
import TableColumnRenderHelper from 'js/helpers/table/column-render-helper';
import Order from 'js/order/models/order';
import OrderConfig from 'js/order_v2/configs/order-config';

/**
 * Order Table.
 *
 * @class
 * @extends BaseEntityTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 * @param {boolean}    [inherited]
 */
function OrderTable(tableEl, options, inherited) {
  BaseEntityTable.call(this, tableEl, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityClass: Order,
      loadMore: true,
      rowClickAction: this.ROW_CLICK_ACTION_CLICK,
      dataTable: {
        pageLength: 15,
        columnDefs: [{
          orderable: false,
          targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        }],
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.onRowClick = function(tr) {
    const order = this.getRowData(tr);
    window.location.href = `/order/part/list?order=${order.id}`;
  };

  /**
   * @inheritDoc
   */
  this.createdRow = function(tr, record, index) {
    parent.createdRow.call(this, tr, record, index);

    this.highlightRow(record, tr);
  };

  /**
   * @inheritDoc
   */
  this.getColumns = function() {
    return [{
      // 1. Checkbox
      className: 'check_item first-col highlight-bar',
      type: TableColumnRenderHelper.TYPE_CHECKBOX,
    }, {
      // 2. Customer
      name: 'customer',
      className: 'text_left',
      render: (order) => this.renderer.renderCustomer(order),
    }, {
      // 3. Vendor
      name: 'vendor',
      className: 'text_left',
      render: (order) => this.renderer.renderVendor(order),
    }, {
      // 4. Job
      name: 'job_name',
      className: 'text_left',
      render: (order) => this.renderer.renderJob(order),
    }, {
      // 5. Order custom ID
      name: 'order_custom_id',
      className: 'orderCustomIdCol text_right',
      render: (order) => this.renderer.renderOrderCustomId(order),
    }, {
      // 6. Quote custom ID
      name: 'quote_custom_id',
      className: 'text_right',
      render: (order) => this.renderer.renderQuoteCustomId(order),
    }, {
      // 7. Total price
      name: 'total_price',
      className: 'text_right',
      type: TableColumnRenderHelper.TYPE_PRICING,
    }, {
      // 8. Created
      name: 'created_at',
      className: 'text_left',
      type: TableColumnRenderHelper.TYPE_DATE,
    }, {
      // 9. Status
      name: 'status',
      className: 'text_left',
      render: (order) => this.renderer.renderStatus(order),
    }, {
      // 10. Responsive Button
      className: 'text_left',
      type: TableColumnRenderHelper.TYPE_RESPONSIVE_BTN,
    }];
  };

  /**
   * Highlight row.
   *
   * @param  {string}     record
   * @param  {DOMElement} tr
   * @return {OrderTable}
   */
  this.highlightRow = function(record, tr) {
    let className = '';

    switch (record.type) {
      case Order.TYPE_CUSTOMER:
        className = 'blue';
        break;
      case Order.TYPE_VENDOR:
        className = 'green';
        break;
      default:
        // Do nothing
    }

    $('td.first-col.highlight-bar', tr).addClass(className);

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getEntityConfig = function() {
    return new OrderConfig();
  };

  if (true !== inherited) {
    // Initialize
    this.init();
  }
}

export default OrderTable;
