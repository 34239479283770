import React from 'react';

import './button.scss';

const getSizeClass = size => {
  switch (size) {
    case 'sm': return 'btn_sm';
    case 'xs': return 'btn_xs';
    default: return '';
  }
};

const getTypeClass = type => {
  switch (type) {
    case 'danger': return 'btn_danger';
    default: return '';
  }
};

const ButtonView = ({
  onClick,
  disabled,
  size, // Optional. Allowed: sm, xs
  type, // Optional. Allowed: danger
  children,
}) => (
  <button
    onClick={onClick}
    type="button"
    className={`btn btn_block ${getSizeClass(size)} ${getTypeClass(type)}`}
    disabled={disabled}
  >
    {children}
  </button>
);

export default ButtonView;
