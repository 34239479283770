import ModalForm from 'js/base_v2/modal-form';
import QuillTextarea from 'js/components_v2/quill-textarea';
/**
 * Message Text Modal.
 *
 * @class
 * @extend ModalForm
 *
 * @param {object} options
 */
function MessageTextModal(options) {
    ModalForm.call(this, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {string}
     */
    this.message = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            tmplEl: '#full-message-modal',
            tmplParams: {
                title: this.translator.get('message_label'),
            },
            onShown: function() {
                self.onMessageShown();
            },
            quill: {
                quillEditor: {
                    modules: {
                        toolbar: false
                    }
                }
            }
        });
    };

    /**
     * Message text modal shown event handler.
     *
     * @return {MessageTextModal}
     */
    this.onMessageShown = function() {
        new QuillTextarea(
            $('.textEditor', this.modalCt),
            this.options.quill.extend({
                initialContent: this.message
            })
        ).create();
    };

    // Initialize
    this.init();
}

export default MessageTextModal;
