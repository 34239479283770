import { toDate, getMonth, getYear } from 'date-fns';

import { DATE_OPTIONS } from 'partfiniti/core/types';

export const DISPLAY_FORMAT = 'MM/DD/YY';

export const SERVER_FORMAT_DATE = 'yyyy-MM-DD';

const today = new Date();
const currentYear = getYear(today);
const currentMonth = new Date(`${getMonth(today) + 1}/01/${currentYear}`);
const lastMonth = new Date(`${getMonth(today)}/01/${currentYear}`);

const getLastDayOfMonth = (date) => {
  const selectedDate = toDate(date);
  const month = selectedDate.getMonth();

  selectedDate.setFullYear(selectedDate.getFullYear(), month + 1, 0);
  selectedDate.setHours(0, 0, 0, 0);

  return selectedDate;
};

export const dateOptions = [
  {
    id: 0,
    value: null,
    label: DATE_OPTIONS.ALL,
  },
  {
    id: 1,
    value: { startDate: today, endDate: today },
    label: DATE_OPTIONS.TODAY,
  },
  {
    id: 2,
    value: {
      startDate: currentMonth,
      endDate: getLastDayOfMonth(today),
    },
    label: DATE_OPTIONS.THIS_MONTH,
  },
  {
    id: 3,
    value: {
      startDate: lastMonth,
      endDate: getLastDayOfMonth(lastMonth),
    },
    label: DATE_OPTIONS.LAST_MONTH,
  },
  {
    id: 4,
    value: null,
    label: DATE_OPTIONS.CUSTOM_RANGE,
  },
];
