import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import store from 'js/react/store';
import partfinitiTheme from 'partfiniti/core/themes/partfiniti';

/**
 * Create React root element.
 *
 * @param  {function|Component} Component
 * @param  {string}             domId
 * @param  {object}             [props]
 * @param  {function}           [callback]
 * @return {function}
 */
const createElement = (Component, domId, props = {}, callback) => {
  const container = document.getElementById(domId);

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={partfinitiTheme}>
        <Component {...props} />
      </ThemeProvider>
    </Provider>,
    container,
    callback
  );

  return () => ReactDOM.unmountComponentAtNode(container);
};

export default createElement;
