import BaseLoginForm from 'js/auth/forms/base-login-form';
import UserValidator from 'js/auth/validators/user-validator';

/**
 * Login - Password Form.
 *
 * @class
 * @extends BaseLoginForm
 *
 * @param {object} [options]
 */
function LoginPasswordForm(options) {
  BaseLoginForm.call(this, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      tmplEl: '#auth-login-password-form',
      tmplParams: {
        submitBtnLabel: this.translator.getTitle('label_sign_in'),
      },
      serverParams: {
        signin_action: 'authenticate',
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      password: {
        required: {
          message: this.translator.get('error_empty_password'),
        },
      },
    };
  };

  /**
   * @inheritDoc
   */
  this.notifyError = function(message, errorType) {
    if (UserValidator.ERROR_TYPE_USER_EMAIL_NOT_CONFIRMED !== errorType) {
      return parent.notifyError.call(this, message);
    }

    const buttonHtml = `
      <a href="#" class="resendEmailVerificationLink blue_text">
        ${this.translator.get('label_button_here')}
      </a>
    `;

    const customMessage = this.translator.get(
      'message_resend_email_verification_link',
      { button: buttonHtml },
    );

    return parent.notifyError.call(this, `${message} ${customMessage}`);
  };

  // Initialize
  this.init();
}

export default LoginPasswordForm;
