import React, { useMemo } from 'react';

import { api } from 'partfiniti/core/services';

import AsyncSelect from './AsyncSelect';
import StaticSelect from './StaticSelect';

const Select = ({
  // Use this for static options
  options,

  // Use this props for async loaded options
  loadPath,
  loadParams = {},
  responseOptionsField = 'data',
  responseCountField = 'recordsFiltered',
  processResponse,
  pageSize = 25,

  value,
  onChange,
  getValue = option => option.id,
  getLabel = option => option.name,
  isMulti = false,
  isClearable,
  placeholder,
  size = 'big',
}) => {
  const selectProps = {
    options,
    value,
    onChange,
    getValue,
    getLabel,
    isMulti,
    isClearable,
    placeholder,
    size,
  };

  if (_.isEmpty(loadPath)) {
    const staticSelectOptions = useMemo(() => (
      options.map(option => ({
        value: getValue(option),
        label: getLabel(option),
      }))
    ), [options, getValue, getLabel]);

    return (
      <StaticSelect
        {...{
          ...selectProps,
          options: staticSelectOptions,
          onChange: option => onChange(option.value),
          value: staticSelectOptions
            .find(option => option.value === value),
        }}
      />
    );
  }

  const loadOptions = async (query, prevOptions, additional) => {
    let response = await api.get(
      loadPath,
      {
        ...loadParams,
        filters: {
          ...(loadParams.filters || {}),
          query,
        },
        page: additional.page,
        pageSize,
      },
    );

    if (_.isFunction(processResponse)) {
      response = processResponse(response, query);
    }

    const loadedOptionsCount = prevOptions.length +
            response[responseOptionsField].length;

    return {
      options: response[responseOptionsField],
      hasMore: loadedOptionsCount < response[responseCountField],
      additional: {
        page: additional.page + 1,
      },
    };
  };

  return (
    <AsyncSelect
      {...{
        ...selectProps,
        loadOptions,
      }}
    />
  );
}

export default Select;
