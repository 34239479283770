import BaseActionConfirmation from 'js/base_v2/action-confirmation';
/**
 * Quote Archive Confirmation.
 *
 * @class
 * @extends BaseActionConfirmation
 *
 * @param {object|object[]} records
 * @param {?object}         options
 */
function QuoteArchiveConfirmation(records, options) {
    BaseActionConfirmation.call(this, records, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entityLabel: 'quote',
            entitiesLabel: 'quotes',
            actionLabel: 'archive',
            actionBtnLabel: 'archive',
            actionFinishedLabel: 'archived',
            actionUrl: '/quote/customer/save-bulk'
        });
    };

    /**
     * @inheritDoc
     */
    this.prepareServerParams = function() {
        var quotes = [];

        _.each(this.records, function(quote) {
            quotes.push({
                id: quote.id,
                archived: 1
            });
        })

        this.options.serverParams = this.options.serverParams.extend({
            quotes: quotes
        });

        return this;
    };

    /**
     *  @inheritDoc
     */
    this.prepareTmplParams = function() {
        parent.prepareTmplParams.call(this);

        return this.extendOptions({
            tmplParams: {
                confirmBtnClassName: 'green_btn'
            }
        });
    };

    // Initialize
    this.init();
}

export default QuoteArchiveConfirmation;
