import React from 'react';

import renderer from 'js/components/renderer';

const RateView = ({ amount, interval = 'hr' }) => (
  <span>
    {renderer.Price(amount)}
    /
    {interval}
  </span>
);

export default RateView;
