import React from 'react';

import ModalHeader from './ModalHeader';

const ModalView = ({
  header,
  error,
  closeModal,
  children
}) => (
  <div className="modal-content">
    <ModalHeader {...{ ...header, error, closeModal }} />
    {children}
  </div>
);

export default ModalView;
