import styled, { css } from 'styled-components';

export const iconStyle = {
  marginRight: 5,
  marginLeft: 10,
};

export const DateIntervalWrapper = styled.div``;

export const Control = styled.div`
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: ${({ theme }) => theme.palette.neutral.light};
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  cursor: default;
  display: flex;
  justify-content: space-between;
  min-height: 45px;
  outline: 0;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border-color: hsl(0, 0%, 80%);
    box-shadow: none;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 5px;
`;

export const Placeholder = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.placeholder};
  display: flex;
  align-items: center;
  cursor: ponter;
`;

export const Text = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Menu = styled.div`
  overflow-y: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;
  box-sizing: border-box;
  width: fit-content;
  border-right: ${({ theme }) => `1px solid ${theme.palette.neutral.lightest}`};
`;

export const ListItem = styled.div`
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #DEEBFF;
  }

  ${({ selected }) => selected && css`
    background-color: #2684FF;

    &:hover {
      background-color: #2684FF;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.lightest}`};
  line-height: 12px;
  font-size: 12px;
  vertical-align: middle;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DateWrapper = styled.span`
  font-size: 12px;
  padding: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Box = styled.div``;

export const Dropdown = styled.div`
  background-color: hsl(0, 0%, 100%);
  position: absolute;
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.lightest}`};
  max-width: none;
  padding: 0;
  margin-top: 7px;
  z-index: 3000;
  font-size: 15px;
  line-height: 1em;
  display: block;
  right: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 564px) {
    width: auto;
  }

  &:after,
  :before {
    position: absolute;
    display: inline-block;
    content: '';
  }

  &:after {
    left: 10px;
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
  }

  &:before {
    left: 9px;
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 
      ${({ theme }) => `7px solid ${theme.palette.neutral.lightest}`};
  }

  ${({ isNotFittingToTheRight }) =>
    isNotFittingToTheRight &&
    css`
      right: 30px;

      &:after {
        left: 258px;
      }

      &:before {
        left: 257px;
      }
    `}
`;
