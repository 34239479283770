import React from 'react';

const Option = ({
  option,
  label,
  name,
  value,
  onClick,

  renderOption,
}) => {
  const renderedOptionLabel = typeof renderOption === 'function' ?
    renderOption(option) :
    label

  return (
    <div className="radio-option" onClick={() => onClick(option)}>
      <label className="custom-control custom-radio">
        <input
          name={name}
          value={option}
          checked={value === option ? 'checked' : ''}
          onChange={() => onClick(option)}
          type="radio"
          className="custom-control-input"
        />
        <span className="custom-control-indicator"></span>
      </label>

      <span className="radio-option-label">{renderedOptionLabel}</span>
    </div>
  );
}

export default Option;
