import { createSlice } from '@reduxjs/toolkit';

import { api } from 'js/react/core/services';
import { partCostEntity } from 'js/react/part/entities';

const vendorCostsSlice = createSlice({
  name: 'vendorCosts',
  initialState: {
    loading: false,
    list: [],
  },
  reducers: {
    addVendorCosts(state, action) {
      state.list = action.payload.vendorCosts;
      state.loading = false;

      return state;
    },
    setSelectedVendorCost(state, action) {
      state.list.map(vendorCost => {
        if (vendorCost.id === action.payload.id) {
          vendorCost.selected = true;
        } else {
          vendorCost.selected = false;
        }

        return vendorCost;
      });

      return state;
    },
    setLoading(state, action) {
      state.loading = action.payload.loading;

      return state;
    },
  },
});

export const fetchVendorCosts = (partConfiguration, part) => async dispatch => {
  if (_.isEmpty(part)) {
    return;
  }

  dispatch(vendorCostsSlice.actions.setLoading({ loading: true }));

  const response = await api.get(
    '/contacts/default-vendor-list/contacts',
    {
      filters: {
        part_number: part.number,
        manufacturer_id: part.manufacturer_id,
      }
    }
  );

  const defaultCost = partCostEntity.getDefaultCost();
  const vendorCosts = response.data;

  if ((!_.isEmpty(partConfiguration) && partConfiguration.standard_cost > 0) ||
        _.isEmpty(response.data)
  ) {
    defaultCost.selected = true;
  } else {
    vendorCosts[0].selected = true;
  }

  dispatch(vendorCostsSlice.actions.addVendorCosts({
    vendorCosts: [
      defaultCost,
      ...vendorCosts,
    ],
  }));
};

export const { setSelectedVendorCost } = vendorCostsSlice.actions;

export default vendorCostsSlice.reducer;
