import BaseEntity from 'js/base_v3/models/entity';
import CorporatePart from 'js/corporate_v2/models/part';
import PriceHelper from 'js/helpers/price-helper';
import Part from 'js/part/models/part';
import PricingSource from 'js/pricing/models/source';

const staticSelf = BaseEntityPart;

/**
 * @const
 */
staticSelf.TYPE_ELEMENT = '__element__';

/**
 * @const
 */
staticSelf.TYPE_COMPONENT = '__component__';

/**
 * @const
 */
staticSelf.TYPE_PART = '__part__';

/**
 * @const
 */
staticSelf.TYPE_DISCOUNT = '__discount__';

/**
 * @const
 */
staticSelf.RECORD_TYPE_PART = 'part';

/**
 * @const
 */
staticSelf.RECORD_TYPE_PRICING_GROUP_DISCOUNT = 'pricing_group_discount';

/**
 * @const
 */
staticSelf.RECORD_TYPE_PART_DISCOUNT = 'part_discount';

/**
 * Base Entity Part Entity.
 *
 * @class
 * @abstract
 * @extends BaseEntity
 *
 * @param {object} [data]
 */
function BaseEntityPart(data) {
  BaseEntity.call(this, data);

  /**
   * Get part.
   *
   * @return {?Part}
   */
  this.getPart = function() {
    if (_.isObject(this.part) && !_.isEmpty(this.part)) {
      return new Part(this.part);
    }

    if (!_.isString(this.part) || _.isEmpty(this.part)) {
      return null;
    }

    return new Part({
      id: this.part_id,
      number: this.part,
      manufacturer_id: this.manufacturer_id,
      manufacturer_name: this.manufacturer_name,
      manufacturer_url_name: this.manufacturer_url_name,
      company_manufacturer_name: this.company_manufacturer_name || null,
      family_id: this.part_family_id,
      description: this.part_description,
      description_long: this.part_description_long,
      ordering_number: this.part_ordering_number,
      engineering_number: this.part_engineering_number,
      verified: !_.isUndefined(this.part_verified) ?
        this.part_verified :
        this.verified,
      generic: this.part_generic,
      wildcard: this.part_wildcard,
      is_base_code_part: 1 === +this.is_base_code_part,
    });
  };

  /**
   * Get corporate part.
   *
   * @return {?CorporatePart}
   */
  this.getCorporatePart = function() {
    if (_.isObject(this.corporate_part) &&
            !_.isEmpty(this.corporate_part)
    ) {
      return new CorporatePart(this.corporate_part);
    }

    if (!(this.corporate_part_id > 0)) {
      return null;
    }

    return new CorporatePart({
      id: this.corporate_part_id,
      part: this.part,
      manufacturer_id: this.manufacturer_id,
      manufacturer_name: this.manufacturer_name,
      manufacturer_url_name: this.manufacturer_url_name,
    });
  };

  /**
   * Get name.
   *
   * @return {?string}
   */
  this.getName = function() {
    switch (this.type) {
      case staticSelf.TYPE_COMPONENT:
        return this.component_name;
      case staticSelf.TYPE_PART:
        return this.part;
      default:
        return null;
    }
  };

  /**
   * Set quantity.
   *
   * @param  {number}         quantity
   * @return {BaseEntityPart}
   */
  this.setQuantity = function(quantity) {
    return this.setData({
      quantity,
      extended_price: this.price * quantity,
    });
  };

  /**
   * Get package units.
   *
   * @return {?number}
   */
  this.getPackageUnits = function() {
    return this.package_units;
  };

  /**
   * Get price.
   *
   * @param  {string}  type
   * @return {?number}
   */
  this.getPrice = function(type) {
    let field = 'price';

    if (PriceHelper.PRICING_TYPE_TOTAL !== type) {
      field += `_${type}`;
    }

    return PriceHelper.get(this[field]);
  };

  /**
   * Get price base.
   *
   * @return {?number}
   */
  this.getPriceBase = function() {
    return this.getPrice(PriceHelper.PRICING_TYPE_BASE);
  };

  /**
   * Get price adder.
   *
   * @return {?number}
   */
  this.getPriceAdder = function() {
    return this.getPrice(PriceHelper.PRICING_TYPE_ADDER);
  };

  /**
   * Get purchase cost.
   *
   * @param  {string}  type
   * @return {?number}
   */
  this.getPurchaseCost = function(type) {
    return this._getCostingFieldValue('purchase_cost', type);
  };

  /**
   * Get purchase cost base.
   *
   * @return {?number}
   */
  this.getPurchaseCostBase = function() {
    return this.getPurchaseCost(PriceHelper.PRICING_TYPE_BASE);
  };

  /**
   * Get purchase cost adder.
   *
   * @return {?number}
   */
  this.getPurchaseCostAdder = function() {
    return this.getPurchaseCost(PriceHelper.PRICING_TYPE_ADDER);
  };

  /**
   * Get cost.
   *
   * @param  {string}  type
   * @return {?number}
   */
  this.getCost = function(type) {
    return this._getCostingFieldValue('cost', type);
  };

  /**
   * Get cost base.
   *
   * @return {?number}
   */
  this.getCostBase = function() {
    return this.getCost(PriceHelper.PRICING_TYPE_BASE);
  };

  /**
   * Get cost adder.
   *
   * @return {?number}
   */
  this.getCostAdder = function() {
    return this.getCost(PriceHelper.PRICING_TYPE_ADDER);
  };

  /**
   * Get base GPM.
   *
   * @return {?number}
   */
  this.getBaseGpm = function() {
    return PriceHelper.computeGpm(
      this.getCostBase(),
      this.getPriceBase(),
    );
  };

  /**
   * Get adder GPM.
   *
   * @return {?number}
   */
  this.getAdderGpm = function() {
    return PriceHelper.computeGpm(
      this.getCostAdder(),
      this.getPriceAdder(),
    );
  };

  /**
   * Get price decimals.
   *
   * @return {?number|undefined}
   */
  this.getPriceDecimals = function() {
    return undefined;
  };

  /**
   * Get cost source.
   *
   * @return {?PricingSource}
   */
  this.getCostSource = function() {
    return null;
  };

  /**
   * Get price source.
   *
   * @return {?PricingSource}
   */
  this.getPriceSource = function() {
    return null;
  };

  /**
   * Determine whether PNB pricing is enabled.
   *
   * @return {boolean}
   */
  this.isPnbPricingEnabled = function() {
    return 1 === +this.pnb_pricing_enabled ||
      PriceHelper.isNonEmpty(this.getPurchaseCostBase()) ||
      PriceHelper.isNonEmpty(this.getPurchaseCostAdder()) ||
      PriceHelper.isNonEmpty(this.getCostBase()) ||
      PriceHelper.isNonEmpty(this.getCostAdder()) ||
      PriceHelper.isNonEmpty(this.getPriceBase()) ||
      PriceHelper.isNonEmpty(this.getPriceAdder());
  };

  /**
   * Determine whether base-adder PNB pricing is enabled.
   *
   * @return {boolean}
   */
  this.isBaseAdderPnbPricingEnabled = function() {
    return !_.isNull(this.base_identifier) && this.isPnbPricingEnabled();
  };

  /**
   * Determine whether cost is a PNB fixed one.
   *
   * @return boolean
   */
  this.isCostPnbFixed = function() {
    return PricingSource.TYPE_FAMILY === this.computed_cost_source_type &&
      PriceHelper.isEmpty(this.getPurchaseCostBase()) &&
      PriceHelper.isEmpty(this.getPurchaseCostAdder()) &&
      PriceHelper.isEmpty(this.getCostBase()) &&
      PriceHelper.isEmpty(this.getCostAdder());
  };

  /**
   * Get minimum order quantity.
   *
   * @abstract
   *
   * @return {string}
   */
  this.getMinimumOrderQuantity = null;

  /**
   * Get order increment.
   *
   * @abstract
   *
   * @return {string}
   */
  this.getOrderIncrement = null;

  /**
   * Get costing field value.
   *
   * @protected
   *
   * @param  {string}  fieldType   E.g "cost", "purchase_cost".
   * @param  {string}  costingType E.g. "total", "base", "adder".
   * @return {?number}             Costing field value as float, or null if
   *                               empty.
   */
  this._getCostingFieldValue = function(fieldType, costingType) {
    return PriceHelper.get(
      this[this._getCostingFieldName(fieldType, costingType)],
    );
  };

  /**
   * Get costing field name.
   *
   * @protected
   *
   * @throws {Exception}
   *
   * @param  {string} fieldType   E.g "cost", "purchase_cost".
   * @param  {string} costingType E.g. "total", "base", "adder".
   * @return {string}
   */
  this._getCostingFieldName = function(fieldType, costingType) {
    let fieldName = 'computed_';

    switch (costingType) {
      case PriceHelper.PRICING_TYPE_TOTAL:
        fieldName += fieldType;
        break;
      case PriceHelper.PRICING_TYPE_BASE:
      case PriceHelper.PRICING_TYPE_ADDER:
        fieldName += `${fieldType}_${costingType}`;
        break;
      default:
        throw new Error(`Invalid costingType: "${costingType}".`);
    }

    return fieldName;
  };
}

export default BaseEntityPart;
