import { Event } from 'partfiniti/core/events';

export default class MessageCcChanged extends Event {
    static NAME = 'message-cc-changed';

    /**
     * Constructor.
     *
     * @param {object[]} ccRecipients
     */
    constructor(ccRecipients) {
      super();

      this._ccRecipients = ccRecipients;
    }

    /**
     * Get CC recipients.
     *
     * @return {object[]}
     */
    get ccRecipients() {
      return this._ccRecipients;
    }
}
