import { createSelector } from '@reduxjs/toolkit';

export const selectVendorCosts = createSelector(
  state => state.vendorCosts,
  vendorCosts => vendorCosts.list,
);

export const selectSelectedVendorCost = createSelector(
  selectVendorCosts,
  vendorCosts => vendorCosts.find(vendorCost => vendorCost.selected),
);
