import React from 'react';

const FieldLabelView = ({
  label,
  required = false,
}) => (
  <label className="control_label">
    {label}
    {required ? ' *' : ''}
  </label>
);

export default FieldLabelView;
