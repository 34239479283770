import React from 'react';
import ReactDOM from 'react-dom';

import { notifier } from 'js/components_v2/default-notifier';
import { translator } from 'js/translator';

import Table from 'partfiniti/core/components/Table';
import { api } from 'partfiniti/core/services';
import { objectKeysCamelToSnakeCase } from 'partfiniti/utils';

const fetchRecords = async (loadPath, loadParams, filters, page) => {
  try {
    return await api.get(
      loadPath,
      objectKeysCamelToSnakeCase(
        loadParams.extend({
          page,
          pageSize: 15,
        }).extend({ filters })
      )
    );
  } catch (error) {
    notifier.notifyError(
      `${translator.get('error_occurred')}: ${error.message || ''}`
    );

    return {};
  }
};

const fetchTotals = async (totalsPath, loadParams, filters) => {
  try {
    return await api.get(
      totalsPath,
      objectKeysCamelToSnakeCase(loadParams.extend({ filters }))
    );
  } catch (error) {
    notifier.notifyError(
      `${translator.get('error_occurred')}: ${error.message || ''}`
    );

    return {};
  }
};

const List = ({
  name,
  getColumns,
  loadPath,
  loadParams = {},
  totalsPath,
  recordsField = 'records',
  recordsCountField = 'recordsCount',
  totalsField = 'totals',
  headerLines = 1,
  footerLines = 0,
  alternating = false,
  borders = true,
  filters,
  hasTotals,
  emptyMessage,
  onRowClick,
  listReloadRequested,
}) => {
  const [page, setPage] = React.useState(1);
  const [records, setRecords] = React.useState([]);
  const [recordsCount, setRecordsCount] = React.useState(0);
  const [totals, setTotals] = React.useState({});
  const tableScrollableRef = React.useRef();

  const loadRecords = async (newPage, concat = false) => {
    const promisses = [fetchRecords(loadPath, loadParams, filters, newPage)];

    if (!concat && hasTotals) {
      promisses.push(fetchTotals(totalsPath, loadParams, filters));
    }

    const [data, totalsResponse] = await Promise.all(promisses);

    ReactDOM.unstable_batchedUpdates(() => {
      setRecords(
        concat ?
          records.concat(data?.[recordsField] || []) :
          data?.[recordsField] || []
      );
      setRecordsCount(data?.[recordsCountField]);
      setPage(newPage);

      if (hasTotals && totalsResponse) {
        setTotals(totalsResponse?.[totalsField] || {});
      }
    });
  };
  const loadRecordsDebounced = _.debounce(loadRecords, 200);

  React.useEffect(() => {
    loadRecordsDebounced(1, false);
    tableScrollableRef.current.scrollTo(0, 0);
  }, [filters, listReloadRequested]);

  const columns = React.useMemo(() => getColumns(totals), [totals]);

  return (
    <Table
      {...{
        name,
        columns,
        data: records,
        loadMore: () => loadRecords(page + 1, true),
        onRowClick,
        totalItems: recordsCount,
        alternating,
        borders,
        headerLines,
        footerLines,
        emptyMessage,
        scrollableRef: tableScrollableRef,
      }}
    />
  );
};

export default List;
