import BaseRenderer from 'js/base_v3/helpers/renderer';
import Renderer from 'js/components/renderer';
import { quoteRenderHelper } from 'js/quote/helpers/render-helper';
import Quote from 'js/quote/models/quote';

const staticSelf = QuoteRenderer;

/**
 * @const
 */
staticSelf.MANUFACTURERS_DISPLAY_LIMIT = 5;

/**
 * Quote Renderer.
 *
 * @class
 * @extends BaseRenderer
 *
 * @param {object} [options]
 */
function QuoteRenderer(options) {
  BaseRenderer.call(this, options);

  /**
   * Render contact company.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderContactCompany = function(quote) {
    return this.wrapText(quoteRenderHelper.getContactCompanyName(quote)) +
      this.wrapText(
        quoteRenderHelper.getContactUserName(quote),
        { wrapperClassName: 'name_user help_text' },
      );
  };

  /**
   * Render job.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderJob = function(quote) {
    return this.wrapText(quote.job_name) +
      this.wrapText(
        quoteRenderHelper.getProjectName(quote),
        { wrapperClassName: 'help_text' },
      );
  };

  /**
   * Render custom ID.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderCustomId = function(quote) {
    return this.wrapText(quote.custom_id);
  };

  /**
   * Render quote values.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderQuoteValues = function(quote) {
    return Renderer.Price(quote.release_price > 0 ? quote.release_price : null);
  };

  /**
   * Render status.
   *
   * @param  {object} record
   * @return {string}
   */
  this.renderStatus = function(record) {
    const statusHtml = this.wrapText(quoteRenderHelper.getStatus(record));

    let noteHtml = '';
    let archivedLabelHtml = '';

    if (Quote.STATUS_LOST === record.status &&
      _.isString(record.notes_map?.status_lost?.content)
    ) {
      const noteContent = this.wrapText(
        record.notes_map.status_lost.content,
        { wrapperClassName: 'help_text' },
      );

      noteHtml = `<div>${noteContent}</div>`;
    }

    const archivedLabel = this._translator.getUppercased('archived');

    if (1 === +record.archived) {
      archivedLabelHtml = `
        <div>
          ${this.wrapText(archivedLabel, { wrapperClassName: 'help_text' })}
        </div>
      `;
    }

    return statusHtml + noteHtml + archivedLabelHtml;
  };

  /**
   * Render contact.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderContact = function(quote) {
    let contactName = '';

    switch (quote.type) {
      case Quote.TYPE_CUSTOMER:
        contactName = quote.vendor_corporate_user_name;
        break;
      case Quote.TYPE_VENDOR:
        contactName = quote.customer_corporate_user_name;
        break;
      default:
        // Do nothing
    }

    const locationName = this.wrapText(
      quote.location_name || '-', // Value
      { wrapperClassName: 'help_text' }, // Options
    );

    return `
      <div>
        ${this.wrapText(contactName || '-')}
        ${locationName}
      </div>
    `;
  };

  /**
   * Render contact grouping.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderContactGrouping = function(quote) {
    switch (quote.type) {
      case Quote.TYPE_CUSTOMER:
        return `
          ${this.wrapText(quote.customer_contact_grouping || '-')}
          <div class="help_text">
            ${this.wrapText(quote.vertical_market_name || '-')}
          </div>
        `;
      case Quote.TYPE_VENDOR:
        return this.wrapText(quote.vendor_contact_grouping || '-');
      default:
        return '-';
    }
  };

  /**
   * Render clone button.
   *
   * @param  {Quote}  quote
   * @return {string}
   */
  this.renderCloneButton = function(quote) {
    if (Quote.TYPE_CUSTOMER === quote.type) {
      return `
        <div
          data-identifier="${quote.id}"
          class="quoteCloneButton stopPropagation clone-button"
        >
          <svg><use xlink:href="#icon-clone"></use></svg>
        </div>
      `;
    }

    return '';
  };

  /**
   * Render vendor quote data.
   *
   * @param  {Quote}  quote
   * @return {string}
   */
  this.renderVendorQuoteData = function(quote) {
    return `
      <span>${quote.vendor_quote_number || '-'}</span>
      <span class="help_text">${quote.vendor_project_name || ''}</span>
    `;
  };

  /**
   * Render valid until.
   *
   * @param  {Quote}  quote
   * @return {string}
   */
  this.renderValidUntil = function(quote) {
    return _.isNull(quote.valid_until) ?
      '-' :
      moment(quote.valid_until).format('MM/DD/YYYY');
  };

  /**
   * Render close date.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderCloseDate = function(quote) {
    let closeDateStr = _.isEmpty(quote.estimated_close_date) ?
      '-' :
      moment(quote.estimated_close_date).format('MM/YYYY');

    if (Quote.TYPE_VENDOR === quote.type) {
      closeDateStr = '&nbsp;';
    }

    const validUntilStr = _.isNull(quote.valid_until) ?
      '-' :
      moment(quote.valid_until).format('MM/DD/YYYY');

    return `
      ${closeDateStr}
      <div class="help_text">${validUntilStr}</div>
    `;
  };

  /**
   * Render close confidence.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderCloseConfidence = function(quote) {
    if (Quote.TYPE_VENDOR === quote.type) {
      return '';
    }

    return Renderer.Percentage(
      quote.close_confidence > 0 ? quote.close_confidence / 100 : null,
      '0%',
    );
  };

  /**
   * Render manufacturers.
   *
   * @param  {Quote} quote
   * @return {string}
   */
  this.renderManufacturers = function(quote) {
    if (_.isEmpty(quote.manufacturers)) {
      return '-';
    }

    const manufacturersHtmlPieces = quote.manufacturers.map(
      (manufacturer) => `<span class="nowrap">${manufacturer.name}</span>`,
    );

    // eslint-disable-next-line max-len
    if (manufacturersHtmlPieces.length <= staticSelf.MANUFACTURERS_DISPLAY_LIMIT) {
      return `<div>${manufacturersHtmlPieces.join('<br>')}</div>`;
    }

    /* eslint-disable max-len */
    return `
      <div>
        <div>${manufacturersHtmlPieces.slice(0, staticSelf.MANUFACTURERS_DISPLAY_LIMIT).join('<br>')}</div>
        <div class="wrap_tooltip">
          <div class="displayed-manufacturers-ellipsis">...</div>
          <span class="desc_tooltip">
            ${manufacturersHtmlPieces.join('<br>')}
          </span>
        </div>
      </div>
    `;
    /* eslint-enable max-len */
  };

  // Initialize
  this._init();
}

export const quoteRenderer = new QuoteRenderer();

export default QuoteRenderer;
