import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`;

// TODO use this instead of inline styles
const Label = styled.div`
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
`;

const Info = styled.div`
    color: #2e3037;
    width: fit-content;
`;

const Primary = styled.div`
    font-weight: 600;
    font-size: 1rem;
    text-align: right;
`;

const Secondary = styled.div`
    font-size: 11px;
    font-weight: 400;
    text-align: right;
`;

const InfoLineView = ({ label, primary, secondary }) => (
  <Container>
    <div className="row" style={{
      padding: '15px 0',
      borderBottom: '1px solid #E4E4E4',
      fontFamily: 'sans-serif',
      color: '#2e3037',
    }}>
      <div className="col-sm-6">
        <Label>{label}</Label>
      </div>
      <div className="col-sm-6">
        <Info>
          <Primary>{primary}</Primary>
          <Secondary>{secondary}</Secondary>
        </Info>
      </div>
    </div>
  </Container>
);

export default InfoLineView;
