import BaseObject from 'js/base_v2/object';
var staticSelf = ImpersonatedUserRepository;

/**
 * @const
 */
staticSelf.LOCAL_STORAGE_KEY = 'impersonation';

/**
 * @const
 */
staticSelf.LIMIT = 5;

/**
 * Impersonated user repository.
 *
 * @class
 * @extends BaseObject
 *
 * @param {object} options
 */
function ImpersonatedUserRepository(options) {
    BaseObject.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {object}
     */
    this.options = options || {};

    /**
     * Find all users.
     *
     * @return {object[]}
     */
    this.findAll = function() {
        return this.getFromLocalStorage();
    };

    /**
     * Save user.
     *
     * @return {ImpersonatedUserRepository}
     */
    this.save = function(user) {
        var users = this.findAll();
        users = this.filterOutIntruderUser(users, user);
        users.unshift(user);
        users.splice(staticSelf.LIMIT);

        return this.saveToLocalStorage(users);
    };

    /**
     * Save users to local storage.
     *
     * @param  {object[]}                   users
     * @return {ImpersonatedUserRepository}
     */
    this.saveToLocalStorage = function(users) {
        try {
            var impersonation = { mostRecentUsed: users };
            window.localStorage
                .setItem('impersonation', JSON.stringify(impersonation));
        } catch (e) {}

        return this;
    };

    /**
     * Get users from local storage.
     *
     * @return {object[]}
     */
    this.getFromLocalStorage = function() {
        try {
            var impersonation = window.localStorage
                .getItem(staticSelf.LOCAL_STORAGE_KEY);
            impersonation = impersonation ? JSON.parse(impersonation) : null;

            if (_.isObject(impersonation) &&
                _.isArray(impersonation.mostRecentUsed)
            ) {
                return impersonation.mostRecentUsed;
            }
        } catch (e) {}

        return [];
    };

    /**
     * Filter out intruder user from users.
     *
     * @param  {object[]} users
     * @param  {object}   intruderUser
     * @return {object[]}
     */
    this.filterOutIntruderUser = function(users, intruderUser) {
        return users.filter(function(u) {
            return intruderUser.id !== u.id;
        });
    };

    // Initialize
    this.init();
}

export default ImpersonatedUserRepository;
