import BaseBulkSaveForm   from 'js/base_v2/bulk-save-form';
import CorporateUserField from 'js/corporate_v2/fields/corporate-user-field';

/**
 * Corporate User Select Form
 *
 * @class
 * @abstract
 * @extends BaseBulkSaveForm
 *
 * @param {array}  records
 * @param {object} [options]
 */
function CorporateUserSelectForm(records, options) {
    BaseBulkSaveForm.call(this, records, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {CorporateUserField}
     */
    this.corporateUserField = null;

    /**
     * @prop {string[]}
     */
    this.fieldNames = [].concat(this.addressFieldNames, [
        'corporateUserField'
    ]);

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.defaultOptions = $.extend(true, {}, this.defaultOptions, {
            tmplEl: '#corporate-user-select-form',
            corporateUserField: {
                autocompleteParams: {
                    filters: {
                        status: ['active']
                    }
                }
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.initFields = function() {
        this.corporateUserField = new CorporateUserField(
            $('.corporateUserFieldCt', this.formEl),
            $('.corporateUserSelect', this.formEl),
            this.options.corporateUserField
        ).create();

        return this;
    };

    /**
     * @inheritDoc
     */
    this.getConstraints = function() {
        var requiredFields = {
            'corporate_user_id': {
                'required': {
                    message: 'The user cannot be empty.'
                }
            }
        };

        return requiredFields;
    };
};

export default CorporateUserSelectForm;
