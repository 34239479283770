import React from 'react';

import './fieldWrapper.scss';

const FieldWrapperView = ({
  hasPaddingTop = false,
  children,
}) => {
  const classes = ['field_wrapper_block'];

  if (hasPaddingTop) {
    classes.push('has_padding_top');
  }

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  );
};

export default FieldWrapperView;
