import BaseView from 'js/base_v3/views/view';
var staticSelf = CollapsableTopBlock;

/**
 * @const
 */
staticSelf.STATE_EXPANDED = 'expanded';

/**
 * @const
 */
staticSelf.STATE_COLLAPSED = 'collapsed';

/**
 * Collapsable Top Block.
 *
 * @class
 * @extends BaseView
 *
 * @param {DOMElement} container
 * @param {object}     options
 */
function CollapsableTopBlock(container, options) {
    BaseView.call(this, container, options);
    var parent = this.clone();
    var self = this;

    /**
     * Collapse top block.
     *
     * @return {CollapsableTopBlock}
     */
    this.collapseTopBlock = function() {
        if (this._checkTopBlockState(staticSelf.STATE_COLLAPSED)) {
            return this;
        }

        $('html').addClass('sticky_top_part');

        this._toggleHeaderChevron();

        this._onCollapseTopBlockStart();

        setTimeout(function() {
            self._onCollapseTopBlockEnd();
        }, this._options.topBlockEndEventDelay);

        return this;
    };

    /**
     * Expand top block.
     *
     * @return {CollapsableTopBlock}
     */
    this.expandTopBlock = function() {
        if (this._checkTopBlockState(staticSelf.STATE_EXPANDED)) {
            return this;
        }

        $('html').removeClass('sticky_top_part');

        this._toggleHeaderChevron();

        this._onExpandTopBlockStart();

        setTimeout(function() {
            self._onExpandTopBlockEnd();
        }, this._options.topBlockEndEventDelay);

        return this;
    };

    /**
     * @inheritDoc
     */
    this._initDefaults = function() {
        parent._initDefaults.call(this);

        return this._extendDefaultOptions({
            topBlockEndEventDelay: 350,
            onCollapseTopBlockStart: undefined,
            onCollapseTopBlockEnd: undefined,
            onExpandTopBlockStart: undefined,
            onExpandTopBlockEnd: undefined
        });
    };

    /**
     * @inheritDoc
     */
    this._registerEventListeners = function() {
        parent._registerEventListeners.call(this);

        this._container.on('click', '.collapseTopBlockBtn', function(event) {
            self._onCollapseTopBlockBtnClick(event);
        });

        this._container.on('click', '.expandTopBlockBtn', function(event) {
            self._onExpandTopBlockBtnClick(event);
        })

        return this;
    };

    /**
     * @inheritDoc
     */
    this._create = function() {
        parent._create.call(this);

        this._container.find('.collapseTopBlockBtn').removeClass('hidden');

        return this;
    };

    /**
     * Collapse top block button click event handler.
     *
     * @protected
     *
     * @param {Event} event
     */
    this._onCollapseTopBlockBtnClick = function(event) {
        event.preventDefault();
        this.collapseTopBlock();
    };

    /**
     * Expand top block button click event handler.
     *
     * @protected
     *
     * @param {Event} event
     */
    this._onExpandTopBlockBtnClick = function(event) {
        event.preventDefault();
        this.expandTopBlock();
    };

    /**
     * Toggle header chevron icon.
     *
     * @protected
     *
     * @return {CollapsableTopBlock}
     */
    this._toggleHeaderChevron = function() {
        if (this._checkTopBlockState(staticSelf.STATE_COLLAPSED)) {
            $('.expandTopBlockBtn').show();
            $('.collapseTopBlockBtn').hide();
        } else {
            $('.expandTopBlockBtn').hide();
            $('.collapseTopBlockBtn').show();
        }

        return this;
    };

    /**
     * Check top block state.
     *
     * @protected
     *
     * @param  {string}  state
     * @return {boolean}
     */
    this._checkTopBlockState = function(state) {
        var isStickyTop = $('html').hasClass('sticky_top_part');

        return state === staticSelf.STATE_COLLAPSED ?
            isStickyTop :
            !isStickyTop;
    };

    /**
     * On collapse top block start.
     *
     * @protected
     *
     * @return {boolean|undefined}
     */
    this._onCollapseTopBlockStart = function() {
        if (_.isFunction(this._options.onCollapseTopBlockStart) &&
            (false === this._options.onCollapseTopBlockStart())
        ) {
            return false;
        }

        return undefined;
    };

    /**
     * On collapse top block end.
     *
     * @protected
     *
     * @return {boolean|undefined}
     */
    this._onCollapseTopBlockEnd = function() {
        if (_.isFunction(this._options.onCollapseTopBlockEnd) &&
            (false === this._options.onCollapseTopBlockEnd())
        ) {
            return false;
        }

        return undefined;
    };

    /**
     * On expand top block start.
     *
     * @protected
     *
     * @return {boolean|undefined}
     */
    this._onExpandTopBlockStart = function() {
        if (_.isFunction(this._options.onExpandTopBlockStart) &&
            (false === this._options.onExpandTopBlockStart())
        ) {
            return false;
        }

        return undefined;
    };

    /**
     * On expand top block end.
     *
     * @protected
     *
     * @return {boolean|undefined}
     */
    this._onExpandTopBlockEnd = function() {
        if (_.isFunction(this._options.onExpandTopBlockEnd) &&
            (false === this._options.onExpandTopBlockEnd())
        ) {
            return false;
        }

        return undefined;
    };

    // Initialize
    this._init();
}

export default CollapsableTopBlock;
