import styled from 'styled-components';

const NoRecordsMessage = styled.div`
  padding: 30px;
  margin: auto;
  text-align: center;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
`;

export default NoRecordsMessage;
