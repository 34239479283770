import Ajax from 'js/components/ajax';

class Api {
  /**
     * Constructor.
     *
     * @param {Ajax} ajax
     */
  constructor(ajax) {
    this.ajax = ajax;
  }

  /**
     * GET request.
     *
     * @param  {string}                  url
     * @param  {object}                  data
     * @param  {object}                  options
     * @return {Promise<XMLHttpRequest>}
     */
  async get(url, data, options) {
    return this.request('get', url, data, options);
  }

  /**
     * POST request.
     *
     * @param  {string}                  url
     * @param  {object}                  data
     * @param  {object}                  options
     * @return {Promise<XMLHttpRequest>}
     */
  async post(url, data, options) {
    return this.request('post', url, data, options);
  }

  /**
     * Request.
     *
     * @param  {string}                  method
     * @param  {string}                  url
     * @param  {object}                  data
     * @param  {object}                  options
     * @return {Promise<XMLHttpRequest>}
     */
  request(method, url, data, options) {
    return new Promise((resolve, reject) => {
      const onSuccess = (response) => resolve(response);
      const onError = (response, error) => reject({ response, error });

      this.ajax[method](
        url,
        data,
        onSuccess,
        onError,
        options
      );
    });
  }
}

export default new Api(Ajax);
