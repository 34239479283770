import React from 'react';

import SvgIcon from './SvgIcon';

const IconView = ({ name, classNames, style }) => {
  switch (name) {
    case 'loader':
    case 'search':
    case 'arrow_left':
    case 'calendar':
      return <SvgIcon {...{ name, classNames, style }} />;
    default:
      throw new Error(`Unknwon icon "${name}"`);
  }
};

export default IconView;
