/* eslint-disable max-len */
import BaseSelect2AutocompleteField from 'js/base_v2/select2-autocomplete-field';
import Ajax from 'js/components/ajax';
import { customerNumberValidator } from 'js/order/validators/customer-number-validator';
/* eslint-enable max-len */

const staticSelf = OrderCustomerNumberSelectField;

/**
 * @const
 */
staticSelf.SOURCE_BOM = 'bom';

/**
 * @const
 */
staticSelf.SOURCE_LOCATION = 'location';

/**
 * @const
 */
staticSelf.SOURCE_CUSTOMER = 'customer';

/**
 * Order Customer Number Select Field.
 *
 * @class
 * @extends BaseSelect2AutocompleteField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function OrderCustomerNumberSelectField(fieldCt, fieldEl, options) {
  BaseSelect2AutocompleteField.call(this, fieldCt, fieldEl, options);
  const parent = this.clone();

  /**
   * @inheritdoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      select2: {
        placeholder: '',
        ajax: {
          url: '/customer/external-customer-number/list',
        },
      },
      entitiesName: 'external_customer_numbers',
      entityIdentifierField: 'external_customer_number',
      createNew: true,
      createNewOptions: {
        triggerClick: false,
      },
      onChange: () => this.onExternalCustomerNumberChange(),
    });
  };

  /**
   * @inheritDoc
   */
  this.createNewTag = function(term) {
    const newTag = Object.extend(parent.createNewTag.call(this, term), {
      external_customer_number: term,
    });

    return Object.extend(newTag, {
      original_record: {
        id: term,
        external_customer_number: term,
        isNew: true,
      },
    });
  };

  /**
   * @inheritdoc
   */
  this.getRecordLabel = function(customerNumber) {
    if (!customerNumber.external_customer_number) {
      return parent.getRecordLabel.call(this, customerNumber);
    }

    const label = this.getExternalCustomerNumberSourceLabel(customerNumber);

    return (
      `${customerNumber.external_customer_number}${label ? ' - ' : ''}${label}`
    );
  };

  /**
   * @inheritDoc
   */
  this.getNewTagValue = function(term) {
    return term;
  };

  /**
   * External customer number change event handler.
   */
  this.onExternalCustomerNumberChange = async function() {
    const customerNumber = this.getRecord();

    if (!_.isObject(customerNumber)) {
      this.toggleRadioGroupOptionVisibility(true);

      return;
    }

    const existingNumbers = await this.loadExistingCustomerNumbers();

    const numberExists = existingNumbers
      .find(({ external_customer_number: externalCustomerNumber } = {}) => (
        externalCustomerNumber &&
          externalCustomerNumber === customerNumber.external_customer_number
      ));

    if (!customerNumber.isNew || numberExists) {
      this.toggleRadioGroupOptionVisibility(true);

      return;
    }

    this.toggleRadioGroupOptionVisibility(false);
    this.toggleRadioGroupOptionVisibility(false, '> div');

    let hasBomNumber = false;
    let hasCustomerNumber = false;
    let hasLocationNumber = false;

    existingNumbers.forEach((number) => {
      if (number.source === staticSelf.SOURCE_BOM) {
        hasBomNumber = true;
        this.toggleRadioGroupOptionVisibility(true, '.bomGroup');
      }

      if (number.source === staticSelf.SOURCE_CUSTOMER) {
        hasCustomerNumber = true;
        this.toggleRadioGroupOptionVisibility(true, '.customerGroup');
      }

      if (number.source === staticSelf.SOURCE_LOCATION) {
        hasLocationNumber = true;
        this.toggleRadioGroupOptionVisibility(true, '.locationGroup');
      }
    });

    if (hasBomNumber && hasCustomerNumber && hasLocationNumber) {
      this.toggleRadioGroupOptionVisibility(true);
    }
  };

  /**
   * Toggle radio group option visibility.
   *
   * @param {boolean} isHidden
   * @param {string}  selector
   */
  this.toggleRadioGroupOptionVisibility = function(isHidden, selector = '') {
    $(`.externalCustomerNumberRadioGroup ${selector}`, this.fieldCt)
      .toggleClass('hidden', isHidden);
  };

  /**
   * Get external customer number source label.
   *
   * @param  {object} customerNumber
   * @return {string}
   */
  this.getExternalCustomerNumberSourceLabel = function(customerNumber) {
    const customerNumberLabel = this.translator
      .get('label_external_customer_number');

    if (!customerNumber.source) {
      return '';
    }

    switch (customerNumber.source) {
      case staticSelf.SOURCE_BOM:
        return `${this.translator.getTitle('bom')} ${customerNumberLabel}`;
      case staticSelf.SOURCE_CUSTOMER:
        return `${
          this.translator.getTitle('label_company')
        } ${customerNumberLabel}`;
      case staticSelf.SOURCE_LOCATION:
        return `${customerNumber.label} ${customerNumberLabel}`;
      default:
        throw new Error(`Unknown source ${customerNumber.source}`);
    }
  };

  /**
   * Set project id.
   *
   * @param {int} projectId
   */
  this.setProjectId = function(projectId) {
    this.options.autocompleteParams = this.options.autocompleteParams.extend({
      project_id: projectId,
    });
  };

  /**
   * Set job name.
   *
   * @param {string} jobName
   */
  this.setJobName = function(jobName) {
    this.options.autocompleteParams = this.options.autocompleteParams.extend({
      job_name: jobName,
    });
  };

  /**
   * Set customer user id.
   *
   * @param {int} customerUserId
   */
  this.setCustomerUserId = function(customerUserId) {
    this.options.autocompleteParams = this.options.autocompleteParams.extend({
      customer_user_id: customerUserId,
    });
  };

  /**
   * Load existing customer numbers.
   *
   * @return {object[]}
   */
  this.loadExistingCustomerNumbers = async function() {
    if (!this.existingCustomerNumbers) {
      this.existingCustomerNumbers = await new Promise((resolve) => Ajax.get(
        this.options.select2.ajax.url,
        this.options.autocompleteParams,
        (response) => resolve(response.external_customer_numbers),
        () => resolve([]),
      ));
    }

    return this.existingCustomerNumbers;
  };

  /**
   * @return {object}
   */
  this.validate = function() {
    return customerNumberValidator.validate(this.getValue()?.id);
  };

  // Initialize
  this.init();
}

export default OrderCustomerNumberSelectField;
