import BaseLoginForm from 'js/auth/forms/base-login-form';

/**
 * Login - Email Form.
 *
 * @class
 * @extends BaseLoginForm
 *
 * @param {object} [options]
 */
function LoginEmailForm(options) {
  BaseLoginForm.call(this, options);
  const parent = this.clone();

  /**
   * Get typed in email.
   *
   * @return {?string}
   */
  this.getEmail = function() {
    return $('input[name="email"]', this.formEl).val();
  };

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      tmplEl: '#auth-login-email-form',
      tmplParams: {
        submitBtnLabel: this.translator.getTitle('label_continue'),
      },
      serverParams: {
        signin_action: 'resolve_account_type',
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    return {
      email: {
        required: {
          message: this.translator.get('error_empty_email'),
        },
      },
    };
  };

  // Initialize
  this.init();
}

export default LoginEmailForm;
