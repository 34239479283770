import BaseFormGroup from 'js/base_v2/form-group';
import LoginEmailForm from 'js/auth/forms/login-email-form';
import LoginPasswordForm from 'js/auth/forms/login-password-form';
import Renderer from 'js/components/renderer';

/**
 * Login Form Group.
 *
 * @class
 * @extends BaseFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function LoginFormGroup(records, options) {
  BaseFormGroup.call(this, records, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.formParams = [{
    name: 'loginEmailForm',
    className: LoginEmailForm,
  }, {
    name: 'loginPasswordForm',
    className: LoginPasswordForm,
  }];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      activeFormName: 'loginEmailForm',
      defaults: {},
      loginEmailForm: {
        afterSubmit: (response) => this.afterLoginEmailFormSubmit(response),
      },
      loginPasswordForm: {
        switchButtons: [{
          targetFormName: 'loginEmailForm',
          label: Renderer.Back(this.translator.getTitle('label_back'), 'left'),
        }],
        afterSubmit: () => this.afterLoginPasswordFormSubmit(),
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    return this.extendOptions({
      loginEmailForm: {
        defaults: this.options.defaults,
      },
    });
  };

  /**
   * Login - Email Form after submit event handler.
   *
   * @param {object} response
   */
  this.afterLoginEmailFormSubmit = function(response) {
    if (1 === +response.sso_enabled) {
      window.location.href = response.sso_redirect_url;
    } else {
      this.forms.loginPasswordForm.extendOptions({
        defaults: {
          email: this.forms.loginEmailForm.getEmail(),
        },
      });

      this.reload('loginPasswordForm');
    }

    return false;
  };

  /**
   * Login - Password Form after submit event handler.
   */
  this.afterLoginPasswordFormSubmit = function() {
    window.location.reload();

    return false;
  };

  // Initialize
  this.init();
}

export default LoginFormGroup;
