/* eslint-disable max-len */
import BaseQuoteCreateFormGroup from 'js/base_v3/forms/quotation_forms/create-form-group';
import Renderer from 'js/components/renderer';
import CustomerOrderCreateForm from 'js/order/customer/forms/create-form';
import OrderTypeChooseForm from 'js/order/forms/type-choose-form';
import VendorOrderCreateForm from 'js/order/vendor/forms/create-form';
/* eslint-enable max-len */

/**
 * Order Create Form Group.
 *
 * @class
 * @extends BaseQuoteCreateFormGroup
 *
 * @param {object} [records]
 * @param {object} [options]
 */
function OrderCreateFormGroup(records, options) {
  BaseQuoteCreateFormGroup.call(this, records, options);
  const parent = this.clone();

  /**
   * @prop {boolean}
   */
  this.coreOrderIntegrationEnabled = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      [this.saveCustomerQuotationFormName]: {
        data: {
          coreOrderIntegrationEnabled: this.coreOrderIntegrationEnabled,
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.getTypeChooseFormClass = function() {
    return OrderTypeChooseForm;
  };

  /**
   * @inheritDoc
   */
  this.getSaveCustomerQuotationFormName = function() {
    return 'customerOrderCreateForm';
  };

  /**
   * @inheritDoc
   */
  this.getSaveVendorQuotationFormName = function() {
    return 'vendorOrderCreateForm';
  };

  /**
   * @inheritDoc
   */
  this.getCreateCustomerQuotationFormClass = function() {
    return CustomerOrderCreateForm;
  };

  /**
   * @inheritDoc
   */
  this.getCreateVendorQuotationFormClass = function() {
    return VendorOrderCreateForm;
  };

  /**
   * @inheritDoc
   */
  this.getSaveQuotationFormDefaultOptions = function(quotationType) {
    let defaultOptions = parent.getSaveQuotationFormDefaultOptions.call(
      this,
      quotationType,
    );

    defaultOptions = defaultOptions.extend({
      switchButtons: [{
        targetFormName: 'typeChooseForm',
        label: Renderer.Back('Back', 'left'),
      }],
    });

    return defaultOptions;
  };

  // Initialize
  this.init();
}

export default OrderCreateFormGroup;
