import BaseTypeaheadField from 'js/base_v2/typeahead-field';
import Renderer from 'js/components/renderer';
/**
 * Part Search Typeahead Field.
 *
 * @class
 * @extends BaseTypeaheadField
 *
 * @param {DOMElement} fieldCt
 * @param {DOMElement} fieldEl
 * @param {object}     [options]
 */
function PartSearchTypeaheadField(fieldCt, fieldEl, options) {
    BaseTypeaheadField.call(this, fieldCt, fieldEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entitiesName: 'parts',
            url: '/parts/index/autocomplete?manufacturer_included=1&page_size=5&q=%QUERY',
            urlWildcard: '%QUERY'
        });
    };

    /**
     * @inheritDoc
     */
    this.getRecordLabel = function(record) {
        return Renderer.PartLabel(record, {
            display_manufacturer: true
        });
    };

    /**
     * @inheritDoc
     */
    this.getDisplay = function() {
        return (record) => Renderer.PartNumber(record.number);
    };

    // Initialize
    this.init();
}

export default PartSearchTypeaheadField;
