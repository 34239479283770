import BaseList from 'js/base_v2/list';
import MessageFilterGroup from 'js/messages/filters/message-filter-group';
import MessageTextModal from 'js/messages/full-text-modal';
import MessageTable from 'js/messages/tables/message-table';
import NewMessageRowView from 'js/messages_v2/views/new-message-row-view';
/**
 * Message List
 *
 * @class
 * @extends BaseList
 *
 * @param {DOMElement} listCt
 * @param {DOMElement} tableEl
 * @param {object}     options
 */
function MessageList(listCt, tableEl, options) {
    BaseList.call(this, listCt, tableEl, null, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {NewMessageRowView}
     */
    this.newMessageRowView = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.extendDefaultOptions({
            entityName: 'message',
            entityLabel: 'message',
            tableClass: MessageTable,
            loadUrl: '/messages/list/items',
            filterGroupClass: MessageFilterGroup,
            filterGroupCt: '.messageFilterGroupCt',
            showNewMessageRow: false,
            newMessageRowView: {
                composeForm: {
                    afterSubmit: function() {
                        self.reloadAndScrollToBottom();
                    }
                }
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.registerEventListeners = function() {
        parent.registerEventListeners.call(this);

        this.listCt.on('click', '.messageTextIntro', function(event) {
            self.onMessageNoteClick($(this), event);
        });

        return this;
    };

    /**
     * Create new quote part row view.
     *
     * @return {MessageList}
     */
    this.createNewMessageRowView = function() {
        this.newMessageRowView = new NewMessageRowView(
            this.tableEl,
            this.options.newMessageRowView
        ).create();

        this.table.adjustColumns();

        return this;
    };

    /**
     * @inheritDoc
     */
    this.onTableDraw = function() {
        parent.onTableDraw.call(this);

        if (this.options.showNewMessageRow) {
            this.createNewMessageRowView();
        }
    };

    /**
     * Message note button click event handler.
     *
     * @param {DOMElement} element
     * @param {Event}      event
     */
    this.onMessageNoteClick = function(element, event) {
        event.preventDefault();

        var record = this.table.getRowData(element.parents('tr'));

        new MessageTextModal({
            data: {
                message: record.message_text
            }
        }).create();
    };

    // Initialize
    this.init();
}

export default MessageList;
