import React from 'react';

import FieldLabel from '../FieldLabel';
import Select from '../Select';

const sizes = {
  sm: 'small',
};

const SelectFieldView = ({
  label,
  required,

  // Form props
  value,
  onChange,

  // Select props
  options,
  loadPath,
  loadParams = {},
  responseOptionsField = 'data',
  responseCountField = 'recordsFiltered',
  processResponse,
  isMulti = false,
  pageSize = 25,
  getValue = option => option.id,
  getLabel = option => option.name,
  placeholder = ' ',

  // Styling
  size = 'sm',
}) => {

  return (
    <div className="form-group">
      <FieldLabel {...{ label, required }} />

      <Select
        {...{
          value,
          onChange,
          options,
          loadPath,
          loadParams,
          responseOptionsField,
          responseCountField,
          processResponse,
          isMulti,
          pageSize,
          getValue,
          getLabel,
          placeholder,
          size: sizes[size] || size,
        }}
      />
    </div>
  );
};

export default SelectFieldView;
