/* eslint-disable max-len */
import BaseCustomerQuotationCreateForm from 'js/base_v3/forms/quotation_forms/customer-quotation-create-form';
import OrderCustomerNumberSelectField from 'js/order/fields/customer-number-select-field';
import OrderHelper from 'js/order/helpers/helper';
/* eslint-enable max-len */

/**
 * Customer Order Create Form.
 *
 * @class
 * @extends BaseCustomerQuotationCreateForm
 *
 * @param {object} [record]
 * @param {object} [options]
 */
function CustomerOrderCreateForm(record, options) {
  BaseCustomerQuotationCreateForm.call(this, record, options);
  const parent = this.clone();

  /**
   * @prop {boolean}
   */
  this.coreOrderIntegrationEnabled = null;

  /**
   * @prop {OrderCustomerNumberSelectField}
   */
  this.orderCustomerNumberSelectField = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'order',
      entityLabel: this.translator.get('customer_order_label'),
      actionUrl: '/order/customer/save',
      contactField: {
        onChange: () => {
          this.onContactFieldChange();
        },
      },
      orderCustomerNumberSelectField: {},
      validate: (notify, formData) => this.validateOrderFormData(
        formData.order,
      ),
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    if (this.coreOrderIntegrationEnabled) {
      this.options.requiredFields = this.options.requiredFields || [];

      const additionalRequiredFields = [
        'external_customer_number',
        'purchase_order_number',
      ];

      _.each(additionalRequiredFields, (field) => {
        if (!this.options.requiredFields.includes(field)) {
          this.options.requiredFields.push(field);
        }
      }, this);
    }

    return this.extendOptions({
      hiddenFields: [
        `${this.options.entityName}[close_confidence]`,
        `${this.options.entityName}[estimated_close_date]`,
        `${this.options.entityName}[vertical_market_id]`,
        `${this.options.entityName}[location_id]`,
        `${this.options.entityName}[external_number]`,
        `${this.options.entityName}[external_project_name]`,
        `${this.options.entityName}[revision_number]`,
        `${this.options.entityName}[attach_to]`,
        'validity_field_group',
      ],
    });
  };

  /**
   * @inheritDoc
   */
  this.registerEventListeners = function() {
    parent.registerEventListeners.call(this);

    this.formEl.on('input', '.bomField', (event) => {
      this.onBomFieldInput(event);
    });

    return this;
  };

  /**
   * On bom field input.
   *
   * @param {object} event
   */
  this.onBomFieldInput = function(event) {
    this.orderCustomerNumberSelectField?.setJobName(event.target.value);
  };

  /**
   * @inheritdDoc
   */
  this.onProjectFieldChange = function() {
    if (parent.onProjectFieldChange.call(this) === false) {
      return false;
    }

    const projectId = this.projectField?.getRecord()?.id;

    this.options.orderCustomerNumberSelectField = this.options
      .orderCustomerNumberSelectField.extend({
        autocompleteParams: {
          project_id: projectId,
        },
      });
    this.orderCustomerNumberSelectField?.setProjectId(projectId);

    return true;
  };

  /**
   * On contact field change.
   */
  this.onContactFieldChange = function() {
    this.orderCustomerNumberSelectField?.setCustomerUserId(
      this.contactField.getRecord().user_id,
    );
  };

  /**
   * @inheritDoc
   */
  this.prepareServerParams = function() {
    parent.prepareServerParams.call(this);

    if (_.isObject(this.projectField)) {
      const project = this.projectField.getRecord();

      this.options.serverParams = this.options.serverParams.extend({
        order: {
          close_confidence: Object.get(project, 'close_confidence'),
          estimated_close_date: moment().format('MM/YYYY'),
          vertical_market_id:
            Object.get(project, 'vertical_market_id') || '__skip__',
          valid_until: this.helper.getValidUntilFromProject(project)
            .format('MM/DD/YYYY'),
        },
      });
    }

    return this;
  };

  /**
   * @inheritDoc
   */
  this.onShown = function() {
    parent.onShown.call(this);

    this.createCustomerNumberSelectField();
  };

  /**
   * @return {bool}
   */
  this.validateOrderFormData = function () {
    try {
      this.orderCustomerNumberSelectField.validate();
    } catch (error) {
      this.notifier.notifyError(error);

      return false;
    }

    return true;
  };

  /**
   * Create customer number field.
   */
  this.createCustomerNumberSelectField = function () {
    this.orderCustomerNumberSelectField = new OrderCustomerNumberSelectField(
      $('.externalCustomerNumberContainer', this.formEl),
      $('.externalCustomerNumber', this.formEl),
      this.options.orderCustomerNumberSelectField,
    ).create();

    if (_.isObject(this.bom) && !_.isEmpty(this.bom.job_name)) {
      this.orderCustomerNumberSelectField.setJobName(this.bom.job_name);
    }
  };

  /**
   * @inheritDoc
   */
  this.getHelper = function() {
    return new OrderHelper();
  };

  // Initialize
  this.init();
}

export default CustomerOrderCreateForm;
