import { createSlice } from '@reduxjs/toolkit';

const quotePartSlice = createSlice({
  name: 'quotePart',
  initialState: null,
  reducers: {
    setQuotePart(state, action) {
      return action.payload.quotePart;
    },
  },
});

export const { setQuotePart } = quotePartSlice.actions;

export default quotePartSlice.reducer;
