import BaseBulkSaveForm from 'js/base_v2/bulk-save-form';
import DateField from 'js/base_v2/fields/date-field';
import SelectField from 'js/components/select-field';
import CloseDateField from 'js/components_v2/fields/close-date-field';
import QuillTextarea from 'js/components_v2/quill-textarea';
import MarketField from 'js/corporate_v2/market-field';
import QuoteFormHelper from 'js/quote/helpers/form-helper';
import RecordsUtils from 'js/utils/records';
import Quote from 'js/quote/models/quote';
import { quoteRenderHelper } from 'js/quote/helpers/render-helper';

/**
 * Quote Bulk Update Form.
 *
 * @class
 * @extends BaseBulkSaveForm
 *
 * @param {object} records
 * @param {object} [options]
 */
function QuoteBulkUpdateForm(records, options) {
  BaseBulkSaveForm.call(this, records, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @prop {BaseSelect2Field}
   */
  this.statusField = null;

  /**
   * @prop {QuillTextarea}
   */
  this.statusLostNoteField = null;

  /**
   * @prop {CloseDateField}
   */
  this.closeDateField = null;

  /**
   * @prop {MarketField}
   */
  this.verticalMarketField = null;

  /**
   * @prop {DateField}
   */
  this.validUntilField = null;

  /**
   * @prop {QuoteFormHelper}
   */
  this.quoteFormHelper = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'quotes',
      entitiesName: this.translator.get('label_quotes'),
      entitiesLabel: this.translator.get('label_quotes'),
      tmplEl: '#quote-update-form',
      tmplParams: {
        quoteRenderHelper,
        actionUrl: '/quote/customer/save-bulk',
        noteClassName: 'hidden',
      },
      statusFieldClass: SelectField,
      statusField: {
        onChange(event) {
          self.onQuoteStatusChange(event);
        },
      },
      statusLostNoteField: {
        quillEditor: {
          modules: {
            toolbar: false,
          },
        },
      },
      estimatedCloseDateFieldClass: CloseDateField,
      estimatedCloseDateField: {},
      verticalMarketFieldClass: MarketField,
      verticalMarketField: {},
      validUntilFieldClass: DateField,
      validUntilField: {
        daterangepicker: {
          minDate: moment().format('MM/DD/YYYY'),
          locale: {
            format: 'MM/DD/YYYY',
          },
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.initProps = function() {
    parent.initProps.call(this);

    this.quoteFormHelper = new QuoteFormHelper();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.initFields = function() {
    parent.initFields.call(this);

    return this
      .createField('status')
      .createField('estimatedCloseDate')
      .createField('verticalMarket')
      .createField('validUntil');
  };

  /**
   * @inheritDoc
   */
  this.prepareTmplParams = function() {
    parent.prepareTmplParams.call(this);

    return this.extendOptions({
      tmplParams: {
        statuses: this.quoteFormHelper.getDropdownStatuses(),
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processFormData = function(formData) {
    parent.processFormData.call(this, formData);

    return this.getUpdateData(formData);
  };

  /**
   * Quote status change event handler.
   *
   * @param {object} event
   */
  this.onQuoteStatusChange = function(event) {
    event.preventDefault();

    const statusFieldValue = $('.statusField', this.formEl).val();

    if (Quote.STATUS_LOST === statusFieldValue &&
        _.isNull(this.statusLostNoteField)
    ) {
      this.statusLostNoteField = new QuillTextarea(
        $('.textEditor', this.formEl),
        this.options.statusLostNoteField,
      ).create();
    }

    $('.statusLostNoteContainer', this.formEl).toggleClass(
      'hidden',
      Quote.STATUS_LOST !== statusFieldValue,
    );
  };

  /**
   * Get update data.
   *
   * @param  {object}   formData
   * @return {object[]}
   */
  this.getUpdateData = function(formData) {
    const quoteIds = RecordsUtils.getIds(this.records);

    const updateData = {};

    _.each(formData.quotes, (value, field) => {
      if (_.isEmpty(value)) {
        return;
      }

      updateData[field] = value;

      if ('status' === field && Quote.STATUS_LOST === value) {
        updateData.status_notes = {
          lost: self.statusLostNoteField.getPlainText(),
        };
      }
    });

    const bulkUpdateData = quoteIds.map(
      (quoteId) => updateData.extend({ id: quoteId }),
    );

    return {
      quotes: bulkUpdateData,
    };
  };

  /**
   * @inheritDoc
   */
  this.getRawFormData = function() {
    const formData = parent.getRawFormData.call(this);

    if (Quote.STATUS_LOST !== $('.statusField', this.formEl).val()) {
      return formData;
    }

    return formData.extend({
      quotes: {
        status_notes: {
          lost: this.statusLostNoteField.getPlainText(),
        },
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.validate = function(notify = true) {
    if (!parent.validate.call(this, notify)) {
      return false;
    }

    return this.validateFormFields(this.getFormData(), notify);
  };

  /**
   * Validate form fields.
   *
   * @param  {object}  formData
   * @param  {boolean} notify
   * @return {boolean}
   */
  this.validateFormFields = function(formData, notify) {
    const updatedValues = Object.entries(formData.quotes).filter(
      (value) => !_.isEmpty(value[1]),
    );

    if (_.isEmpty(updatedValues) && notify) {
      this.notifier.notifyError(
        this.translator.get('error_fill_one_field'),
      );

      return false;
    }

    return true;
  };

  /**
   * @inheritDoc
   */
  this.getBeforeSubmitMessage = function() {
    return 'Updating Quotes...';
  };

  /**
   * @inheritDoc
   */
  this.getAfterSubmitMessage = function() {
    return 'Quotes have been successfully updated.';
  };

  /**
   * @inheritDoc
   */
  this.getConstraints = function() {
    const constraints = parent.getConstraints.call(this);

    if ($('.statusLostNoteContainer', this.formEl).hasClass('hidden')) {
      return constraints;
    }

    return constraints.extend({
      'quotes[status_notes][lost]': {
        required: {
          message: this.translator.get('error_empty_status_lost_note'),
        },
      },
    });
  };

  // Initialize
  this.init();
}

export default QuoteBulkUpdateForm;
