import React from 'react';

import { translator } from 'js/translator';

import { eventDispatcher } from 'partfiniti/core/services';
import { MessageCcChanged } from 'partfiniti/messages/events';
import { Select } from 'partfiniti/core/components';

const MessageCcFormView = () => {
  const [value, setValue] = React.useState([]);

  const handleChange = (ccRecipients) => {
    setValue(ccRecipients);

    eventDispatcher.dispatch(new MessageCcChanged(ccRecipients));
  };

  const processResponse = (response, query) => {
    if (response.data.length > 0) {
      return response;
    }

    if (!/\S+@\S+\.\S+/.test(query)) {
      // if the query has an email format then allow it as valid option
      return response;
    }

    return {
      data: [{
        email: query,
        type: 'email',
      }],
      recordsFiltered: 1,
    };
  };

  return (
    <div>
      <Select
        loadPath="/corporate/user/users"
        value={value}
        onChange={handleChange}
        isMulti
        getValue={option => option.email}
        getLabel={({ name, email }) => (
          [name, email].filter(item => item).join(' ')
        )}
        processResponse={processResponse}
        placeholder={translator.get('select_message_ccs')}
      />
    </div>
  );
}

export default MessageCcFormView;
