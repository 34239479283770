import styled, { css } from 'styled-components';

export const LeftWrapper = styled.div`
  display: inline-block;
  width: calc(50% - 4px);
  margin-right: 4px;
`;

export const RightWrapper = styled.div`
  display: inline-block;
  width: calc(50% - 4px);
  margin-left: 4px;

  ${({ alignRight }) => alignRight && css`
    text-align: right;
  `}
`;
