import ModalForm from 'js/base_v2/modal-form';
import ImpersonatedUserRepository from 'js/auth/repositories/impersonated-user-repository';
import UserField from 'js/auth/fields/user-field';
/**
 * Impersonate Form.
 *
 * @class
 * @extends ModalForm
 *
 * @param {object} options
 */
function ImpersonateForm(options) {
    ModalForm.call(this, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {object}
     */
    this.attempts = {};

    /**
     * @prop {ImpersonatedUserRepository}
     */
    this.userRepository = null;

    /**
     * @prop {UserField}
     */
    this.userField = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        this.extendDefaultOptions({
            focusFirstField: false,
            tmplEl: '#impersonate-form',
            tmplParams: {
                title: 'Impersonate',
                submitBtnLabel: 'Impersonate Now'
            },
            userField: {}
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.initProps = function() {
        parent.initProps.call(this);

        this.userRepository =
            new ImpersonatedUserRepository(this.options.userRepository);

        return this;
    };

    /**
     * @inheritDoc
     */
    this.initFields = function() {
        parent.initFields.call(this);
        return this.createUserField();
    };

    /**
     * Create user field.
     *
     * @return {ImpersonateForm}
     */
    this.createUserField = function() {
        this.userField = new UserField(
            $('.userFieldCt', this.formEl),
            $('.userFieldCt .userField', this.formEl),
            this.options.userField
        ).create();

        return this;
    };

    /**
     * @inheritDoc
     */
    this.registerEventListeners = function() {
        parent.registerEventListeners.call(this);

        this.formEl.on('click', '.impersonateMostRecent', function(e) {
            self.onImpersonateMostRecentBtnClick($(this), e);
        });

        return this;
    };

    /**
     * Impersonate most recent button click event handler.
     *
     * @param {DOMElement} btn
     * @param {Event}      e
     */
    this.onImpersonateMostRecentBtnClick = function(btn, e) {
        e.preventDefault();

        var user = {
            id: btn.attr('data-id'),
            name: btn.attr('data-name'),
            email: btn.attr('data-email')
        };

        this.impersonate(user);
    };

    /**
     * @inheritDoc
     */
    this.prepareTmplParams = function() {
        parent.prepareTmplParams.call(this);

        this.extendOptions({
            tmplParams: {
                mostRecentUsers: this.getMostRecentUsers()
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.afterSubmit = function(response) {
        if (false === parent.afterSubmit.call(this, response)) {
            return false;
        }

        this.userRepository.save(response.impersonated_user);
        window.location.reload();

        return undefined;
    };

    /**
     * @inheritDoc
     */
    this.onSubmitError = function(response, errorMessage) {
        var key = this.formEl.serialize();

        if (_.has(this.attempts, key)) {
            this.attempts = {};
            parent.onSubmitError.call(this, response, errorMessage);

            return;
        }

        this.attempts[key] = true;
        this.submit();
    };

    /**
     * @inheritDoc
     */
    this.getConstraints = function() {
        return {
            'impersonated_user_id': {
                'required': {
                    'message': 'Please select the user to impersonate.'
                }
            }
        };
    };

    /**
     * @inheritDoc
     */
    this.getAfterSubmitMessage = function(response) {
        var user = response.impersonated_user;
        return 'Successfully impersonated as ' + this.getUserName(user) + '.';
    };

    /**
     * Impersonate user.
     *
     * @param  {object}          user
     * @return {ImpersonateForm}
     */
    this.impersonate = function(user) {
        this.userField.setRecord(user);
        this.submit();

        return this;
    };

    /**
     * Get most recent users.
     *
     * @return {object[]}
     */
    this.getMostRecentUsers = function() {
        return this.processUsers(this.userRepository.findAll());
    };

    /**
     * Process users.
     *
     * @param  {object[]} users
     * @return {object[]}
     */
    this.processUsers = function(users) {
        var processedUsers = [];

        _.each(users, function(user) {
            user.name = this.getUserName(user);
            user.label = this.getUserLabel(user);
            processedUsers.push(user);
        }, this);

        return processedUsers;
    };

    /**
     * Get user name.
     *
     * @param {object} user
     * @return {string}
     */
    this.getUserName = function(user) {
        return $.trim((user.first_name || '') + ' ' + (user.last_name || ''));
    };

    /**
     * Get user label.
     *
     * @param  {object} user
     * @return {string}
     */
    this.getUserLabel = function(user) {
        if (!user.id) {
            return '';
        }

        var pieces = [];

        pieces.push('#' + user.id);
        pieces.push(user.name);
        pieces.push('(' + user.email + ')');

        return pieces.join(' ');
    };

    // Initialize
    this.init();
}

export default ImpersonateForm;
