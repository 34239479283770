import BaseView from 'js/base_v2/view';
import DefaultNotifier from 'js/components_v2/default-notifier';
/**
 * Base New Table Row View.
 *
 * @class
 * @abstract
 * @extends BaseView
 *
 * @param {DOMElement} tableEl
 * @param {?object}    options
 */
function BaseNewTableRowView(tableEl, options) {
    BaseView.call(this, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {DOMElement}
     */
    this.tableEl = tableEl;

    /**
     * @prop {DOMElement}
     */
    this.rowEl = null;

    /**
     * @prop {DefaultNotifier}
     */
    this.notifier = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            after: undefined,
            closeEnabled: false,
            notifier: {}
        });
    };

    /**
     * @inheritDoc
     */
    this.create = function() {
        this.render();

        parent.create.call(this);

        return this;
    };

    /**
     * Render.
     *
     * @return {BaseNewTableRowView}
     */
    this.render = function() {
        this.rowEl = $(this.getRowHtml());

        if (this.options.after) {
            $(this.options.after).after(this.rowEl);
        } else {
            $('tbody', this.tableEl).append(this.rowEl);
        }

        return this;
    };

    /**
     * @inheritDoc
     */
    this.registerEventListeners = function() {
        parent.registerEventListeners.call(this);

        this.rowEl.on('click', '.closeRow', function(event) {
            self.onCloseRowButtonClick(event);
        });

        return this;
    };

    /**
     * Close row button click event handler.
     *
     * @param {Event} event
     */
    this.onCloseRowButtonClick = function(event) {
        if (!this.options.closeEnabled) {
            return;
        }

        this.rowEl.remove();
    };

    /**
     * @inheritDoc
     */
    this.createComponents = function() {
        parent.createComponents.call(this);

        return this.createNotifier();
    };

    /**
     * Create notifier.
     *
     * @return {BaseNewTableRowView}
     */
    this.createNotifier = function() {
        this.notifier = new DefaultNotifier(this.options.notifier).create();

        return this;
    };

    /**
     * Get row html.
     *
     * @abstract
     *
     * @return {string}
     */
    this.getRowHtml = function() {
        throw 'BaseNewTableRowView.getRowHtml not implemented.';
    };
}

export default BaseNewTableRowView;
