import React from 'react';

import Button from 'partfiniti/core/components/Button';
import DateField from 'partfiniti/core/components/DateField';
import Modal from 'partfiniti/core/components/Modal';
import SelectField from 'partfiniti/core/components/SelectField';
import TextField from 'partfiniti/core/components/TextField';
import Visible from 'partfiniti/core/components/Visible';
import Wrapper from 'partfiniti/core/components/Wrapper';

const renderField = (config, fields, initialValues) => {
  switch (config.type) {
    case 'text':
    case 'textarea':
      return (
        <TextField
          key={config.name}
          {...{
            ...config,
            ...fields[config.name],
          }}
        />
      );
    case 'select':
      return (
        <SelectField
          key={config.name}
          {...{
            options: initialValues?.[config.name] ?
              [initialValues?.[config.name]] :
              [],
            ...config,
            ...fields[config.name],
          }}
        />
      );
    case 'date':
      return (
        <DateField
          key={config.name}
          {...{
            ...config,
            ...fields[config.name],
          }}
        />
      );
    default:
      throw new Error(`Unknown field type "${config.type}"`);
  }
};

const CrudFormView = ({
  isOpen,
  onClose,
  onRemove,
  createTitle,
  editTitle,
  size,
  fieldsConfig,
  allowRemove,
  createBtnLabel,
  editBtnLabel,
  removeBtnLabel,

  initialValues,
  fields,
  form,
  onSubmit,
}) => {
  const error = fieldsConfig.reduce((firstError, field) => (
    firstError || (fields[field.name].touched && fields[field.name].error)
  ), null);

  return (
    <Modal
      header={{ title: initialValues?.id ? editTitle : createTitle }}
      error={error || form.error}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <Wrapper>
        {fieldsConfig.map(config => renderField(config, fields, initialValues))}
      </Wrapper>

      <Wrapper>
        <Visible when={allowRemove && Boolean(initialValues?.id)}>
          <Button onClick={() => onRemove(initialValues)} type="danger">
            {removeBtnLabel}
          </Button>
        </Visible>

        <Button onClick={onSubmit}>
          {initialValues?.id ? editBtnLabel : createBtnLabel}
        </Button>
      </Wrapper>
    </Modal>
  );
};

export default CrudFormView;
