import BaseQuotationHelper from 'js/base_v3/helpers/quotation-helper';
import BaseEntityPart from 'js/part/models/base-entity-part';

/**
 * Order Helper.
 *
 * @class
 * @extends BaseQuotationHelper
 *
 * @param {object} [options]
 */
function OrderHelper(options) {
  BaseQuotationHelper.call(this, options);

  /**
   * From given list get only those records that can be ordered.
   *
   * @param  {BaseEntityPart[]} records
   * @param  {object}           [orderOptions]
   * @return {BaseEntityPart[]}
   */
  this.getOrderableRecords = function(records, orderOptions = {}) {
    return records.filter(
      (record) => this.isRecordOrderable(record, orderOptions),
    );
  };

  /**
   * Determine whether record is orderable (e.g. is active part and customer
   * order part price value is greater than 0).
   *
   * @param  {BaseEntityPart} record
   * @param  {object}         [orderOpts]
   * @return {boolean}
   */
  this.isRecordOrderable = function(record, orderOpts = {}) {
    const orderOptions = {
      omitNoPricing: false,
    }.extend(orderOpts);

    if (BaseEntityPart.TYPE_PART !== record.type ||
      1 === +record.no_quote ||
      1 === +record.deleted) {
      return false;
    }

    if (orderOptions.omitNoPricing &&
      !_.isUndefined(record.price) &&
      !(record.price > 0)
    ) {
      return false;
    }

    return true;
  };

  /**
   * Determine whether the order can be submitted or not.
   *
   * @param  {array}   orderParts
   * @return {boolean}
   */
  this.isValidForSubmit = function(orderParts) {
    if (_.isEmpty(orderParts)) {
      return false;
    }

    let isValid = true;

    _.each(orderParts, (orderPart) => {
      if (!this.isRecordOrderable(orderPart)) {
        isValid = false;
      }
    }, this);

    return isValid;
  };

  // Initialize
  this._init();
}

export default OrderHelper;
