import BaseSelectionPanel from 'js/base_v2/selection-panel';
import BaseExportHelper from 'js/base_v3/helpers/export-helper';
import BaseQuotation from 'js/base_v3/models/quotation';
import QuoteArchiveConfirmation
  from 'js/quote/confirmations/archive-confirmation';
import QuoteFollowUpForm from 'js/quote/forms/follow-up-form';
import ReassignUserForm from 'js/quote/forms/reassign-user-form';
import QuoteBulkUpdateForm from 'js/quote/forms/update-bulk-form';
import Quote from 'js/quote/models/quote';
import RecordsUtils from 'js/utils/records';

/**
 * Quote Selection Panel.
 *
 * @class
 * @extends BaseSelectionPanel
 *
 * @param {object} [options]
 */
function QuoteSelectionPanel(options) {
  BaseSelectionPanel.call(this, options);
  const parent = this.clone();

  /**
   * @prop {QuoteBulkUpdateForm}
   */
  this.quoteBulkUpdateForm = null;

  /**
   * @prop {QuoteArchiveConfirmation}
   */
  this.quoteArchiveConfirmation = null;

  /**
   * @prop {ReassignUserForm}
   */
  this.reassignUserForm = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      template: 'quote-list-selection-panel',
      entityName: 'quote',
      entityLabel: 'quote',
      entitiesLabel: 'quotes',
      quoteArchiveConfirmation: {},
      quoteBulkUpdateForm: {},
      quoteFollowUpForm: {},
      reassignUserForm: {},
      exportQuotesList: undefined,
    });
  };

  /**
   * @inheritDoc
   */
  this.getAllowedActions = function() {
    let actions = [
      'followUpQuotes',
    ];

    if (BaseQuotation.TYPE_CUSTOMER === this.records[0].type) {
      actions = [...actions, ...[
        'updateQuotes',
        'archiveQuotes',
        'reassignQuotes',
        'exportSelectedQuotes',
        'exportFilteredQuotes',
      ]];
    }

    return actions;
  };

  /**
   * updateQuotes action handler.
   *
   * @return {QuoteSelectionPanel}
   */
  this.actionUpdateQuotes = function() {
    this.quoteBulkUpdateForm = new QuoteBulkUpdateForm(
      this.records,
      this.options.quoteBulkUpdateForm,
    ).create();

    return this;
  };

  /**
   * archiveQuotes action handler.
   *
   * @return {QuoteSelectionPanel}
   */
  this.actionArchiveQuotes = function() {
    this.quoteArchiveConfirmation = new QuoteArchiveConfirmation(
      this.records,
      this.options.quoteArchiveConfirmation,
    ).create();

    return this;
  };

  /**
   * followUpQuotes action handler.
   *
   * @return {QuoteSelectionPanel}
   */
  this.actionFollowUpQuotes = function() {
    this.quoteFollowUpForm = new QuoteFollowUpForm(
      this.options.quoteFollowUpForm.extend({
        data: {
          quotes: RecordsUtils.objectsToRecords(this.records, Quote),
        },
      }),
    ).create();

    return this;
  };

  /**
   * reassignQuotes action handler.
   *
   * @return {QuoteSelectionPanel}
   */
  this.actionReassignQuotes = function() {
    this.reassignUserForm = new ReassignUserForm(
      this.records,
      this.options.reassignUserForm,
    ).create();

    return this;
  };

  /**
   * exportSelectedQuotes action handler.
   *
   * @return {QuoteSelectionPanel}
   */
  this.actionExportSelectedQuotes = function() {
    if (_.isFunction(this.options.exportQuotesList)) {
      this.options.exportQuotesList(
        BaseExportHelper.CONTENT_SELECTED, // Content type
        this.records.map((record) => record.id), // Selected Quote IDs
      );
    }

    this.hide();

    return this;
  };

  /**
   * exportFilteredQuotes action handler.
   *
   * @return {QuoteSelectionPanel}
   */
  this.actionExportFilteredQuotes = function() {
    if (_.isFunction(this.options.exportQuotesList)) {
      this.options.exportQuotesList(BaseExportHelper.CONTENT_FILTERED);
    }

    this.hide();

    return this;
  };

  // Initialize
  this.init();
}

export default QuoteSelectionPanel;
