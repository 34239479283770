import React from 'react';
import { useTable, useSortBy } from 'react-table';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'partfiniti/core/components/Loader';
import Scrollable from 'partfiniti/core/components/Scrollable';
import Visible from 'partfiniti/core/components/Visible';

import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import NoRecordsMessage from './NoRecordsMessage';

const setupColWidth = columns => {
  const totalWidth = columns.reduce((sum, col) => sum + col.width, 0);

  return columns.map(col => ({
    ...col,
    minWidth: col.minWidth || col.width,
    width: `${Math.floor((100 * col.width) / totalWidth)}%`,
  }));
};

const TableView = ({
  name,
  columns,
  data,
  totalItems,
  loadMore,
  scrollableTarget = name,
  borders = false,
  alternating = true,
  headerLines = 1,
  footerLines = 0,
  emptyMessage,
  scrollableRef,

  onRowClick,
}) => {
  const processedColumns = React.useMemo(() => {
    return setupColWidth(columns);
  }, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: processedColumns,
      disableSortBy: true,
      data,
    },
    useSortBy,
  );

  return (
    <div {...getTableProps()}>
      <Header {...{ headerGroups, borders, lines: headerLines }} />

      <Visible when={rows.length === 0}>
        <NoRecordsMessage>{emptyMessage}</NoRecordsMessage>
      </Visible>

      <Scrollable
        id={scrollableTarget}
        ref={scrollableRef}
        hasFooter
      >
        <Visible when={rows.length > 0}>
          <InfiniteScroll
            dataLength={rows.length}
            next={loadMore}
            hasMore={totalItems > rows.length}
            loader={<Loader />}
            scrollableTarget={scrollableTarget}
            style={{
              height: 'auto',
              overflow: 'initial',
            }}
          >
            <div {...getTableBodyProps()}>
              <Body {...{
                rows,
                prepareRow,
                borders,
                alternating,
                onRowClick,
              }} />
            </div>
          </InfiniteScroll>
        </Visible>
      </Scrollable>

      <Visible when={footerLines > 0}>
        <Footer {...{ footerGroups, borders, lines: footerLines }} />
      </Visible>
    </div>
  );
}

export default TableView;
