export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const objectKeysCamelToSnakeCase = (val) => {
  if (_.isArray(val)) {
    return val.map(objectKeysCamelToSnakeCase);
  }

  if (!_.isObject(val)) {
    return val;
  }

  return Object.keys(val).reduce((snakeCaseObj, field) => {
    return {
      ...snakeCaseObj,
      [camelToSnakeCase(field)]: objectKeysCamelToSnakeCase(val[field]),
    };
  }, {});
};
