import BaseFilterGroup from 'js/base_v2/filter-group';
import TextFilter from 'js/components/text-filter';
import SelectFilter from 'js/components/select-filter';
import SelectField from 'js/components/select-field';
import DateRangeFilter from 'js/components_v2/date-range-filter';

/**
 * Message Filter Group.
 *
 * @class
 * @extends BaseFilterGroup
 *
 * @param {DOMElement} filterGroupContainer
 * @param {object}   options
 */
function MessageFilterGroup(filterGroupContainer, options) {
  BaseFilterGroup.call(this, filterGroupContainer, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.filterParams = [{
    name: 'queryFilter',
    className: TextFilter,
    filterEl: 'input[name="query"]',
  }, {
    name: 'directionFilter',
    className: SelectFilter,
    filterEl: 'select[name="direction"]',
  }, {
    name: 'typeFilter',
    className: SelectFilter,
    filterEl: 'select[name="type"]',
  }, {
    name: 'dateRangeFilter',
    className: DateRangeFilter,
    filterEl: '.messageDateRangePicker',
  }];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      queryFilter: {
        name: 'query',
      },
      directionFilter: {
        name: 'direction',
        fieldClassName: SelectField,
        fieldOptions: {
          select2: {
            allowClear: false,
          },
        },
      },
      typeFilter: {
        name: 'type',
        fieldClassName: SelectField,
        fieldOptions: {
          select2: {
            allowClear: false,
          },
        },
      },
      periodFilter: {
        name: 'period',
        fieldClassName: SelectField,
        fieldOptions: {
          select2: {
            allowClear: false,
          },
        },
      },
      dateRangeFilter: {
        autoUpdateInput: false,
        allRangeEmpty: true,
        name: 'creation_date',
        ranges: {
          Today: [moment()],
          'Month to Date': [moment().startOf('month'), moment()],
          'Year to Date': [moment().startOf('year'), moment()],
        },
      },
    });

    return this;
  };

  // Initialize
  this.init();
}

export default MessageFilterGroup;
