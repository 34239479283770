import React from 'react';

import './wrapper.scss';

const WrapperView = ({
  textAlign = 'left',
  children,
}) => {
  const classes = ['wrapper_block'];

  switch (textAlign) {
    case 'center':
      classes.push('text_center');

      break;
    default:
            // Nothing
  }

  return (
    <div className={classes.join(' ')}>
      {children}
    </div>
  );
};

export default WrapperView;
