import styled from 'styled-components';

const calculateHeight = ({
  theme: { topBigFixedHeaderHeight, filterBarHeight, headerFooterTableHeight },
  hasBigHeader = true,
  hasFilters = true,
  hasFooter = false,
}) => {
  if (hasBigHeader && hasFilters && hasFooter) {
    return `calc(100vh - ${topBigFixedHeaderHeight}px - ${filterBarHeight}px -
      2 * ${headerFooterTableHeight}px)`;
  }
  if (hasBigHeader && hasFilters) {
    return `calc(100vh - ${topBigFixedHeaderHeight}px - ${filterBarHeight}px)`;
  }
  if (hasBigHeader) {
    return `calc(100vh - ${topBigFixedHeaderHeight}px)`;
  }

  return '100vh';
};

const ScrollableView = styled.div`
  overflow: auto;
  height: ${props => calculateHeight(props)};
`;

export default ScrollableView;
