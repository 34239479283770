import BaseExportHelper from 'js/base_v3/helpers/export-helper';

const staticSelf = QuoteExportHelper;

/**
 * @const
 */
staticSelf.CONTENT_SELECTED = BaseExportHelper.CONTENT_SELECTED;

/**
 * @const
 */
staticSelf.CONTENT_FILTERED = BaseExportHelper.CONTENT_FILTERED;

/**
 * @const
 */
staticSelf.TYPE_PARTS = 'export_parts';

/**
 * @const
 */
staticSelf.TYPE_LIST = 'export_list';

/**
 * @const
 */
staticSelf.TYPE_QUOTE = 'export_quote';

/**
 * Quote Export Helper.
 *
 * @class
 * @abstract
 * @extends BaseExportHelper
 *
 * @param {?object} [options]
 */
function QuoteExportHelper(options) {
  BaseExportHelper.call(this, options);
}

export default QuoteExportHelper;
