import React from 'react';
import className from 'class-names';
import styled from 'styled-components';

import Cell from './Cell';

const Tr = styled.div`
    cursor: default;
    padding: 0 15px;

    &.even {
        background-color: #fff;
    }

    &.odd {
        background-color: #f9f9f9;
    }

    &.odd:hover,
    &.even:hover {
        background-color: #f6f6f6;
    }

    &.has-border {
        border-bottom: 1px solid #aaa;
    }
`;

const Td = styled.div`
    display: inline-block;
    padding: 21px 15px;
    vertical-align: top;
`;

const Body = ({
  rows,
  prepareRow,
  borders,
  alternating,
  onRowClick,
}) => rows.map((row) => {
  prepareRow(row);

  const classes = className({
    even: alternating && row.index % 2,
    odd: alternating && (row.index + 1) % 2,
    'has-border': borders,
  });

  return (
    <Tr // eslint-disable-line react/jsx-key
      {...row.getRowProps()}
      className={classes}
      onClick={() => (
        typeof onRowClick === 'function' && onRowClick(row.original)
      )}
    >
      {row.cells.map(cell => (
        <Td {...{ // eslint-disable-line react/jsx-key
          ...cell.getCellProps(),
          style: {
            width: cell.column.width,
            minWidth: cell.column.minWidth,
          },
        }}>
          {cell.render(Cell)}
        </Td>
      ))}
    </Tr>
  );
});

export default Body;
