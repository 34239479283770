import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import FieldLabel from 'partfiniti/core/components/FieldLabel';
import Visible from 'partfiniti/core/components/Visible';

import { DateInput } from './dateFieldStyle.js';

const DateFieldView = ({
  label,
  required,

  // Form props
  startDate,
  endDate,
  onChange,

  // Select props
  minDate,
  selectsEnd,
  selectsStart,
  dateFormat = 'MM/dd/yy',
  showTimeInput = false,
  timeInputLabel,
  value,
  monthsShown,
  inline,
  placeholder = ' ',
  
  // Styling
  size = 'sm',
  showBorder = false,
  hasBorder = true,
  selectsRange = false,
}) => {
  const [date, setDate] = useState(value);

  const handleChange = date => {
    setDate(date);
    onChange && onChange(date);
  };

  return (
    <div className={selectsRange ? '' : 'form-group'}>
      <Visible when={Boolean(label)}>
        <FieldLabel {...{ label, required }} />
      </Visible>

      <DateInput
        size={size}
        showBorder={showBorder}
        selectsRange={selectsRange}
        hasBorder={hasBorder}
      >
        <ReactDatePicker
          {...{
            dateFormat,
            showTimeInput,
            timeInputLabel,
            onChange: handleChange,
            startDate: _.isArray(date) ? date[0] : startDate,
            endDate: _.isArray(date) ? date[1] : endDate,
            minDate,
            selected: _.isArray(date) ? null : date,
            selectsStart,
            selectsEnd,
            selectsRange,
            inline,
            monthsShown,
            placeholderText: placeholder,
            popperPlacement: 'top-start',
          }}
        />
      </DateInput>
    </div>
  );
};

export default DateFieldView;
