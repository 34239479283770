import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
    font: 400 16px/1.2em "Open Sans",sans-serif;
`;

const Secondary = styled.div`
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    padding-top: 5px;
`;

const Cell = ({ value, column, row }) =>{
  let mainContent = String(value);
  let secondaryContent = null;

  if (_.isFunction(column.main)) {
    mainContent = column.main(row, value);
  }

  if (_.isFunction(column.secondary)) {
    secondaryContent = (
      <Secondary>{column.secondary(row, value)}</Secondary>
    );
  }

  return (
    <>
      <Main style={column.mainStyle}>{mainContent}</Main>
      {secondaryContent}
    </>
  );
}

export default Cell;
