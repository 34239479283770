import { combineReducers } from 'redux';

import partConfigurationReducer from 'js/react/part/redux/partConfiguration';
import partReducer from 'js/react/part/redux/part';
import quotePartReducer from 'js/react/part/redux/quotePart';
import quoteReducer from 'js/react/part/redux/quote';
import vendorCostsReducer from 'js/react/part/redux/vendorCosts';

export default combineReducers({
  part: partReducer,
  partConfiguration: partConfigurationReducer,
  quote: quoteReducer,
  quotePart: quotePartReducer,
  vendorCosts: vendorCostsReducer,
});
