import React from 'react';

import Icon from '../Icon';

const LoaderView = () => (
  <div className="loader fade in">
    <Icon name="loader" classNames="fa-spin"/>
  </div>
);

export default LoaderView;
