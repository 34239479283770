import { translator } from 'js/translator';

const DEFAULT_COST_ID = 'default';

const defaultCost = {
  id: DEFAULT_COST_ID,
  group_name: translator.getTitle('label_company_costing'),
};

const partCostEntity = {
  DEFAULT_COST_ID,

  getDefaultCost: () => defaultCost,
};

export default partCostEntity;
