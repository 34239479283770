import BaseNewTableRowView from 'js/base_v2/views/new-table-row-view';
import MessagesComposeForm from 'js/messages_v2/forms/compose';
/**
 * New Message Row View.
 *
 * @class
 * @abstract
 * @extends BaseNewTableRowView
 *
 * @param {DOMElement} tableEl
 * @param {?object}    options
 */
function NewMessageRowView(tableEl, options) {
    BaseNewTableRowView.call(this, tableEl, options);
    var parent = this.clone();
    var self = this;

    /**
     * @prop {MessagesComposeForm}
     */
    this.composeForm = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            composeForm: {
                entityType: undefined,
                entity: undefined
            }
        });
    };

    /**
     * Create compose form.
     *
     * @return {MessagesDetails}
     */
    this.createComposeForm = function() {
        var formEl = $('#messages-compose-form').tmpl();

        this.composeForm = new MessagesComposeForm(
            formEl,
            this.options.composeForm
        ).create();

        return this;
    };

    /**
     * @inheritDoc
     */
    this.render = function() {
        this.createComposeForm();

        var div = $('<div class="form-group message_form"></div>');
        div.append(this.composeForm.formEl);

        var td = $('<td class="text_left"></td>');
        td.append(div);

        var tr = $('<tr class="jsMainRow lastRow new-message-row"></tr>');
        tr.append(td);

        this.rowEl = tr;
        $('tbody', this.tableEl).append(this.rowEl);

        return this;
    };

    // Initialize
    this.init();
}

export default NewMessageRowView;
