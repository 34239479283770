import BaseFilterGroup from 'js/base_v2/filter-group';
import TextFilter from 'js/components/text-filter';
import SelectFilter from 'js/components/select-filter';
import SelectField from 'js/components/select-field';
import DateRangeFilter from 'js/components_v2/date-range-filter';
import CorporateUserField from 'js/corporate_v2/fields/corporate-user-field';

/**
 * Order Filter Group.
 *
 * @class
 * @extends BaseFilterGroup
 *
 * @param {DOMElement} filterGroupContainer
 * @param {object}   options
 */
function OrderFilterGroup(filterGroupContainer, options) {
  BaseFilterGroup.call(this, filterGroupContainer, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this.filterParams = [{
    name: 'queryFilter',
    className: TextFilter,
    filterEl: 'input[name="query"]',
  }, {
    name: 'typeFilter',
    className: SelectFilter,
    filterEl: 'select[name="type"]',
  }, {
    name: 'dateRangeFilter',
    className: DateRangeFilter,
    filterEl: '.orderPartDateRangePicker',
  }, {
    name: 'statusFilter',
    className: SelectFilter,
    filterEl: 'select[name="status"]',
  }, {
    name: 'usersFilter',
    className: SelectFilter,
    filterEl: 'select[name="corporate_users"]',
  }];

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    this.extendDefaultOptions({
      queryFilter: {
        name: 'query',
      },
      typeFilter: {
        name: 'type',
        fieldClassName: SelectField,
        fieldOptions: {
          select2: {
            allowClear: false,
          },
        },
      },
      dateRangeFilter: {
        name: 'created_at',
        ranges: {
          Today: [moment()],
          'Month to Date': [moment().startOf('month'), moment()],
          'Last 30 Days': [moment().subtract('30', 'd'), moment()],
          'Year to Date': [moment().startOf('year'), moment()],
        },
        initialRange: 'Last 30 Days',
      },
      statusFilter: {
        name: 'status',
        fieldClassName: SelectField,
        fieldOptions: {
          select2: {
            allowClear: false,
          },
        },
      },
      usersFilter: {
        name: 'corporate_users',
        fieldClassName: CorporateUserField,
        fieldOptions: {
          showShortName: true,
          select2: {
            placeholder: 'Users',
          },
        },
      },
    });

    return this;
  };

  // Initialize
  this.init();
}

export default OrderFilterGroup;
