import React, { useMemo, useState } from 'react';
import { withForm } from 'snowbox';

import { notifier } from 'js/components_v2/default-notifier';
import { translator } from 'js/translator';

import { ADD_LOG_STEPS } from 'partfiniti/bom/types';
import { DISPLAY_FORMAT } from 'partfiniti/core/constants';
import { api } from 'partfiniti/core/services';
import { 
  addDays, 
  calculateDifferenceInDays, 
  mapArrayToNameValueObject,
} from 'partfiniti/core/utils';

import AddLogFormView from './AddLogFormView';
import { processFormData, validateFormData } from './utils';

const inititalFieldsConfig = [
  {
    name: 'user',
    type: 'select',
    loadPath: '/corporate/user/autocomplete',
    loadParams: {
      filters: {
        status: '"active"',
      },
    },
    responseOptionsField: 'corporate_users',
    responseCountField: 'total_count',
    label: translator.getUppercased('label_user'),
    placeholder: translator.getTitle('label_select_user'),
    validation: {
      presence: { allowEmpty: false },
    },
    required: true,
  },
  {
    name: 'task',
    type: 'select',
    loadPath: '/bom/task-list/tasks',
    label: translator.getUppercased('label_task'),
    placeholder: translator.getTitle('label_select_task'),
    validation: {
      presence: { allowEmpty: false },
    },
    required: true,
  },
  {
    name: 'start_date',
    type: 'date',
    label: translator.getUppercased('label_start_date'),
    placeholder: 'Select Start Date',
    value: new Date(),
    validation: {
      presence: { allowEmpty: false },
    },
    required: true,
  },
  {
    name: 'end_date',
    type: 'date',
    label: translator.getUppercased('label_end_date'),
    placeholder: 'Select End Date',
    value: new Date(),
    validation: {
      presence: { allowEmpty: false },
    },
    required: true,
  },
  {
    name: 'note',
    type: 'textarea',
    label: translator.getUppercased('label_note'),
  },
];

const AddLogForm = (props) => {
  const { initialValues } = props;

  const [step, setStep] = useState(ADD_LOG_STEPS.DETAILS);
  const [customFieldsConfig, setCustomFieldsConfig] = 
    useState(inititalFieldsConfig);
  const [values, setValues] = useState({});

  const onNext = (data) => {
    const dates = calculateDifferenceInDays(
      data.start_date,
      data.end_date,
    );

    const activityDates = [];
    for (let i = 0; i < dates; i++) {
      activityDates.push(addDays(data.start_date, i));
    }

    const fields = [];
    for (let i = 0; i < dates; i++) {
      fields.push({
        name: `activity_date_${i}`,
        type: 'text',
        label: translator.getUppercased('label_activity_date'),
        value: moment(activityDates[i]).format(DISPLAY_FORMAT),
        disabled: true,
      },
      {
        name: `duration_${i}`,
        type: 'text',
        label: translator.getUppercased('label_duration_in_hours'),
        required: true,
      });
    }


    setCustomFieldsConfig(fields);
    setStep(ADD_LOG_STEPS.TIME);
  }

  const goBack = () => {
    setStep(ADD_LOG_STEPS.DETAILS);
    setCustomFieldsConfig(inititalFieldsConfig);
  };

  const submitForm = async (
    props,
    data,
    setServerErrors,
  ) => {
    const {
      onSuccess,
      validate,
    } = props;
  
    if (!validateFormData(data, props, validate, setServerErrors)) {
      return false;
    }


    if (step === ADD_LOG_STEPS.DETAILS) {
      setValues(data);
      onNext(data);
  
      return;
    }

    const formData = processFormData(data, { ...initialValues, ...values });

    try {
      notifier.notifyLoading(translator.getTitle('message_saving'));
  
      await api.post('/bom/log-edit/save-bulk', formData);
  
      notifier.notifySuccess(translator.getTitle('message_success'));
  
      onSuccess();
    } catch (error) {
      notifier.clear();
  
      setServerErrors({ _error: error.error });
  
      return false;
    }
  };

  const Form = useMemo(() => {
    const formFields = customFieldsConfig.reduce((fields, field) => (
      {
        ...fields,
        [field.name]: field.validation,
      }
    ), {});

    return withForm({
      fields: formFields,
      submitForm,
      initialValues,
    })(AddLogFormView);
  }, [step, customFieldsConfig]);

  return (
    <Form {
      ...{
        ...props, 
        step, 
        goBack,
        fieldsConfig: customFieldsConfig,
        initialValues: {
          ...mapArrayToNameValueObject(customFieldsConfig),
          ...initialValues, 
        },
      }
    } 
    />
  );
};

export default AddLogForm;