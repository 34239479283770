export const createCustomStyles = (size) => ({
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    minHeight: size === 'big' ? 65 : 45,
    borderColor: '#dee5e9',
    boxShadow: 'none',
    '&:hover,&:focus': {
      borderColor: 'hsl(0, 0%, 80%)',
      boxShadow: 'none',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
  option: (provided) => ({
    ...provided,
    color: 'black',
    fontSize: 12,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 12,
  }),
});
