class EventDispatcher {
  /**
     * Constructor.
     */
  constructor() {
    this.subscribers = {};
  }

  /**
     * Subscribe to event.
     *
     * @param {string}   eventName
     * @param {function} handler
     */
  subscribe(eventName, handler) {
    if (!_.isFunction(handler)) {
      throw new Error('[EventDispatcher] "handler" must be function');
    }

    if (!_.has(this.subscribers, eventName)) {
      this.subscribers[eventName] = [];
    }

    this.subscribers[eventName].push(handler);
  }

  /**
     * Dispatch event.
     *
     * @param {Event} event
     */
  dispatch(event) {
    const eventName = event.constructor.NAME;

    if (!_.has(this.subscribers, eventName)) {
      return;
    }

    const handlersToRemove = [];

    this.subscribers[eventName].forEach((handler => {
      const removeHandler = handler(event);

      if (true === removeHandler) {
        handlersToRemove.push(handler);
      }
    }));

    handlersToRemove.forEach(handlerToRemove => {
      this.subscribers[eventName] = this.subscribers[eventName]
        .filter(handler => handler !== handlerToRemove);
    });
  }
}

export default new EventDispatcher();
