import BaseService from 'js/base_v3/services/service';

const staticSelf = UserValidator;

/**
 * @const
 */
staticSelf.ERROR_TYPE_USER_EMAIL_NOT_CONFIRMED = 'expired';

/**
 * Auth - User Validation Service.
 *
 * @class
 * @extends BaseService
 *
 * @param {object} [options]
 */
function UserValidator(options) {
  BaseService.call(this, options);

  // Initialize
  this._init();
}

export default UserValidator;
