import React from 'react';
import styled from 'styled-components';

export const ArrowDownWrapper = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-right: 5px;
`;

const ArrowDown = () => {
  return (
    <ArrowDownWrapper />
  );
};

export default ArrowDown;
