import React, { useEffect, useState } from 'react';

import { DATE_OPTIONS } from 'partfiniti/core/types';
import { formatDate } from 'partfiniti/core/utils';

import DateIntervalView from './DateIntervalView';

const DateInterval = ({
  name,
  options,
  value,
  onChange,
  placeholder,
  size = 'lg',
  rightPosition = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [opened, setOpened] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (!value) {
      handleChange(options[0]);
    }
  }, [value]);

  const onChangeDate = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const handleChange = (option) => {
    const label = options[option.id]?.label;
    const value = options[option.id]?.value;

    const filterValue = `${formatDate(value?.startDate)}|${formatDate(
      value?.endDate
    )}`;

    if (label === DATE_OPTIONS.ALL) {
      setSelectedOption(null);
      setOpened(false);

      setStartDate(null);
      setEndDate(null);

      onChange('');

      return;
    }

    if (label === DATE_OPTIONS.CUSTOM_RANGE) {
      setSelectedOption(option);

      return;
    }

    setSelectedOption(option);
    setStartDate(value.startDate);
    setEndDate(value.endDate);

    onChange(filterValue);
    setOpened(false);
  };

  const onApply = (startDate, endDate) => {
    const value = { startDate, endDate };
    const filterValue = `${formatDate(startDate)}|${formatDate(endDate)}`;

    const option = {
      id: options.length - 1,
      value,
      label: DATE_OPTIONS.CUSTOM_RANGE,
    };

    handleChange(option);
    onChange(filterValue);
    setOpened(false);
  };

  return (
    <DateIntervalView
      {...{
        name,
        onApply,
        onChange: onChangeDate,
        handleChange,
        setOpened,
        opened,
        options,
        selectedOption,
        startDate,
        endDate,
        placeholder,
        size,
        rightPosition,
      }}
    />
  );
};

export default DateInterval;
