import React from 'react';

import Option from './Option';

const RadioFieldView = ({
  label,
  name,
  value,
  onChange,
  options,
  renderOption,
}) => {
  return (
    <div className="form-group radio_check radio-field">
      <label className="control_label">
        {label}
      </label>
      <div className="form-group item_block active_input">
        {options.map(({ option, label: optionLabel })=> (
          <Option
            key={option}
            {...{
              option,
              label: optionLabel,
              name,
              value,
              onClick: val => onChange(val),
              renderOption,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioFieldView;
