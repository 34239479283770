import CorporateUserSelectForm from 'js/corporate_v2/corporate-user-select-form';

/**
 * Reassign User Form
 *
 * @class
 * @extends CorporateUserSelectForm
 *
 * @param {object[]} records
 * @param {object}   [options]
 */
function ReassignUserForm(records, options) {
    CorporateUserSelectForm.call(this, records, options);
    var parent = this.clone();
    var self = this;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            entityName: 'quote',
            entityLabel: 'quote',
            actionType: 'update',
            tmplParams: {
                actionUrl: '/quote/customer/save-bulk',
                fieldName: 'user_id'
            }
        });
    };

    /**
     * @inheritDoc
     */
    this.processFormData = function(formData) {
        var recordsData = [];

        _.each(this.records, function(record) {
            recordsData.push({
                id: record.id,
                corporate_user_id: formData['corporate_user_id']
            });
        });

        return {
            'quotes': recordsData
        };
    };

    // Initialize
    this.init();
}

export default ReassignUserForm;
