import ModalForm from 'js/base_v2/modal-form';
/**
 * Quote Follow Up Form.
 *
 * @class
 * @extends ModalForm
 *
 * @param {object} options
 */
function QuoteFollowUpForm(options) {
    ModalForm.call(this, options, true);
    var parent = this.clone();
    var self = this;

    /**
     * @protected
     *
     * @prop {Quote[]}
     */
    this.quotes = null;

    /**
     * @inheritDoc
     */
    this.initDefaults = function() {
        parent.initDefaults.call(this);

        return this.extendDefaultOptions({
            tmplEl: '#messages-compose-modal-form',
            tmplParams: {
                actionUrl: '/messages/message/create',
                title: 'Follow Up on Quotes',
                submitBtnLabel: 'Send'
            }
        });
    };

    /**
     * @inheritDoc
     */
    this.prepareServerParams = function() {
        var quoteIds = _.map(this.quotes, function(quote){
            return quote.id;
        });

        this.options.serverParams = this.options.serverParams.extend({
            message: {
                type: 'v2_quote_message',
                quote_ids: quoteIds
            }
        });

        return this;
    };

    /**
     * @inheritDoc
     */
    this.getBeforeSubmitMessage = function() {
        return 'Sending...';
    };

    /**
     * @inheritDoc
     */
    this.getAfterSubmitMessage = function(response) {
        return 'Message(s) have been sent.';
    };

    // Initialize
    this.init();
}

export default QuoteFollowUpForm;
