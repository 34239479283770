import BaseRenderer from 'js/base_v3/helpers/renderer';
import { orderRenderHelper } from 'js/order_v2/helpers/render-helper';

/**
 * Order Renderer.
 *
 * @class
 * @extends BaseRenderer
 *
 * @param {object} [options]
 */
function OrderRenderer(options) {
  BaseRenderer.call(this, options);

  /**
   * Render customer.
   *
   * @param  {Order}  order
   * @return {string}
   */
  this.renderCustomer = function(order) {
    return `
      <span>
        ${orderRenderHelper.getCustomerUserName(order)}
      </span>
      <span class="help_text">
        ${orderRenderHelper.getCustomerCompanyName(order)}
      </span>
    `;
  };

  /**
   * Render vendor.
   *
   * @param  {Order}  order
   * @return {string}
   */
  this.renderVendor = function(order) {
    return `
      <span>
        ${orderRenderHelper.getVendorUserName(order)}
      </span>
      <span class="help_text">
        ${orderRenderHelper.getVendorCompanyName(order)}
      </span>
    `;
  };

  /**
   * Render job.
   *
   * @param  {Order}  order
   * @return {string}
   */
  this.renderJob = function(order) {
    return `
      <span>${order.job_name}</span>
      <span class="help_text">${orderRenderHelper.getProjectName(order)}</span>
    `;
  };

  /**
   * Render order custom ID.
   *
   * @param  {Order}  order
   * @return {string}
   */
  this.renderOrderCustomId = function(order) {
    return `
      <span>${order.order_custom_id}</span>
      <span class="help_text">
        ${this._renderExternalOrderContent(order)}
      </span>
    `;
  };

  /**
   * Render quote custom ID.
   *
   * @param  {Order}  order
   * @return {string}
   */
  this.renderQuoteCustomId = function(order) {
    return `
      <span>${order.quote_custom_id}</span>
      <span class="help_text">${order.type.toUpperCase()}</span>
    `;
  };

  /**
   * Render status.
   *
   * @param  {Order}  order
   * @return {string}
   */
  this.renderStatus = function(order) {
    return `
      <span>${orderRenderHelper.getStatus(order)}</span>
      <span class="help_text">
          ${(order.purchase_order_number || '')}
      </span>
    `;
  };

  /**
   * Render external order content.
   *
   * @protected
   *
   * @param  {Order}  order
   * @return {string}
   */
  this._renderExternalOrderContent = function(order) {
    if (!_.isEmpty(order.external_order_number)) {
      return this._renderExternalOrderNumber(
        order.external_order_number,
        order.external_order_url || null,
      );
    }

    if (!_.isEmpty(order.external_pending_order_number)) {
      return this._renderExternalOrderNumber(
        order.external_pending_order_number,
        order.external_pending_order_url || null,
      );
    }

    return '-';
  };

  /**
   * Render external order number.
   *
   * @protected
   *
   * @param  {?string} externalOrderNumber
   * @param  {?string} externalOrderUrl
   * @return {string}
   */
  this._renderExternalOrderNumber = function(
    externalOrderNumber,
    externalOrderUrl,
  ) {
    if (_.isEmpty(externalOrderUrl)) {
      return externalOrderNumber;
    }

    return `
      <a
        href="${externalOrderUrl}"
        target="_blank"
        class="stopPropagation default-link"
      >
        ${externalOrderNumber}
      </a>
    `;
  };

  // Initialize
  this._init();
}

export const orderRenderer = new OrderRenderer();

export default OrderRenderer;
