import BaseObject from 'js/base_v3/objects/object';

/**
 * Application Config.
 *
 * @class
 * @extends BaseObject
 *
 * @param {object} [options]
 */
function ApplicationConfig(options) {
  BaseObject.call(this, options);
  const parent = this.clone();

  /**
   * @inheritDoc
   */
  this._initDefaults = function() {
    parent._initDefaults.call(this);

    return this._extendDefaultOptions({
      grecaptchaSiteKey: window.GRECAPTCHA_SITE_KEY,
    });
  };

  /**
   * Get option.
   *
   * @param  {string} key
   * @return {*}
   */
  this.get = function(key) {
    return this.getOption(key);
  };

  // Initialize
  this._init();
}

export default ApplicationConfig;
