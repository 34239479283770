import BaseDetails from 'js/base_v2/details';
import MessageList from 'js/messages/lists/message-list';
import MessageTable from 'js/messages_v2/tables/messages';
import DefaultNotifier from 'js/components_v2/default-notifier';

/**
 * Messages Details.
 *
 * @class
 * @extends BaseDetails
 *
 * @param {DOMElement} detailsCt
 * @param {?object}    record
 * @param {object}     [options]
 */
function MessagesDetails(detailsCt, record, options) {
  BaseDetails.call(this, detailsCt, record, options);
  const parent = this.clone();

  /**
   * @prop {MessageList}
   */
  this.messageList = null;

  /**
   * @prop {DefaultNotifier}
   */
  this.notifier = null;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      entityName: 'message_list',
      entityLabel: 'quote or order',
      emptyClassName: 'empty',
      tmplParams: {},
      messageList: {
        tableClass: MessageTable,
        showNewMessageRow: true,
        filterGroupCt: $('.messagingMessagesFilterGroupCt', detailsCt),
      },
    });
  };

  /**
   * @return {MessagesDetails}
   */
  this.load = function(newRecord) {
    parent.load.call(this, newRecord);

    if (!newRecord) {
      return this;
    }

    this.detailsCt.find('.messageListPresentCt').show();
    this.detailsCt.find('.messageListAbsentCt').hide();

    const loadParams = {
      order_id: null,
      quote_id: null,
    };

    const { entityName } = this.options;

    loadParams[`${entityName}_id`] = newRecord.id;

    this._createMessageList();

    this.messageList.table.extendLoadParams(loadParams);

    this.messageList.extendOptions({
      newMessageRowView: {
        composeForm: {
          entityType: entityName,
          entity: newRecord,
        },
      },
    });

    this.messageList.reloadAndScrollToBottom();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.renderEmptyTemplate = function() {
    const div = this.getEmptyTemplate();

    this.detailsCt
      .find('.messageListAbsentCt')
      .show()
      .html(div);

    this.detailsCt.find('.messageListPresentCt').hide();

    return this;
  };

  /**
   * @inheritDoc
   */
  this.renderTemplate = function() {
    // Do nothing, template rendered in a different way
    return this;
  };

  /**
   * Create components.
   *
   * @return {MessagesDetails}
   */
  this.createComponents = function() {
    parent.createComponents.call(this);

    this.notifier = new DefaultNotifier(this.options.notifier).create();

    return this;
  };

  /**
   * Create message list.
   *
   * @return {MessagesDetails}
   */
  this._createMessageList = function() {
    const listCt = $('.messageListCt', this.detailsCt);

    // Prevent double initialization of DataTables
    this.messageList = listCt.data('list');

    if (!this.messageList) {
      this.messageList = new MessageList(
        listCt,
        listCt.find('table'),
        this.options.messageList,
      ).create();

      listCt.data('list', this.messageList);
    }

    return this;
  };

  // Initialize
  this.init();
}

export default MessagesDetails;
