import React from 'react';
import { default as RSelect } from 'react-select';

import { translator } from 'js/translator';

import { createCustomStyles } from './selectStyles';

const StaticSelect = ({
  options,
  value,
  onChange,
  onBlur,
  isMulti = false,
  placeholder = translator.get('select_option'),
  size,
  isClearable = false,
}) => {
  const customStyles = createCustomStyles(size);

  return (
    <RSelect
      {...{
        styles: customStyles,
        value,
        onChange,
        onMenuClose: onBlur,
        options,
        placeholder,
        isMulti,
        isClearable,
      }}
    />
  );
}

export default StaticSelect;
