import QuoteTable from 'js/quote/table';
import { quoteRenderHelper } from 'js/quote/helpers/render-helper';

/**
 * Messages Quote Table.
 *
 * @class
 * @extends QuoteTable
 *
 * @param {DOMElement} tableEl
 * @param {object}     [options]
 */
function MessagesQuoteTable(tableEl, options) {
  QuoteTable.call(this, tableEl, options, true);
  const parent = this.clone();
  const self = this;

  /**
   * @inheritDoc
   */
  this.initDefaults = function() {
    parent.initDefaults.call(this);

    return this.extendDefaultOptions({
      rowClickAction: this.ROW_CLICK_ACTION_SELECT,
      loadParams: {
        sortField: ['updated_at'],
        sortDir: ['desc'],
      },
    });
  };

  /**
   * @inheritDoc
   */
  this.processOptions = function() {
    parent.processOptions.call(this);

    this.options.dataTable.order = [];
    this.options.dataTable.columnDefs = [];

    return this;
  };

  /**
   * @inheritDoc
   */
  this.getColumns = function() {
    return [{
      name: 'name',
      className: 'highlight',
      data: null,
      render(data) {
        return self.renderName(data);
      },
    }];
  };

  /**
   * Render name.
   *
   * @param  {object} quote
   * @return {string}
   */
  this.renderName = function(quote) {
    const html = $('<a>')
      .addClass('showRecord v_btn_tab')
      .attr('href', '#');

    const topLeft = $('<span>')
      .text(quoteRenderHelper.getContactUserName(quote));

    const topRight = $('<span>')
      .text(quote.custom_id)
      .addClass('float-right');

    const topRow = $('<div>')
      .append(topLeft)
      .append(topRight);

    const bottomRow = $('<div>')
      .text(quoteRenderHelper.getContactCompanyName(quote))
      .addClass('help_text');

    html
      .append(topRow)
      .append(bottomRow);

    return html.prop('outerHTML');
  };

  // Initialize
  this.init();
}

export default MessagesQuoteTable;
