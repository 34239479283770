import React from 'react';
import { withForm } from 'snowbox';

import { notifier } from 'js/components_v2/default-notifier';
import { translator } from 'js/translator';

import { api } from 'partfiniti/core/services';

import CrudFormView from './CrudFormView';

const submitForm = async (
  props,
  data,
  setServerErrors,
) => {
  const {
    onSuccess,
    processFormData,
    savePath,
    validate,
  } = props;

  if (_.isFunction(validate)) {
    const errors = validate(data, props);

    if (_.isObject(errors)) {
      setServerErrors(errors);

      return false;
    }
  }

  const formData = _.isFunction(processFormData) ?
    processFormData(data, props) :
    data;

  try {
    notifier.notifyLoading(translator.getTitle('message_saving'));

    await api.post(savePath, formData);

    notifier.notifySuccess(translator.getTitle('message_success'));

    onSuccess();
  } catch (error) {
    notifier.clear();

    setServerErrors({ _error: error.error });

    return false;
  }
};

const CrudForm = (props) => {
  const { fieldsConfig, initialValues } = props;

  const Form = React.useMemo(() => {
    const formFields = fieldsConfig.reduce((fields, field) => ({
      ...fields,
      [field.name]: field.validation,
    }), {});

    return withForm({
      fields: formFields,
      submitForm,
      initialValues: initialValues,
    })(CrudFormView);
  }, [fieldsConfig, initialValues]);

  return <Form {...props} />;
};

export default CrudForm;
